import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectGroupSettings,
  groupSettingsChanged,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import axios from "axios";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TooltipHelp from "../../../components/TooltipHelp";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  maxWidth: "1200px",
};

const CssTextField = styled(TextField)({
  marginTop: 15,
});

export default function ContractPreferences({
  contractParameters,
  setContractParameters,
  setPenaltiesDialogOpen,
}) {
  const { t } = useTranslation();

  const [fineReasonA, setFineReasonA] = useState("");
  const [maximumFineReasonA, setMaximumFineReasonA] = useState("");
  const [fineReasonB, setFineReasonB] = useState("");
  const [maximumFineReasonB, setMaximumFineReasonB] = useState("");
  const [fineReasonC, setFineReasonC] = useState("");
  const [maximumFineReasonC, setMaximumFineReasonC] = useState("");
  const [oneOffFineReasonD, setOneOffFineReasonD] = useState("");
  const [additionalFineReasonD, setAdditionalFineReasonD] = useState("");
  const [maximumFineReasonD, setMaximumFineReasonD] = useState("");
  const [oneOffFineReasonE, setOneOffFineReasonE] = useState("");
  const [additionalFineReasonE, setAdditionalFineReasonE] = useState("");
  const [maximumFineReasonE, setMaximumFineReasonE] = useState("");
  const [fineForOtherReasons, setFineForOtherReasons] = useState("");
  const [maximumFineForOtherReasons, setMaximumFineForOtherReasons] =
    useState("");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const groupValues = useSelector(selectGroupSettings);

  useEffect(() => {
    setFineReasonA(contractParameters.fines.fine_reason_A);
    setMaximumFineReasonA(contractParameters.fines.fine_reason_A_maximum);
    setFineReasonB(contractParameters.fines.fine_reason_B);
    setMaximumFineReasonB(contractParameters.fines.fine_reason_B_maximum);
    setFineReasonC(contractParameters.fines.fine_reason_C);
    setMaximumFineReasonC(contractParameters.fines.fine_reason_C_maximum);
    setOneOffFineReasonD(contractParameters.fines.fine_reason_D_one_off);
    setAdditionalFineReasonD(
      contractParameters.fines.fine_reason_D_additional_daily
    );
    setMaximumFineReasonD(contractParameters.fines.fine_reason_D_maximum);
    setOneOffFineReasonE(contractParameters.fines.fine_reason_E_one_off);
    setAdditionalFineReasonE(
      contractParameters.fines.fine_reason_E_additional_daily
    );
    setMaximumFineReasonE(contractParameters.fines.fine_reason_E_maximum);
    setFineForOtherReasons(contractParameters.fines.fine_other_reasons);
    setMaximumFineForOtherReasons(
      contractParameters.fines.fine_other_reasons_maximum
    );
  }, [groupValues]);

  function handleApplyChanges(e) {
    e.preventDefault();
    setLoading(true);

    const fines = {
      fine_reason_A: fineReasonA,
      fine_reason_A_maximum: maximumFineReasonA,
      fine_reason_B: fineReasonB,
      fine_reason_B_maximum: maximumFineReasonB,
      fine_reason_C: fineReasonC,
      fine_reason_C_maximum: maximumFineReasonC,
      fine_reason_D_one_off: oneOffFineReasonD,
      fine_reason_D_additional_daily: additionalFineReasonD,
      fine_reason_D_maximum: maximumFineReasonD,
      fine_reason_E_one_off: oneOffFineReasonE,
      fine_reason_E_additional_daily: additionalFineReasonE,
      fine_reason_E_maximum: maximumFineReasonE,
      fine_other_reasons: fineForOtherReasons,
      fine_other_reasons_maximum: maximumFineForOtherReasons,
    };

    setContractParameters({
      ...contractParameters,
      fines: fines,
    });

    setPenaltiesDialogOpen(false);
  }

  function handleMakeDefault() {
    setLoading(true);

    const fines = {
      fine_reason_A: fineReasonA,
      fine_reason_A_maximum: maximumFineReasonA,
      fine_reason_B: fineReasonB,
      fine_reason_B_maximum: maximumFineReasonB,
      fine_reason_C: fineReasonC,
      fine_reason_C_maximum: maximumFineReasonC,
      fine_reason_D_one_off: oneOffFineReasonD,
      fine_reason_D_additional_daily: additionalFineReasonD,
      fine_reason_D_maximum: maximumFineReasonD,
      fine_reason_E_one_off: oneOffFineReasonE,
      fine_reason_E_additional_daily: additionalFineReasonE,
      fine_reason_E_maximum: maximumFineReasonE,
      fine_other_reasons: fineForOtherReasons,
      fine_other_reasons_maximum: maximumFineForOtherReasons,
    };

    let payload = {
      group_id: groupValues.group_id,
      contract_features: { ...groupValues.contract_features, fines: fines },
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        dispatch(groupSettingsChanged(payload));
        dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        setContractParameters({
          ...contractParameters,
          fines: fines,
        });
        setPenaltiesDialogOpen(false);
      });
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <form onSubmit={handleApplyChanges}>
        <Typography
          sx={{
            margin: 1,
            fontSize: 20,
          }}
        >
          {t("Fines")}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Reason A")}
              {"\u00a0"}
              <TooltipHelp json_flag={"Reason A tooltip"} />
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Fine")}
                  variant="outlined"
                  value={fineReasonA}
                  onChange={(event) => setFineReasonA(event.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineReasonA}
                  onChange={(event) =>
                    setMaximumFineReasonA(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>

            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Reason B")}
              {"\u00a0"}
              <TooltipHelp json_flag={"Reason B tooltip"} />
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Fine")}
                  variant="outlined"
                  value={fineReasonB}
                  onChange={(event) => setFineReasonB(event.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>

              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineReasonB}
                  onChange={(event) =>
                    setMaximumFineReasonB(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Reason C")}
              {"\u00a0"}
              <TooltipHelp json_flag={"Reason C tooltip"} />
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Fine")}
                  variant="outlined"
                  value={fineReasonC}
                  onChange={(event) => setFineReasonC(event.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineReasonC}
                  onChange={(event) =>
                    setMaximumFineReasonC(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Reason D")}
              {"\u00a0"}
              <TooltipHelp json_flag={"Reason D tooltip"} />
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Oneoff Fine")}
                  variant="outlined"
                  value={oneOffFineReasonD}
                  onChange={(event) => setOneOffFineReasonD(event.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Additional Fine")}
                  variant="outlined"
                  value={additionalFineReasonD}
                  onChange={(event) =>
                    setAdditionalFineReasonD(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineReasonD}
                  onChange={(event) =>
                    setMaximumFineReasonD(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Reason E")}
              {"\u00a0"}
              <TooltipHelp json_flag={"Reason E tooltip"} />
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Oneoff Fine")}
                  variant="outlined"
                  value={oneOffFineReasonE}
                  onChange={(event) => setOneOffFineReasonE(event.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Additional Fine")}
                  variant="outlined"
                  value={additionalFineReasonE}
                  onChange={(event) =>
                    setAdditionalFineReasonE(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineReasonE}
                  onChange={(event) =>
                    setMaximumFineReasonE(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 14,
              }}
            >
              {t("Other Reasons")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CssTextField
                  label={t("Fine")}
                  variant="outlined"
                  value={fineForOtherReasons}
                  onChange={(event) =>
                    setFineForOtherReasons(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>

              <Grid item>
                <CssTextField
                  label={t("Maximum Fine")}
                  variant="outlined"
                  value={maximumFineForOtherReasons}
                  onChange={(event) =>
                    setMaximumFineForOtherReasons(event.target.value)
                  }
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="column">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              borderRadius: 16,
              fontSize: "18px",
              marginTop: 40,
              maxWidth: 600,
            }}
            disabled={
              loading ||
              (groupValues.fine_reason_A === fineReasonA &&
                groupValues.fine_reason_A_maximum === maximumFineReasonA &&
                groupValues.fine_reason_B === fineReasonB &&
                groupValues.fine_reason_B_maximum === maximumFineReasonB &&
                groupValues.fine_reason_C === fineReasonC &&
                groupValues.fine_reason_C_maximum === maximumFineReasonC &&
                groupValues.fine_reason_D_one_off === oneOffFineReasonD &&
                groupValues.fine_reason_D_additional_daily ===
                  additionalFineReasonD &&
                groupValues.fine_reason_D_maximum === maximumFineReasonD &&
                groupValues.fine_reason_E_one_off === oneOffFineReasonE &&
                groupValues.fine_reason_E_additional_daily ===
                  additionalFineReasonE &&
                groupValues.fine_reason_E_maximum === maximumFineReasonE &&
                groupValues.fine_other_reasons === fineForOtherReasons &&
                groupValues.fine_other_reasons_maximum ===
                  maximumFineForOtherReasons)
            }
          >
            {t("APPLY CHANGES")}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            style={{
              borderRadius: 16,
              fontSize: "18px",
              marginTop: 20,
              maxWidth: 600,
            }}
            onClick={handleMakeDefault}
            disabled={
              loading ||
              (groupValues.fine_reason_A === fineReasonA &&
                groupValues.fine_reason_A_maximum === maximumFineReasonA &&
                groupValues.fine_reason_B === fineReasonB &&
                groupValues.fine_reason_B_maximum === maximumFineReasonB &&
                groupValues.fine_reason_C === fineReasonC &&
                groupValues.fine_reason_C_maximum === maximumFineReasonC &&
                groupValues.fine_reason_D_one_off === oneOffFineReasonD &&
                groupValues.fine_reason_D_additional_daily ===
                  additionalFineReasonD &&
                groupValues.fine_reason_D_maximum === maximumFineReasonD &&
                groupValues.fine_reason_E_one_off === oneOffFineReasonE &&
                groupValues.fine_reason_E_additional_daily ===
                  additionalFineReasonE &&
                groupValues.fine_reason_E_maximum === maximumFineReasonE &&
                groupValues.fine_other_reasons === fineForOtherReasons &&
                groupValues.fine_other_reasons_maximum ===
                  maximumFineForOtherReasons)
            }
          >
            {t("APPLY AND MAKE DEFAULT")}
          </Button>
        </Grid>
      </form>
    </motion.div>
  );
}
