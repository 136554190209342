import React, { useEffect, useState } from "react";
import axios from "axios";
import i18next from "i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Navigator from "./Navigator";
import Header from "./Header";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "../../contexts/AuthContext";

import {
  getSelectStatus,
  selectUserSettings,
  selectGroupSettings,
  fetchUserData,
  fetchGroupSettings,
  fetchOrganizationMembers,
  fetchTenantsForAdvancedList,
  fetchTenants,
  fetchLandlords,
  getLandingPageLinks,
  groupSettingsChanged,
} from "../../slicers/userDataSlice";

import {
  selectSnackbarMessage,
  snackbarMessageChanged,
} from "../../slicers/globalStateVariablesSlice";

import LoadingDataComponent from "../../components/LoadingDataComponent";
import componentsMap from "../../utils/componentsMap";
import WelcomePopUp from "./DashboardComponents/WelcomePopUp";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import configData from "../../utils/config.json";

let theme = createTheme({
  palette: {
    primary: {
      light: "#257cff",
      main: "#257cff",
      dark: "#257cff",
    },
    secondary: {
      light: "#f45a2a",
      main: "#f45a2a",
      dark: "#f45a2a",
    },
    background: {
      default: "#e7f3fc",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { display: "none" }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
          .pac-container {
            z-index: 1500 !important;
          }
        `,
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#257cff",
          borderWidth: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          borderWidth: 3,
          opacity: 0.5,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 28,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

const minicardStyle = {
  p: 2,
  backgroundColor: "#f5f5f5",
  borderRadius: 2,
  boxShadow: 3,
  maxWidth: 350,
};

const bulletPoints = ['ID and credit checks', 'Income verification', 'AI-driven fraud detection', 'Extensive PDF reports', 'Pay per completed candidate screening']

const TrialPeriodPopUp = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState("");

  const groupSettings = useSelector(selectGroupSettings);

  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const payload = {
      group_id: groupSettings.group_id,
      account_type: "Standard",
    };

    await axios.post(
      configData.backendURL + "/update_properize_groups_data",
      payload
    );

    dispatch(groupSettingsChanged(payload));
    dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
    setLoading(false);
  }
  return (
    <Dialog open={true}>
      <DialogTitle>
        <Grid align="center" p={1}>
          {t("Your trial period has ended")}
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{ minWidth: "300px", maxWidth: "450px", marginTop: "20px" }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ ...minicardStyle }}>
            <Typography sx={{ margin: 2, marginTop: 1, fontSize: 16, color: "darkblue" }}>
              {t("€9 per completed screening afterwards")}
            </Typography>
            {bulletPoints.map(bulletPoint => <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs={2}>
                  <IconButton disabled>
                    <CheckCircleIcon style={{ color: "limegreen" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {t(bulletPoint)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>)}
          </Grid>

          <Grid container direction="column" mt={7} spacing={1}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              disabled={loading}
            >
              {t("CONTINUE")}
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default function Dashboard({ content }) {
  const { currentUser } = useAuth();
  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const userSettings = useSelector(selectUserSettings);
  const groupSettings = useSelector(selectGroupSettings);
  const valuesStatus = useSelector(getSelectStatus);

  const daysLeft = Math.ceil(
    15 - (new Date() - new Date(groupSettings.created)) / (1000 * 60 * 60 * 24)
  );
  const snackbarMessage = useSelector(selectSnackbarMessage);
  const isAdmin = useSelector(selectUserSettings).master_user;

  useEffect(() => {
    if (valuesStatus.userSettings === "idle") {
      setAlreadyRendered(false);
      dispatch(fetchUserData(currentUser.email));
    } else if (valuesStatus.userSettings === "succeeded") {
      i18next.changeLanguage(userSettings.language);
      if (valuesStatus.groupSettings === "idle") {
        dispatch(fetchGroupSettings(userSettings.group_id));
      }

      if (valuesStatus.organizationUsers === "idle") {
        dispatch(fetchOrganizationMembers(userSettings.group_id));
      }

      if (valuesStatus.tenants === "idle") {
        dispatch(fetchTenants(userSettings.group_id));
      }

      if (valuesStatus.tenantsForAdvancedList === "idle") {
        dispatch(fetchTenantsForAdvancedList(userSettings.group_id));
      }
      if (valuesStatus.landingPageLinks === "idle") {
        dispatch(getLandingPageLinks(userSettings.group_id));
      }
      if (valuesStatus.landlords === "idle") {
        dispatch(fetchLandlords(userSettings.group_id));
      }

      if (
        (valuesStatus.tenantsForAdvancedList === "succeeded") &
        (valuesStatus.landingPageLinks === "succeeded")
      ) {
        setAlreadyRendered(true);
      }
    }
  }, [valuesStatus, dispatch, currentUser.email, groupSettings]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const Content = componentsMap.find(
    (component) => component.name === content
  ).componentName;

  const requiredAdminCredential = componentsMap.find(
    (component) => component.name === content
  ).requiredAdminCredential;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        >
          {isMdUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              handleDrawerToggle={handleDrawerToggle}
              drawerWidth={drawerWidth}
            />
          )}
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { md: "block", xs: "none" } }}
            handleDrawerToggle={handleDrawerToggle}
            drawerWidth={drawerWidth}
          />
        </Box>

        <Header onDrawerToggle={handleDrawerToggle} />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          wrap="nowrap"
          mt={8}
        >
          {valuesStatus.userSettings === "succeeded" &&
            valuesStatus.groupSettings === "succeeded" &&
            valuesStatus.organizationUsers === "succeeded" &&
            valuesStatus.tenantsForAdvancedList === "succeeded" &&
            valuesStatus.landingPageLinks === "succeeded" && (
              <Box component="main" sx={{ p: 2, height: "100%" }}>
                {(!requiredAdminCredential ||
                  (requiredAdminCredential && isAdmin)) && <Content />}
              </Box>
            )}
          {valuesStatus.userSettings === "succeeded" && <WelcomePopUp />}
        </Grid>
      </Box>

      {groupSettings.account_type === "Trial" && daysLeft < 0 && (
        <TrialPeriodPopUp />
      )}
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
      <Snackbar
        style={{
          zIndex: "2500",
        }}
        open={snackbarMessage.length > 0}
        onClose={() => dispatch(snackbarMessageChanged(""))}
        autoHideDuration={2000}
        message={snackbarMessage}
      />
    </ThemeProvider>
  );
}
