import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

const TooltipHelp = ({ json_flag, color, Icon=HelpIcon, fontSize="small" }) => {
  const { t } = useTranslation();

  return (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={Math.max([t(json_flag).length * 30], 1500)} title={t(json_flag)} placement="bottom">
      <Icon fontSize={fontSize} style={{color: color}}/>
    </Tooltip>
  );
};

export default TooltipHelp;
