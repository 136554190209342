import React, { useState } from "react";

import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";

import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ClearIcon from "@mui/icons-material/Clear";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  selectTenants,
  fetchTenantsForAdvancedList,
  fetchTenants,
} from "../../../slicers/userDataSlice";
import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import axios from "axios";
import configData from "../../../utils/config.json";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const cardStyle = {
  padding: 1,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginTop: 1,
  marginBottom: 2,
};

const minicardStyle = {
  py: 1,
  px: 3,
  borderRadius: 2,
  boxShadow: 3,
  marginBottom: 1,
  width: "100%",
};

const DossierMobile = ({ parentRequest }) => {
  const [disableDownloadReport, setDisableDownloadReport] = useState(false);
  const [disabledArchiveButton, setDisabledArchiveButton] = useState(false);
  const [disabledActivateButton, setDisabledActivateButton] = useState(false);
  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);

  const [deleteClicked, setDeleteClicked] = useState(false);

  const { t } = useTranslation();

  const tenantsDetails = useSelector(selectTenants);
  const userValues = useSelector(selectUserSettings);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const householdMembers = tenantsDetails.filter(
    (row) => row["Parent request"] === parentRequest
  );

  const statusArray = householdMembers.map((member) => member.Status);
  let overallStatus = "Completed";

  if (statusArray.includes("In progress")) {
    overallStatus = "In progress";
  }

  if (statusArray.includes("Failed")) {
    overallStatus = "Failed";
  }

  const householdData = tenantsDetails.find(
    (row) => row["Parent request"] === parentRequest
  );

  async function moveRecord(parentRequestArray, action) {
    let payload = {
      parent_request_array: parentRequestArray,
      delete: false,
      archive: false,
    };

    if (action === "archive") {
      payload.archive = true;
      setDisabledArchiveButton(true);
    } else if (action === "delete") {
      payload.delete = true;
      setDisabledDeleteButton(true);
      setDeleteClicked(false);
      dispatch(snackbarMessageChanged(t("Removing record") + "..."));
    } else {
      setDisabledActivateButton(true);
    }

    await axios.post(configData.backendURL + "/move_records2", payload);

    dispatch(fetchTenantsForAdvancedList(userValues.group_id));
    dispatch(fetchTenants(userValues.group_id));

    if (action === "archive") {
      payload.archive = true;
      dispatch(snackbarMessageChanged(t("Record has been moved to archive")));
      setDisabledArchiveButton(false);
    } else if (action === "delete") {
      payload.delete = true;
      dispatch(snackbarMessageChanged(t("Record has been deleted")));
    } else {
      dispatch(snackbarMessageChanged(t("Record has been moved to ongoing")));
      setDisabledActivateButton(false);
    }
  }

  const ViewReportButton = () => {
    async function generateReport() {
      setDisableDownloadReport(true);
      const payload = {
        parent_request: parentRequest,
        language: userValues.language,
      };
      const response = await axios.post(
        configData.backendURL + "/generate_report",
        payload
      );
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = response.data;
      link.click();
      setDisableDownloadReport(false);
    }

    return (
      <Button
        variant="contained"
        fullWidth
        startIcon={<PictureAsPdfIcon />}
        onClick={generateReport}
        disabled={disableDownloadReport}
        style={{
          borderRadius: 4,
        }}
      >
        {!disableDownloadReport && t("GENERATE REPORT")}
        {disableDownloadReport && <CircularProgress />}
      </Button>
    );
  };

  const renderStatus = (row) => {
    let color, Icon;

    if (row["Status"] === "Completed") {
      color = "green";
      Icon = DoneIcon;
    } else if (row["Status"] === "Processing") {
      color = "#e47200";
      Icon = RestartAltIcon;
    } else if (row["Status"] === "In progress") {
      color = "blue";
      Icon = AccessTimeIcon;
    } else if (row["Status"] === "Failed") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <Grid container direction="row" ml={2}>
        <IconButton
          disabled
          style={{
            color: color,
            border: "solid 2px",
          }}
        >
          <Icon />
        </IconButton>
      </Grid>
    );
  };

  const ratingCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }}>
      {overallStatus === "Completed" && householdData["Account Type"] !== "Lite" && (
        <Card sx={minicardStyle}>
          <Typography align="center" variant="h6">
            {t("Rating")}
          </Typography>

          <Typography align="center" variant="h6">
            <Rating
              size="large"
              readOnly
              value={householdData["Rating"]}
              sx={{
                fontSize: "3rem",
              }}
            />
          </Typography>
        </Card>
      )}
    </Grid>
  );

  function showTenantDetail(member) {
    navigate(
      "/tenant_detail?par_request=" +
        parentRequest +
        "&properize_id=" +
        member["properize_id"]
    );
  }

  const DeleteDialog = () => {
    return (
      <Dialog open={deleteClicked}>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to remove it")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => moveRecord([parentRequest], "delete")}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const tenantsCard = (
    <Grid container direction="column" mb={3}>
      <Card sx={cardStyle}>
        <Typography
          my={1}
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t("Tenants")}
        </Typography>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              padding: 1.5,
            },
          }}
        >
          <TableBody>
            {householdMembers.map((member) => (
              <TableRow key={member["Full name"]}>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: 16,
                    }}
                  >
                    {member["Full name"]}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {householdData["Account Type"] !== "Lite" && (
                    <Button
                      variant={
                        member["Status"] === "Completed"
                          ? "contained"
                          : "outlined"
                      }
                      sx={{
                        borderRadius: 2,
                      }}
                      onClick={() => showTenantDetail(member)}
                    >
                      {member["Status"] === "Completed"
                        ? t("VIEW")
                        : t("AWAIT")}
                    </Button>
                  )}

                  {householdData["Account Type"] === "Lite" &&
                    renderStatus(member)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={3}
        >
          {householdData["ArchiveRemove"] === 0 && (
            <Grid item>
              <IconButton
                onClick={() => {
                  moveRecord([parentRequest], "archive");
                }}
                style={{ borderRadius: 0 }}
                disabled={disabledArchiveButton}
              >
                <ArchiveIcon
                  color={disabledArchiveButton ? "disabled" : "primary"}
                />
                <Typography
                  color={disabledArchiveButton ? "disabled" : "primary"}
                  sx={{ fontWeight: "bold", fontSize: 14, marginLeft: 1 }}
                >
                  {t("ARCHIVE")}
                </Typography>
              </IconButton>
            </Grid>
          )}
          {householdData["ArchiveRemove"] === 1 && (
            <Grid item>
              <IconButton
                onClick={() => moveRecord([parentRequest])}
                style={{ borderRadius: 0 }}
                disabled={disabledActivateButton}
              >
                <HowToRegIcon
                  color={disabledActivateButton ? "disabled" : "primary"}
                />
                <Typography
                  color={disabledActivateButton ? "disabled" : "primary"}
                  sx={{ fontWeight: "bold", fontSize: 14, marginLeft: 1 }}
                >
                  {t("TO ONGOING")}
                </Typography>
              </IconButton>
            </Grid>
          )}
          {overallStatus !== "Completed" && (
            <Grid item>
              <IconButton
                onClick={() => setDeleteClicked(true)}
                style={{ borderRadius: 0 }}
                disabled={disabledDeleteButton}
              >
                <DeleteIcon
                  color={disabledDeleteButton ? "disabled" : "error"}
                />
                <Typography
                  sx={{
                    color: disabledDeleteButton ? "disabled" : "red",
                    fontWeight: "bold",
                    fontSize: 14,
                    marginLeft: 1,
                  }}
                >
                  {t("REMOVE")}
                </Typography>
              </IconButton>
            </Grid>
          )}

          {overallStatus === "Completed" && (
            <Grid item>
              <ViewReportButton />
            </Grid>
          )}
        </Grid>
      </Grid>
      <DeleteDialog />
    </Grid>
  );

  return (
    <div>
      <Grid container direction="column" mt={2}>
        {ratingCard}
        {tenantsCard}
      </Grid>
    </div>
  );
};

export default DossierMobile;
