import React, { useEffect, useState } from "react";

import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  ListItemText,
  Rating,
  TextField,
  Typography,
} from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import HomeIcon from "@mui/icons-material/Home";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ClearIcon from "@mui/icons-material/Clear";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

import WarningIcon from "@mui/icons-material/Warning";

import { ReactComponent as IdentityCardIcon } from "../../../logos/identity-card-front.svg";
import { ReactComponent as IdentityCardBackIcon } from "../../../logos/id-card-back.svg";
import { ReactComponent as DocumentsIcon } from "../../../logos/documents.svg";
import { ReactComponent as DocumentsGreyIcon } from "../../../logos/documents-grey.svg";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  selectTenants,
  fetchTenantsForAdvancedList,
  fetchTenants,
  tenantsEdited,
  tenantsForAdvancedListEdited,
  selectTenantsForAdvancedList,
} from "../../../slicers/userDataSlice";

import {
  snackbarMessageChanged,
  householdClicked,
  selectHouseholdActive,
} from "../../../slicers/globalStateVariablesSlice";

import { useNavigate, useSearchParams } from "react-router-dom";
import CopyToClipboard from "../../../components/CopyToClipboard";

import axios from "axios";
import configData from "../../../utils/config.json";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import LoadingDataComponent from "../../../components/LoadingDataComponent";
import EditableField from "../../../components/EditableField";
import SendInvitationToCandidate from "./SendInvitationToCandidate";
import TooltipHelp from "../../../components/TooltipHelp";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginTop: 1,
  marginBottom: 2,
};

const minicardStyle = {
  py: 1,
  px: 3,
  backgroundColor: "#f5f5f5",
  borderRadius: 2,
  boxShadow: 3,
  marginBottom: 3,
  width: "100%",
  alignItems: "center",
};

const breadcumbcardStyle = {
  padding: 1,
  px: 3,
  bgcolor: "#ffffff",
  borderRadius: 10,
  boxShadow: 3,
  marginBottom: 1,
};

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function decimalNotationInCommas(x) {
  return x
    .toFixed(0)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function getNowTimestamp() {
  const date = new Date();

  const dateString =
    ("0" + date.getDate()).slice(-2) +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    String(date.getFullYear()) +
    ", " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2);

  return dateString;
}

const AddGuarantorDialog = ({
  properize_id,
  parentRequest,
  guarantorDialog,
  setGuarantorDialog,
  setGuarantors,
}) => {
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorLastname, setGuarantorLastname] = useState("");
  const [guarantorEmail, setGuarantorEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload_form = {
      properize_id: properize_id,
      guarantorName: guarantorName,
      guarantorLastname: guarantorLastname,
      guarantorEmail: guarantorEmail,
      guarantorRelationship: "",
      guarantorRelationshipOther: "",
    };

    await axios.post(
      configData.backendURL + "/add_guarantor_to_database",
      payload_form
    );

    const response = await axios.post(
      configData.backendURL + "/get_guarantors",
      {
        parentRequest: parentRequest,
      }
    );

    setGuarantors(response.data);
    setGuarantorName(null);
    setGuarantorLastname(null);
    setGuarantorEmail(null);
    setLoading(false);
    setGuarantorDialog(false);
  };

  return (
    <Dialog open={guarantorDialog} onClose={() => setGuarantorDialog(false)}>
      <DialogContent sx={{ minWidth: "310px" }}>
        <h3 style={{ textAlign: "center" }}>{t("Add a guarantor request")}</h3>
        <form onSubmit={handleSubmit}>
        <TextField
          onChange={(event) => setGuarantorName(event.target.value)}
          label={t("First Name")}
          variant="outlined"
          sx={{ marginTop: 3 }}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />
        <br />
        <TextField
          onChange={(event) => setGuarantorLastname(event.target.value)}
          label={t("Last Name")}
          variant="outlined"
          style={{ marginTop: "10px" }}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />
        <br />
        <TextField
          onChange={(event) => setGuarantorEmail(event.target.value)}
          label={t("Email")}
          type="email"
          variant="outlined"
          style={{ marginTop: "10px" }}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />
        <Grid container mt={5} spacing={1}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => setGuarantorDialog(false)}
              style={{
                borderRadius: 16,
                fontSize: "18px",
              }}
            >
              {t("CANCEL")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
              disabled={loading}
              style={{
                borderRadius: 16,
                fontSize: "18px",
              }}
            >
              {t("SEND")}
            </Button>
          </Grid>
        </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const languages = languagesData.map((language) => (
  <MenuItem key={language.country_code} value={language.code}>
    <Grid
      mx={1}
      className={`flag-icon flag-icon-${language.country_code} mx-2`}
    />
    <strong>{language.code.toUpperCase()}</strong>
  </MenuItem>
));

const NotesCard = () => {
  const { t } = useTranslation();

  const [notes, setNotes] = useState([]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [newNote, setNewNote] = useState("");

  const [searchParams] = useSearchParams();
  const par_request = searchParams.get("par_request");

  const householdActive = useSelector(selectHouseholdActive);
  const tenantsSelector = useSelector(selectTenants);
  const parentRequest = householdActive.parentRequest || par_request;
  const userSettings = useSelector(selectUserSettings);
  const dispatch = useDispatch();

  const householdData = tenantsSelector.find(
    (row) => row["Parent request"] === parentRequest
  );

  useEffect(() => {
    if (householdData["notes"].length > 0) {
      setNotes(
        JSON.parse(householdData["notes"]).map((note) => ({
          ...note,
          deleteClicked: false,
          editClicked: false,
        }))
      );
    }
  }, []);

  function addNote() {
    const dateString = getNowTimestamp();

    const notesWithNew = [
      {
        id: "note" + makeid(15),
        timestamp: dateString,
        sender: userSettings.name_agent,
        content: newNote,
      },
      ...notes,
    ];

    setNotes(notesWithNew);
    setShowAddNote(false);

    axios.post(configData.backendURL + "/update_household_records", {
      parentRequest: parentRequest,
      table: "Tenants",
      notes: JSON.stringify(
        notesWithNew.map(
          ({ deleteClicked, editClicked, newContent, ...keepAttrs }) =>
            keepAttrs
        )
      ),
    });

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? { ...t, notes: JSON.stringify(notesWithNew) }
            : t
        )
      )
    );
  }

  function editNote(id) {
    const notesEdited = notes.map((p) =>
      p.id === id ? { ...p, content: p.newContent, editClicked: false } : p
    );
    setNotes(notesEdited);
    axios.post(configData.backendURL + "/update_household_records", {
      parentRequest: parentRequest,
      table: "Tenants",
      notes: JSON.stringify(
        notes
          .map((p) => (p.id === id ? { ...p, content: p.newContent } : p))
          .map(
            ({ deleteClicked, editClicked, newContent, ...keepAttrs }) =>
              keepAttrs
          )
      ),
    });
    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? { ...t, notes: JSON.stringify(notesEdited) }
            : t
        )
      )
    );
  }

  function deleteNote(id) {
    const notesWithoutId = notes
      .filter((p) => p.id !== id)
      .map(({ deleteClicked, editClicked, ...keepAttrs }) => keepAttrs);
    setNotes(notesWithoutId);

    axios.post(configData.backendURL + "/update_household_records", {
      parentRequest: parentRequest,
      table: "Tenants",
      notes: JSON.stringify(
        notesWithoutId.map(
          ({ deleteClicked, editClicked, newContent, ...keepAttrs }) =>
            keepAttrs
        )
      ),
    });

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? { ...t, notes: JSON.stringify(notesWithoutId) }
            : t
        )
      )
    );
  }

  return (
    <Grid style={{ maxWidth: "1200px", width: "100%" }}>
      <Card sx={cardStyle}>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 2,
            margin: 1,
          }}
        >
          {t("Notes")}
        </Typography>

        {!showAddNote && notes.length === 0 && (
          <Grid item align="center" mt={3}>
            <Typography fontSize={14}>{t("There are no notes yet")}</Typography>
          </Grid>
        )}

        {showAddNote && (
          <Grid mt={2} style={{ borderBottom: "2px solid #dddddd" }}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="baseline"
              mb={1}
            >
              <Grid item xs={11}>
                <TextField
                  size="small"
                  onChange={(event) => {
                    setNewNote(event.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <IconButton size="small" onClick={() => addNote(newNote)}>
                      <SaveIcon fontSize="small" style={{ color: "green" }} />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={() => setShowAddNote(false)}
                      style={{ borderRadius: 0 }}
                    >
                      <CloseIcon fontSize="small" style={{ color: "red" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid p={2}>
          {notes.map((note) => (
            <Grid
              key={note.id}
              mt={2}
              style={{ borderBottom: "2px solid #dddddd" }}
            >
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                {note["sender"] +
                  " " +
                  t("said") +
                  " " +
                  t("on") +
                  " " +
                  note["timestamp"] +
                  ":"}
              </Typography>

              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={1}
              >
                {!note["editClicked"] && (
                  <Grid item xs={11} mt={1}>
                    <Typography style={{ fontSize: 14, fontStyle: "italic" }}>
                      {'"' + note["content"] + '"'}
                    </Typography>
                  </Grid>
                )}

                {note["editClicked"] && (
                  <Grid item xs={11} mt={1}>
                    <TextField
                      size="small"
                      value={note.newContent}
                      onChange={(event) => {
                        setNotes(
                          notes.map((p) =>
                            p.id === note.id
                              ? { ...p, newContent: event.target.value }
                              : p
                          )
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                )}

                {!note["editClicked"] && !note["deleteClicked"] && (
                  <Grid item>
                    <Grid container wrap="nowrap" spacing={1}>
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setNotes(
                              notes.map((p) =>
                                p.id === note.id
                                  ? {
                                    ...p,
                                    newContent: p.content,
                                    editClicked: true,
                                  }
                                  : p
                              )
                            );
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setNotes(
                              notes.map((p) =>
                                p.id === note.id
                                  ? { ...p, deleteClicked: true }
                                  : p
                              )
                            );
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <Grid container wrap="nowrap" spacing={1}>
                    {note["deleteClicked"] && (
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={() => deleteNote(note.id)}
                        >
                          <DoneIcon
                            fontSize="small"
                            style={{ color: "green" }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                    {note["editClicked"] && (
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={() => editNote(note.id)}
                        >
                          <SaveIcon
                            fontSize="small"
                            style={{ color: "green" }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                    {(note["editClicked"] || note["deleteClicked"]) && (
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setNotes(
                              notes.map((p) =>
                                p.id === note.id
                                  ? {
                                    ...p,
                                    deleteClicked: false,
                                    editClicked: false,
                                  }
                                  : p
                              )
                            );
                          }}
                          style={{ borderRadius: 0 }}
                        >
                          <CloseIcon
                            fontSize="small"
                            style={{ color: "red" }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}

          {!showAddNote && (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              style={{
                borderRadius: 8,
                fontSize: "14px",
                minWidth: "250px",
                maxWidth: "400px",
                marginTop: "20px",
              }}
              onClick={() => setShowAddNote(true)}
            >
              {t("ADD NOTE")}
            </Button>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

const Dossier = () => {
  const [language, setLanguage] = useState("en");

  const [generatingAutoRating, setGeneratingAutoRating] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);

  const [disDownloadOldReport, setDisDownloadOldReport] = useState(false);

  const [disabledArchiveButton, setDisabledArchiveButton] = useState(false);
  const [disabledActivateButton, setDisabledActivateButton] = useState(false);
  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);

  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteAirtableRecordClicked, setDeleteAirtableRecordClicked] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [properizeIdToAct, setProperizeIdToAct] = useState(null);

  const [addNewRequestWindow, setAddNewRequestWindow] = useState(false);
  const [guarantorDialog, setGuarantorDialog] = useState(false);

  const [guarantors, setGuarantors] = useState([]);
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const par_request = searchParams.get("par_request");

  const tenantsSelector = useSelector(selectTenants);
  const tenantsForAdvancedList = useSelector(selectTenantsForAdvancedList);
  const householdActive = useSelector(selectHouseholdActive);
  const parentRequest = householdActive.parentRequest || par_request;
  const userSettings = useSelector(selectUserSettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (parentRequest) {
      setLanguage(userSettings.language);
      const payload = {
        parentRequest: parentRequest,
      };
      axios
        .post(configData.backendURL + "/get_guarantors", payload)
        .then((response) => setGuarantors(response.data));

      dispatch(
        householdClicked({ parentRequest: parentRequest, tenantDetail: false })
      );
    }
  }, []);

  if (!parentRequest || tenantsSelector.length === 0) {
    return navigate("/");
  }

  const householdMembers = tenantsSelector.filter(
    (row) => row["Parent request"] === parentRequest
  );

  if (householdMembers.length === 0) return navigate("/");

  const statusArray = householdMembers.map((member) => member.Status);

  let overallStatus = "Failed";

  if (statusArray.includes("Completed")) {
    overallStatus = "Completed";
  }

  if (statusArray.includes("Processing")) {
    overallStatus = "Processing";
  }

  if (statusArray.includes("In progress")) {
    overallStatus = "In progress";
  }


  const householdData = tenantsSelector.find(
    (row) => row["Parent request"] === parentRequest
  );

  const tenants = tenantsSelector.filter(
    (row) => row["Parent request"] === parentRequest
  );

  const verifiedIncome = tenants
    .map((t) => t["True income median"] ? t["True income median"] : 0)
    .reduce((partialSum, a) => partialSum + a, 0);


  const isRentalPriceTooLow = householdData["Property price (EUR)"] < 299

  let verifiedMultiple
  if (isRentalPriceTooLow) {
    verifiedMultiple = '3';
  } else {
    verifiedMultiple = String(Math.floor(10 * verifiedIncome / householdData["Property price (EUR)"]) / 10)
  }

  const selfReportedIncome = tenants
    .map((t) => t["Selfreported gross income"] ? t["Selfreported gross income"] : 0)
    .reduce((partialSum, a) => partialSum + a, 0);

  const isIncomeNotVerified = tenants
    .map((t) => !t["True income median"] && t["Selfreported gross income"])
    .some(Boolean);

  const isEDRNegative = tenants
    .map((t) => t["EDR overall score"] > 0 && t["EDR overall score"] < 35)
    .some(Boolean);

  const isEDRFailed = tenants
    .map((t) => t["CheckC_Status"] === "Failed")
    .some(Boolean);

  const isFraudRisk = tenants
    .map((t) => ["High", "Medium"].includes(t["Fraud indicator"]))
    .some(Boolean);

  const isDriversLicenseUsed = tenants
    .map((t) => t["document_type"] === "Driving License")
    .some(Boolean);

  const isIDFake = tenants
    .map((t) => t["CheckA_Detail"].includes("The document uploaded is a fake or sample document"))
    .some(Boolean);

  const isMrzNotMatch = tenants
    .map((t) => t["CheckA_Detail"].includes("MRZ contains invalid check digit"))
    .some(Boolean);

  async function moveRecord(parentRequestArray, action) {
    let archiveRemove = 0;
    let payload = {
      parent_request_array: parentRequestArray,
      delete: false,
      archive: false,
    };

    if (action === "archive") {
      payload.archive = true;
      archiveRemove = 1;
      setDisabledArchiveButton(true);
    } else if (action === "delete") {
      payload.delete = true;
      archiveRemove = 2;
      setDeleteClicked(false);
      setDisabledDeleteButton(true);
    } else {
      setDisabledActivateButton(true);
    }

    await axios.post(configData.backendURL + "/move_records2", payload);

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? { ...t, ArchiveRemove: archiveRemove }
            : t
        )
      )
    );

    dispatch(
      tenantsForAdvancedListEdited(
        tenantsForAdvancedList.map((t) =>
          t["id"] === parentRequest ? { ...t, ArchiveRemove: archiveRemove } : t
        )
      )
    );

    if (action === "archive") {
      payload.archive = true;
      dispatch(snackbarMessageChanged(t("Record has been moved to archive")));
      setDisabledArchiveButton(false);
    } else if (action === "delete") {
      payload.delete = true;
      dispatch(snackbarMessageChanged(t("Record has been deleted")));
      navigate("/");
    } else {
      dispatch(snackbarMessageChanged(t("Record has been moved to ongoing")));
      setDisabledActivateButton(false);
    }
  }

  async function deleteAirtableRecord() {
    setDeleteAirtableRecordClicked(false);
    setLoading(true);

    // If deleted last tenant, navigate to root
    let isLastTenantDeleted = false;
    if (householdMembers.length === 1) {
      isLastTenantDeleted = true;
    }

    let payload = {
      properize_id: properizeIdToAct,
    };

    await axios.post(
      configData.backendURL + "/delete_airtable_record",
      payload
    );
    dispatch(fetchTenantsForAdvancedList(userSettings.group_id));
    dispatch(fetchTenants(userSettings.group_id));
    dispatch(snackbarMessageChanged(t("Record has been deleted")));
    setLoading(false);

    if (isLastTenantDeleted) {
      navigate("/");
    }
  }

  async function sendInvitationEmail(properize_id) {
    setLoading(true);
    let payload = {
      properize_id: properize_id,
    };

    await axios.post(
      configData.backendURL + "/resend_email_invitation_to_tenant",
      payload
    );
    dispatch(snackbarMessageChanged(t("Email has been resent successfully")));
    setLoading(false);
  }

  const renderStatus = (status) => {
    let color, Icon;

    if (status === "Completed") {
      color = "green";
      Icon = DoneIcon;
    } else if (status === "Processing") {
      color = "#e47200";
      Icon = RestartAltIcon;
    } else if (status === "In progress") {
      color = "blue";
      Icon = AccessTimeIcon;
    } else if (status === "Failed") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<Icon />}
          disabled
          fullWidth
          style={{
            color: color,
            borderRadius: 50,
            height: "2.25em",
            borderColor: color,
            minWidth: "250px",
          }}
        >
          {t(status.toUpperCase())}
        </Button>
      </div>
    );
  };

  const renderGuarantorStatus = (status) => {
    let color, Icon;

    if (status === "Completed") {
      color = "green";
      Icon = DoneIcon;
    } else if (status === "Processing") {
      color = "#e47200";
      Icon = RestartAltIcon;
    } else if (status === "In progress") {
      color = "blue";
      Icon = AccessTimeIcon;
      status = "In progress guarantor";
    } else if (status === "Failed") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<Icon />}
          disabled
          fullWidth
          style={{
            color: color,
            borderRadius: 50,
            height: "2.25em",
            borderColor: color,
            minWidth: "250px",
          }}
        >
          {t(status.toUpperCase())}
        </Button>
      </div>
    );
  };

  const renderContractStatus = (status) => {
    let color, Icon;

    if (status === "Signed") {
      color = "green";
      Icon = DoneIcon;
    } else if (status === "Waiting") {
      status = "Signature waiting";
      color = "#e47200";
      Icon = AccessTimeIcon;
    } else if (status === "In progress") {
      status = "Signature sent";
      color = "blue";
      Icon = MailOutlineIcon;
    } else if (status === "Rejected") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<Icon />}
          disabled
          fullWidth
          style={{
            color: color,
            borderRadius: 50,
            height: "2.25em",
            borderColor: color,
            minWidth: "250px",
          }}
        >
          {t(status.toUpperCase())}
        </Button>
      </div>
    );
  };

  function ShareButton({ flow_link, properize_id }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ShareIcon color="secondary" sx={{ fontWeight: "bold" }} />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(flow_link);
              dispatch(snackbarMessageChanged(t("Copied to clipboard")));
            }}
          >
            <ListItemText style={{ textAlign: "center" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>{t("COPY LINK")}</Grid>
                <Grid item>
                  <CopyAllIcon fontSize="small" />
                </Grid>
              </Grid>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => sendInvitationEmail(properize_id)}>
            <ListItemText style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  borderRadius: 4,
                }}
                fullWidth
              >
                {t("RESEND EMAIL INVITE")}
              </Button>
            </ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  const breadcrumbsCard = (
    <Grid justifyContent="flex-start" style={{ maxWidth: "1200px" }}>
      <Card sx={breadcumbcardStyle}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            startIcon={<HomeIcon sx={{ fontWeight: "bold" }} />}
            variant="text"
            onClick={() => navigate("/")}
          >
            <Typography sx={{ fontSize: 16 }}>
              {t("Tenant Overview")}
            </Typography>
          </Button>
          <Typography sx={{ fontSize: 16 }}>{t("Dossier Summary")}</Typography>
        </Breadcrumbs>
      </Card>
    </Grid>
  );

  let property_number = householdData["Property number"];

  if (property_number === "X") {
    property_number = "";
  } else if (householdData["Property address line 2"]) {
    property_number =
      " " + property_number + " " + householdData["Property address line 2"];
  } else {
    property_number = " " + property_number;
  }

  const property_location =
    householdData["Property address"] +
    property_number +
    ", " +
    householdData["Post code"] +
    " " +
    householdData["Property city"];

  if (householdData["Contract"].length > 0) {
    if (
      ["In progress", "Completed"].includes(
        JSON.parse(householdData["Contract"])["envelope_status"]
      )
    ) {
    }
  }

  function changeRating(event, newValue) {
    const ratingChangedByAgent =
      userSettings.name_agent + "; " + getNowTimestamp();

    axios.post(configData.backendURL + "/update_household_records", {
      parentRequest: parentRequest,
      table: "Tenants",
      Rating: newValue,
      rating_changed_by_agent: ratingChangedByAgent,
    });

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? {
              ...t,
              Rating: newValue,
              rating_changed_by_agent: ratingChangedByAgent,
            }
            : t
        )
      )
    );

    dispatch(
      tenantsForAdvancedListEdited(
        tenantsForAdvancedList.map((t) =>
          t["id"] === parentRequest
            ? {
              ...t,
              Rating: newValue,
            }
            : t
        )
      )
    );
  }

  async function generateAutomaticScore() {
    setGeneratingAutoRating(true);

    const response = await axios.post(
      configData.backendURL + "/calculate_automatic_rating",
      {
        parent_request: parentRequest,
      }
    );

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest
            ? {
              ...t,
              Rating: Number(response.data),
              rating_changed_by_agent: null,
            }
            : t
        )
      )
    );

    dispatch(
      tenantsForAdvancedListEdited(
        tenantsForAdvancedList.map((t) =>
          t["id"] === parentRequest
            ? {
              ...t,
              Rating: Number(response.data),
              rating_changed_by_agent: null,
            }
            : t
        )
      )
    );

    setGeneratingAutoRating(false);
  }

  async function generateReport() {
    setGeneratingReport(true);
    const payload = {
      parent_request: parentRequest,
      language: language,
    };
    const response = await axios.post(
      configData.backendURL + "/generate_report",
      payload
    );
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = response.data;
    link.click();
    setGeneratingReport(false);
  }

  async function downloadOldReport() {
    setDisDownloadOldReport(true);
    const payload = {
      url: householdData["tenant_report_legacy"],
    };
    const response = await axios.post(
      configData.backendURL + "/create_presigned_url_api",
      payload
    );

    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.href = response.data;
    link.click();
    setDisDownloadOldReport(false);
  }

  const HouseholdCard = () => {
    const [showEditFields, setShowEditFields] = useState(false);
    const [newAddress, setNewAddress] = useState(
      householdData["Property address"]
    );
    const [newNumber, setNewNumber] = useState(
      householdData["Property number"]
    );
    const [newAddressLine2, setNewAddressLine2] = useState(
      householdData["Property address line 2"]
    );
    const [newPostCode, setNewPostCode] = useState(householdData["Post code"]);
    const [newCity, setNewCity] = useState(householdData["Property city"]);

    function cancelEdition() {
      setNewAddress(householdData["Property address"]);
      setNewNumber(householdData["Property number"]);
      setNewAddressLine2(householdData["Property address line 2"]);
      setNewPostCode(householdData["Post code"]);
      setNewCity(householdData["Property city"]);
      setShowEditFields(false);
    }

    function editFields(event) {
      event.preventDefault();
      axios.post(configData.backendURL + "/update_household_records", {
        parentRequest: parentRequest,
        table: "Tenants",
        "Property address": newAddress,
        "Property number": newNumber,
        "Property address line 2": newAddressLine2,
        "Post code": newPostCode,
        "Property city": newCity,
      });
      dispatch(
        tenantsEdited(
          tenantsSelector.map((t) =>
            t["Parent request"] === parentRequest
              ? {
                ...t,
                "Property address": newAddress,
                "Property number": newNumber,
                "Property address line 2": newAddressLine2,
                "Post code": newPostCode,
                "Property city": newCity,
              }
              : t
          )
        )
      );

      dispatch(
        tenantsForAdvancedListEdited(
          tenantsForAdvancedList.map((t) =>
            t["id"] === parentRequest
              ? {
                ...t,
                "Property address": newAddress,
                "Property number": newNumber,
                "Property address line 2": newAddressLine2,
                "Post code": newPostCode,
                "Property city": newCity,
              }
              : t
          )
        )
      );
    }

    return (
      <Grid style={{ maxWidth: "1200px", width: "100%" }}>
        <Card sx={cardStyle}>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            p={1}
          >
            <Grid item xs={12} lg={8}>
              {!showEditFields && (
                <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                      {householdData["Property address"]}
                      {property_number}, {householdData["Post code"]}{" "}
                      {householdData["Property city"]}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => setShowEditFields(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <CopyToClipboard message={property_location} />
                  </Grid>
                </Grid>
              )}

              {showEditFields && (
                <form onSubmit={editFields}>
                  <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <TextField
                            value={newAddress}
                            label={t("Street")}
                            size="small"
                            onChange={(event) => {
                              setNewAddress(event.target.value);
                            }}
                            fullWidth
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            value={newNumber}
                            label={t("Number")}
                            size="small"
                            onChange={(event) => {
                              setNewNumber(event.target.value);
                            }}
                            fullWidth
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            value={newAddressLine2}
                            label={t("Address Line 2")}
                            size="small"
                            onChange={(event) => {
                              setNewAddressLine2(event.target.value);
                            }}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            value={newPostCode}
                            label={t("Post code")}
                            size="small"
                            onChange={(event) => {
                              setNewPostCode(event.target.value);
                            }}
                            fullWidth
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            value={newCity}
                            label={t("City")}
                            size="small"
                            onChange={(event) => {
                              setNewCity(event.target.value);
                            }}
                            fullWidth
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        spacing={1}
                      >
                        <Grid item>
                          <IconButton type="submit">
                            <SaveIcon
                              fontSize="large"
                              style={{ color: "green" }}
                            />
                          </IconButton>
                        </Grid>

                        <Grid item>
                          <IconButton
                            onClick={cancelEdition}
                            style={{ borderRadius: 0 }}
                          >
                            <CloseIcon
                              fontSize="large"
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}

              <Grid container alignItems="center" mt={2} ml={1} spacing={1}>
                <Grid item xs={5}>
                  <Typography variant="body2">{t("Monthly Price")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <EditableField
                    parentRequest={parentRequest}
                    feature="Property price (EUR)"
                    featureLabel={t("Monthly Price")}
                    convertTo={Number}
                    defaultValue={householdData["Property price (EUR)"]}
                    defaultTextValue={
                      <Typography variant="body2">
                        {"€ " +
                          decimalNotationInCommas(
                            householdData["Property price (EUR)"]
                          ) +
                          " / " +
                          t("month")}
                      </Typography>
                    }
                    type="number"
                  />
                </Grid>

                {overallStatus === "Completed" &&
                  householdData["Account Type"] !== "Lite" && (
                    <>
                      <Grid item xs={5}>
                        <Typography variant="body2">
                          {t("Combined verified income")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {verifiedIncome === 0 && (
                          <Typography variant="body2">€ 0</Typography>
                        )}
                        {verifiedIncome !== 0 && (
                          <Typography variant="body2">
                            {"€ " +
                              decimalNotationInCommas(verifiedIncome) +
                              " / " +
                              t("month") +
                              String(isRentalPriceTooLow ? "" : " (" +
                                verifiedMultiple.replace(".", ",") +
                                "x " +
                                t("Monthly Price").toLowerCase() +
                                ")")}
                          </Typography>
                        )}
                      </Grid>
                      {!householdData["rating_changed_by_agent"] &&
                        householdData["Rating"] < 5 && (
                          <Grid item xs={9} mt={1}>
                            <Alert severity="info">
                              <AlertTitle>{t("Why this score?")}</AlertTitle>
                              {!isIncomeNotVerified &&
                                verifiedMultiple >= 3 &&
                                !isRentalPriceTooLow &&
                                !isEDRNegative &&
                                !isEDRFailed &&
                                !isFraudRisk &&
                                !isDriversLicenseUsed &&
                                !isIDFake &&
                                !isMrzNotMatch &&
                                householdData["Properize staff comments"]}
                              <div style={{ marginTop: 10 }}>
                                {isIncomeNotVerified && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("Income is not verified paragraph") +
                                        " € " +
                                        decimalNotationInCommas(
                                          selfReportedIncome
                                        ) +
                                        " / " +
                                        t("month")}
                                    </Typography>
                                  </li>
                                )}
                                {verifiedMultiple < 3 &&
                                  !isIncomeNotVerified && (
                                    <li>
                                      {verifiedIncome === 0 && (
                                        <Typography variant="caption">
                                          {t("Verified income is € 0")}
                                        </Typography>
                                      )}
                                      {verifiedIncome !== 0 && (
                                        <Typography variant="caption">
                                          {t("Verified income is") +
                                            " " +
                                            verifiedMultiple.replace(".", ",") +
                                            "x " +
                                            t("the rental price")}
                                        </Typography>
                                      )}
                                    </li>
                                  )}

                                {isRentalPriceTooLow && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("Rental price is too low")}
                                    </Typography>
                                  </li>
                                )}
                                {isEDRNegative && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("Negative EDR credit check")}
                                    </Typography>
                                  </li>
                                )}
                                {isEDRFailed && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("Failed EDR credit check")}
                                    </Typography>
                                  </li>
                                )}

                                {isFraudRisk && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("There are fraud warnings")}
                                    </Typography>
                                  </li>
                                )}

                                {isDriversLicenseUsed && (
                                  <li>
                                    <Typography variant="caption">
                                      {t(
                                        "Drivers License used for ID verification"
                                      )}
                                    </Typography>
                                  </li>
                                )}


                                {isIDFake && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("ID is fake or a sample")}
                                    </Typography>
                                  </li>
                                )}
                                {isMrzNotMatch && (
                                  <li>
                                    <Typography variant="caption">
                                      {t("MRZ numbers do not match")}
                                    </Typography>
                                  </li>
                                )}
                              </div>
                            </Alert>
                          </Grid>
                        )}
                      {householdData["cotenant_hidden"] &&
                        tenants.length === 1 && (
                          <Grid item xs={9} mt={1}>
                            <Alert severity="warning">
                              <AlertTitle>
                                {t("Potential co-tenant hidden")}
                              </AlertTitle>
                              <Typography variant="caption">
                                {t("Potential co-tenant hidden paragraph")}
                              </Typography>
                            </Alert>
                          </Grid>
                        )}
                    </>
                  )}
              </Grid>
            </Grid>

            {overallStatus === "Completed" && (
              <Grid item xs={12} lg={4}>
                <Grid container direction="column" wrap="nowrap">
                  {householdData["Account Type"] !== "Lite" && (
                    <Grid container direction="column" sx={minicardStyle}>
                      {!generatingAutoRating && (
                        <>
                          <Rating
                            size="large"
                            onChange={changeRating}
                            defaultValue={householdData["Rating"]}
                            sx={{
                              fontSize: "2.75rem",
                            }}
                            disabled={generatingAutoRating}
                          />
                          {!householdData["rating_changed_by_agent"] &&
                            householdData["Rating"] && (
                              <Grid
                                container
                                wrap="nowrap"
                                justifyContent="center"
                                alignItems="flex-end"
                                spacing={1}
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    {t("Automatic score")}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <TooltipHelp
                                    json_flag={"Automatic score tooltip"}
                                    Icon={InfoIcon}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          {householdData["rating_changed_by_agent"] && (
                            <>
                              <Typography variant="caption">
                                {(householdData["Rating"]
                                  ? t("Score assigned by")
                                  : t("Score deleted by")) +
                                  " " +
                                  householdData[
                                    "rating_changed_by_agent"
                                  ].split("; ")[0]}
                              </Typography>
                              <Typography variant="caption">
                                {t("on") +
                                  " " +
                                  householdData[
                                    "rating_changed_by_agent"
                                  ].split("; ")[1]}
                              </Typography>
                            </>
                          )}
                        </>
                      )}

                      {generatingAutoRating && <CircularProgress />}

                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{
                          borderRadius: 8,
                          maxWidth: "400px",
                          marginTop: "10px",
                        }}
                        fullWidth
                        onClick={generateAutomaticScore}
                        disabled={generatingAutoRating}
                      >
                        {t("RE-CALCULATE AUTOMATIC SCORE")}
                      </Button>
                    </Grid>
                  )}

                  <ButtonGroup
                    variant="text"
                    style={{
                      border:
                        generatingAutoRating || generatingReport
                          ? null
                          : "2px solid #f45a2a",
                      borderRadius: 8,
                    }}
                    fullWidth
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={generateReport}
                      disabled={generatingAutoRating || generatingReport}
                      style={{
                        borderRadius: 0,
                        fontSize: "16px",
                      }}
                    >
                      {!generatingReport && t("GENERATE REPORT")}
                      {generatingReport && <CircularProgress />}
                    </Button>
                    {!generatingReport && (
                      <TextField
                        variant="standard"
                        value={language}
                        onChange={(event) => setLanguage(event.target.value)}
                        select
                        style={{
                          border: null,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          padding: 2,
                          fontSize: "16px",
                          minWidth: "90px",
                          backgroundColor: generatingAutoRating
                            ? "#000000"
                            : "#ffd699",
                          opacity: generatingAutoRating ? 0.08 : 1,
                        }}
                        disabled={generatingAutoRating}
                        InputProps={{
                          disableUnderline: true, // <== added this
                          style: { color: "#000" },
                        }}
                      >
                        {languages}
                      </TextField>
                    )}
                  </ButtonGroup>

                  {householdData["tenant_report_legacy"] && (
                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      style={{
                        borderRadius: 8,
                        maxWidth: "400px",
                        marginTop: "10px",
                      }}
                      fullWidth
                      onClick={downloadOldReport}
                      disabled={disDownloadOldReport}
                    >
                      {t("DOWNLOAD OLD REPORT")}
                    </Button>
                  )}

                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      style={{
                        borderRadius: 8,
                        maxWidth: "400px",
                        marginTop: "20px",
                      }}
                      fullWidth
                      onClick={() => navigate("/contract_generator")}
                    >
                      {t("generate a ROZ contract").toUpperCase()}
                    </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  function showTenantDetail(member) {
    dispatch(
      householdClicked({
        parentRequest: householdActive.parentRequest,
        tenantDetail: member["properize_id"],
      })
    );
    navigate(
      "/tenant_detail?par_request=" +
      householdActive.parentRequest +
      "&properize_id=" +
      member["properize_id"]
    );
  }

  const TenantsCard = () => {
    const [showEditFields, setShowEditFields] = useState(false);
    const [newChildren, setNewChildren] = useState(
      householdData["Number of children"]
    );
    const [newPets, setNewPets] = useState(householdData["Pets"]);

    function cancelEdition() {
      setNewChildren(householdData["Number of children"]);
      setNewPets(householdData["Pets"]);
      setShowEditFields(false);
    }

    function editFields() {
      axios.post(configData.backendURL + "/update_household_records", {
        parentRequest: parentRequest,
        table: "Tenants",
        "Number of children": newChildren,
        Pets: newPets,
      });
      dispatch(
        tenantsEdited(
          tenantsSelector.map((t) =>
            t["Parent request"] === parentRequest
              ? { ...t, "Number of children": newChildren, Pets: newPets }
              : t
          )
        )
      );
    }

    const RenderDocumentButton = ({ urls_string }) => {
      const [disabledButton, setDisabledButton] = useState(false);

      const downloadFiles = async (urls_string) => {
        setDisabledButton(true);
        const payload = {
          urls_string: urls_string,
          zip_filename: "docs",
        };
        const response = await axios.post(
          configData.backendURL + "/download_documents",
          payload,
          { responseType: "blob" }
        );

        setTimeout(() => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", response.headers["x-filename"]); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
        setDisabledButton(false);
      };

      return (
        <Grid item>
          <IconButton
            size="small"
            onClick={() => downloadFiles(urls_string)}
            disabled={disabledButton}
          >
            {!disabledButton && <DocumentsIcon height="30" width="30" />}
            {disabledButton && <DocumentsGreyIcon height="30" width="30" />}
          </IconButton>
        </Grid>
      );
    };

    return (
      <Grid style={{ maxWidth: "1200px", width: "100%" }}>
        <Card sx={cardStyle}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
              margin: 1,
              marginBottom: 2,
            }}
          >
            {t("Household composition")}
          </Typography>
          <Table>
            <TableBody>
              {householdMembers.map((member) => (
                <TableRow key={member["Full name"]}>
                  <TableCell align="left">
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 16,
                          }}
                        >
                          {member["Full name"]}
                        </Typography>
                      </Grid>
                      {member["Fraud indicator"] &&
                        member["Fraud indicator"] !== "Low" && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              disabled
                              fullWidth
                              style={{
                                color: "#000000",
                                marginLeft: 20,
                                borderRadius: 50,
                                height: "2.25em",
                                backgroundColor: "#fffbc8",
                                borderColor: "#f5f5f5",
                              }}
                            >
                              {<WarningIcon style={{ color: "orange" }} />}
                              {"\u00a0\u00a0"}
                              <Typography variant="body2" fontWeight="bold">
                                {JSON.parse(member["fraud_warnings"]).length +
                                  " " +
                                  t("fraud warnings")}
                              </Typography>
                            </Button>
                          </Grid>
                        )}
                      {member["email_bounced"] && (
                        <Grid item ml={3}>
                          <TooltipHelp
                            json_flag={t("email_bounce_tooltip")}
                            Icon={ErrorIcon}
                            color="red"
                            fontSize="large"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell align="right">
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={4}
                    >
                      {!["Completed", "Processing"].includes(
                        member["Status"]
                      ) && (
                          <Grid item>
                            <ShareButton
                              flow_link={member["Flow link"]}
                              properize_id={member["properize_id"]}
                            />
                          </Grid>
                        )}
                      {!["Completed", "Processing"].includes(
                        member["Status"]
                      ) && (
                          <Grid item>
                            <IconButton
                              onClick={() => {
                                setProperizeIdToAct(member["properize_id"]);
                                setDeleteAirtableRecordClicked(true);
                              }}
                              style={{ borderRadius: 0 }}
                              disabled={disabledDeleteButton}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      <Grid item>{renderStatus(member["Status"])}</Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: 4,
                        maxWidth: "150px",
                      }}
                      onClick={() => showTenantDetail(member)}
                      fullWidth
                    >
                      {t("VIEW")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            startIcon={<PersonAddIcon />}
            style={{
              borderRadius: 8,
              fontSize: "14px",
              minWidth: "250px",
              maxWidth: "400px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            onClick={() => setAddNewRequestWindow(true)}
          >
            {t("ADD TENANT")}
          </Button>

          {guarantors.length > 0 && (
            <Grid mt={3}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  margin: 1,
                  marginBottom: 2,
                }}
              >
                {t("Guarantors")}
              </Typography>
              <Table>
                <TableBody>
                  {guarantors.map((guarantor) => (
                    <TableRow key={guarantor["First name"]}>
                      <TableCell align="left">
                        <Typography>
                          {guarantor["First name"]}{" "}
                          {guarantor["Last name"]}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography>{guarantor["Email"]}</Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={3}
                        >
                          {guarantor["ID card"] && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  window.open(guarantor["ID card"])
                                }
                              >
                                <IdentityCardIcon height="30" width="30" />
                              </IconButton>
                            </Grid>
                          )}
                          {guarantor["ID card back"] && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  window.open(guarantor["ID card back"])
                                }
                              >
                                <IdentityCardBackIcon height="30" width="30" />
                              </IconButton>
                            </Grid>
                          )}
                          {!guarantor["ID card back"] && (
                            <Grid item px={5.5} />
                          )}
                          {guarantor["Proof of income link"] && (
                            <RenderDocumentButton
                              urls_string={
                                guarantor["Proof of income link"]
                              }
                            />
                          )}
                          <Grid item>
                            {renderGuarantorStatus(guarantor["Status"])}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
          {householdData["Account Type"] !== "Lite" && (
            <div>
              <Button
                type="submit"
                color="secondary"
                variant="outlined"
                startIcon={<PersonAddIcon />}
                style={{
                  borderRadius: 8,
                  fontSize: "14px",
                  minWidth: "250px",
                  maxWidth: "400px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={() => {
                  setProperizeIdToAct(householdData["properize_id"]);
                  setGuarantorDialog(true);
                }}
              >
                {t("ADD A GUARANTOR")}
              </Button>
            </div>
          )}

          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            {!showEditFields && (
              <>
                <Grid item>
                  <Typography style={{ marginLeft: 10 }}>
                    {!householdData["Number of children"] &&
                      t("No children or pets reported yet")}
                    {householdData["Number of children"] &&
                      t("In addition, this household has reported").concat(
                        " ",
                        householdData["Number of children"] === "0"
                          ? t("no (nothing)")
                          : householdData["Number of children"],
                        " ",
                        t("children and")
                      )}
                    {householdData["Number of children"] &&
                      !householdData["Pets"] &&
                      " " + t("no pets")}
                    {householdData["Number of children"] &&
                      householdData["Pets"] &&
                      " " +
                      t("the following pets") +
                      ': "' +
                      householdData["Pets"] +
                      '"'}
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setShowEditFields(true)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </>
            )}

            {showEditFields && (
              <>
                <Grid item>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <TextField
                        value={newChildren}
                        label={t("Children")}
                        type="number"
                        size="small"
                        onChange={(event) => {
                          setNewChildren(event.target.value);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        value={newPets}
                        label={t("Pets")}
                        size="small"
                        onChange={(event) => {
                          setNewPets(event.target.value);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container wrap="nowrap" spacing={1}>
                    <Grid item>
                      <IconButton size="small" onClick={editFields}>
                        <SaveIcon fontSize="small" style={{ color: "green" }} />
                      </IconButton>
                    </Grid>

                    <Grid item>
                      <IconButton
                        onClick={cancelEdition}
                        style={{ borderRadius: 0 }}
                      >
                        <CloseIcon fontSize="small" style={{ color: "red" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const ContractCard = () => {
    const [cancelContractDialogOpen, setCancelContractDialogOpen] =
      useState(false);
    const [cancellingContract, setCancellingContract] = useState(false);
    const [disDownloadContract, setDisDownloadContract] = useState(false);

    if (householdData["Tenant report"].length === 0) return null;

    async function cancelSigning() {
      setCancelContractDialogOpen(false);
      setCancellingContract(true);
      await axios.post(configData.backendURL + "/cancel_envelope_api", {
        parentRequest: parentRequest,
      });
      dispatch(fetchTenants(userSettings.group_id));
      setCancellingContract(false);
    }

    async function downloadSignedContract(contractURL) {
      setDisDownloadContract(true);
      const payload = {
        url: contractURL,
      };
      const response = await axios.post(
        configData.backendURL + "/create_presigned_url_api",
        payload
      );

      window.location.assign(response.data);
      setDisDownloadContract(false);
    }

    const CancelContractDialog = () => {
      return (
        <Dialog
          open={cancelContractDialogOpen}
          onClose={() => setCancelContractDialogOpen(false)}
        >
          <DialogContent>
            <Typography sx={{ fontSize: 16, fontWeight: "bold", marginTop: 1 }}>
              {t("Are you sure you want to cancel the signature process")}
            </Typography>
            <Grid container justifyContent="space-evenly" spacing={2} mt={8}>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  fullWidth
                  onClick={() => setCancelContractDialogOpen(false)}
                >
                  {t("NO")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  fullWidth
                  onClick={cancelSigning}
                >
                  {t("YES")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      );
    };

    if (householdData["Contract"].length === 0) return null

    const contract = JSON.parse(householdData["Contract"]);

    if (["Rejected", "Cancelled"].includes(contract["envelope_status"])) return null

    return (
      <Grid style={{ maxWidth: "1200px", width: "100%" }}>
        <Card sx={cardStyle}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
              margin: 1,
              marginBottom: 2,
            }}
          >
            {t("Rental contract")}
          </Typography>

          <Table>
            <TableBody>
              {contract["signers"].map((signer, index) => (
                <TableRow key={signer["party_name"]}>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontSize: 16,
                      }}
                    >
                      {index + 1}. {signer["party_name"]}{" "}
                      {index === contract["signers"].length - 1 ? (
                        <span style={{ fontWeight: "bold" }}>
                          ({t("Landlord")})
                        </span>
                      ) : null}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      sx={{
                        fontSize: 16,
                      }}
                    >
                      {renderContractStatus(signer["status"])}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {contract["envelope_status"] !== "Completed" && (
            <Grid container m={1} spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    borderRadius: 8,
                    fontSize: "14px",
                    minWidth: "250px",
                    maxWidth: "400px",
                  }}
                  onClick={() => setCancelContractDialogOpen(true)}
                >
                  {t("CANCEL SIGNING")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    borderRadius: 8,
                    fontSize: "14px",
                    minWidth: "250px",
                    maxWidth: "400px",
                  }}
                  onClick={() => window.location.assign(contract["envelope_link"])}
                  disabled={disDownloadContract}
                >
                  {t("REVIEW CONTRACT")}
                </Button>
              </Grid>
            </Grid>
          )}

          {contract["envelope_status"] === "Completed" && (
            <Grid
              container
              alignItems="center"
              direction="column"
              wrap="nowrap"
              mt={2}
              spacing={1}
            >
              <Grid item>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  {t("Contract signed by all parties on")}{" "}
                  {contract["completed_timestamp"]}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    borderRadius: 8,
                    fontSize: "16px",
                    minWidth: "350px",
                    maxWidth: "450px",
                  }}
                  onClick={() =>
                    downloadSignedContract(contract["envelope_signed_url"])
                  }
                  disabled={disDownloadContract}
                >
                  {t("DOWNLOAD CONTRACT")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Card>
        <CancelContractDialog />
        <LoadingDataComponent alreadyRendered={!cancellingContract} />
      </Grid>
    );
  };

  const DeleteDialog = () => {
    return (
      <Dialog open={deleteClicked}>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to remove it")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => {
                  moveRecord([parentRequest], "delete");
                }}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const DeleteAirtableRecordDialog = () => {
    return (
      <Dialog open={deleteAirtableRecordClicked}>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to remove it")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteAirtableRecordClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => deleteAirtableRecord()}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{ marginLeft: 2, marginBottom: 1, fontSize: 24 }}
      >
        {t("Dossier Summary")}
      </Typography>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ marginLeft: 10 }}
      >
        {breadcrumbsCard}
        <HouseholdCard />
        <TenantsCard />
        <ContractCard />
        <NotesCard />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        px={4}
        sx={{ maxWidth: "1250px" }}
      >
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="caption">{t("Created")}:</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption">
                {householdData["Created"]}
              </Typography>
            </Grid>

            {overallStatus === "Completed" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="caption">{t("Completed")}:</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="caption">
                    {householdData["Completed"]}
                  </Typography>
                </Grid>
              </>
            )}
            {overallStatus !== "Completed" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="caption">
                    {t("Last modified")}:
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="caption">
                    {householdData["Last Modified"]}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="caption">{t("Initiated by")}:</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption">
                {t(householdData["Initiated by"])}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            {householdData["ArchiveRemove"] === 0 && (
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginRight: 50,
                }}
              >
                <IconButton
                  onClick={() => {
                    moveRecord([parentRequest], "archive");
                  }}
                  style={{ borderRadius: 0 }}
                  disabled={disabledArchiveButton}
                >
                  <ArchiveIcon
                    color={disabledArchiveButton ? "disabled" : "primary"}
                  />
                  <Typography
                    color={disabledArchiveButton ? "disabled" : "primary"}
                    sx={{ fontWeight: "bold", fontSize: 14, marginLeft: 1 }}
                  >
                    {t("ARCHIVE")}
                  </Typography>
                </IconButton>
              </Grid>
            )}
            {householdData["ArchiveRemove"] === 1 && (
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginRight: 50,
                }}
              >
                <IconButton
                  onClick={() => moveRecord([parentRequest])}
                  style={{ borderRadius: 0 }}
                  disabled={disabledActivateButton}
                >
                  <HowToRegIcon
                    color={disabledActivateButton ? "disabled" : "primary"}
                  />
                  <Typography
                    color={disabledActivateButton ? "disabled" : "primary"}
                    sx={{ fontWeight: "bold", fontSize: 14, marginLeft: 1 }}
                  >
                    {t("TO ONGOING")}
                  </Typography>
                </IconButton>
              </Grid>
            )}
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginRight: 50,
              }}
            >
              <IconButton
                onClick={() => setDeleteClicked(true)}
                style={{ borderRadius: 0 }}
                disabled={disabledDeleteButton}
              >
                <DeleteIcon
                  color={disabledDeleteButton ? "disabled" : "error"}
                />
                <Typography
                  sx={{
                    color: disabledDeleteButton ? "disabled" : "red",
                    fontWeight: "bold",
                    fontSize: 14,
                    marginLeft: 1,
                  }}
                >
                  {t("REMOVE")}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog />
      <DeleteAirtableRecordDialog />

      <SendInvitationToCandidate
        addNewRequestWindow={addNewRequestWindow}
        setAddNewRequestWindow={setAddNewRequestWindow}
        householdData={householdData}
      />

      <AddGuarantorDialog
        properize_id={properizeIdToAct}
        parentRequest={parentRequest}
        guarantorDialog={guarantorDialog}
        setGuarantorDialog={setGuarantorDialog}
        setGuarantors={setGuarantors}
      />
      <LoadingDataComponent alreadyRendered={!loading} />
    </motion.div>
  );
};

export default Dossier;
