import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";
import { getSelectStatus } from "../../../slicers/userDataSlice";

import AdvancedTenantList from "./AdvancedTenantList";
import CollapsibleTable from "./CollapsibleTable";

import { motion } from "framer-motion";

export default function TenantTableSelector() {
  const theme = createTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const valuesStatus = useSelector(getSelectStatus);

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 1,
      }}
      exit="out"
    >
      {isSmUp &&
        valuesStatus.tenantsForAdvancedList === "succeeded" &&
        valuesStatus.tenants === "succeeded" && <AdvancedTenantList />}
      {!isSmUp &&
        valuesStatus.tenantsForAdvancedList === "succeeded" &&
        valuesStatus.tenants === "succeeded" && <CollapsibleTable />}
    </motion.div>
  );
}
