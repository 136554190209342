import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Grid,
  Link,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  selectGroupSettings,
  selectLandingPageLinks,
  groupSettingsChanged,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";
import LoadingDataComponent from "../../../components/LoadingDataComponent";
import CopyToClipboard from "../../../components/CopyToClipboard";
import TenantPage from "../../TenantPage";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import emailBg from "../../../logos/email_bg2.jpg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
};

const webStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  display: { xs: "none", md: "block" },
};

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const languages = languagesData.map((language) => (
  <MenuItem key={language.country_code} value={language.code}>
    <Grid
      mr={2}
      className={`flag-icon flag-icon-${language.country_code} mx-2`}
    ></Grid>
    <strong>{language.name}</strong>
  </MenuItem>
));

const translationJSON = {
  en: {
    "email_subject": "Tenant verification process via Properize",
    "candidate": "{candidate}",
    "dear_line": "Dear {tenant name}",
    "intro": "As part of the mandatory screening process, we need to verify your ID and income. We kindly ask you to submit your documents through the secure environment of Properize, our compliance partner.",
    "before_you_begin": "IMPORTANT! Before you begin",
    "bullet_point1": "Have your physical ID ready. Driver's licenses, photos of computer screens or scanned copies are not allowed.",
    "bullet_point2": "All documents you upload should be in PDF format. Unless absolutely necessary, please avoid uploading scanned documents to Properize, as this may cause delays.",
    "bullet_point3": "Do not modify documents in any way before uploading them. For example, avoid merging 3 payslips into a single PDF for simplicity. Documents should be uploaded as they are received from their source.",
    "ending_line1": "Begin the verification process by clicking this link",
    "ending_line2": "Please complete this check as soon as possible so we can proceed with preparing the rental contract.",
    "ending_line3": "We will receive an automatic confirmation after you have completed the verification process.",
    "ending_line4": "Thank you for your cooperation.",
  },
  nl: {
    "email_subject": "Huurders verificatieproces via Properize",
    "candidate": "{kandidaat-huurder}",
    "dear_line": "Beste {kandidaat-huurder}",
    "intro": "Als onderdeel van het verplichte screeningproces moeten we je identiteit en inkomen verifiëren. We vragen je vriendelijk om je documenten in te dienen via de beveiligde omgeving van Properize, onze compliance partner.",
    "before_you_begin": "BELANGRIJK! Voordat je begint",
    "bullet_point1": "Houd je fysieke ID bewijs gereed. Rijbewijzen, foto's van computerschermen of gescande kopieën zijn niet toegestaan.",
    "bullet_point2": "Zorg ervoor dat alle documenten die je uploadt in PDF-formaat zijn. Vermijd het uploaden van gescande documenten naar Properize, tenzij het echt noodzakelijk is, aangezien dit tot vertragingen kan leiden.",
    "bullet_point3": "Wijzig de documenten op geen enkele manier voordat je ze uploadt. Vermijd bijvoorbeeld het samenvoegen van meerdere loonstrookjes in één PDF voor eenvoud. De documenten dienen te worden geüpload zoals je ze oorspronkelijk hebt ontvangen.",
    "ending_line1": "Je kunt het verificatie proces starten door op deze link te klikken",
    "ending_line2": "Gelieve dit proces zo snel mogelijk voltooien zodat we snel kunnen starten met het opstellen van de huurovereenkomst.",
    "ending_line3": "Wij ontvangen een automatische bevestiging nadat je het verificatieproces hebt voltooid.",
    "ending_line4": "Bedankt voor je medewerking"
  },
};

const LandingPage = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [tenantLink, setTenantLink] = useState("");
  const [groupId, setGroupId] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [duplicatesAllowed, setDuplicatesAllowed] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [image, setImage] = useState(null);

  const [tenantLinkExists, setTenantLinkExists] = useState(false);

  const userSettings = useSelector(selectUserSettings);
  const [language, setLanguage] = useState(userSettings.language);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const groupSettings = useSelector(selectGroupSettings);
  const tenantLinks = useSelector(selectLandingPageLinks);

  useEffect(() => {
    setGroupId(groupSettings.group_id);
    setPrimaryColor(groupSettings.primary_color);
    setSecondaryColor(groupSettings.secondary_color);
    setBackgroundColor(groupSettings.background_color);
    setTenantLink(groupSettings.tenant_link);
    setLogoURL(groupSettings.logo_url);
    setDuplicatesAllowed(groupSettings.duplicates_allowed)
    setAlreadyRendered(true);
  }, [groupSettings]);

  const handleTenantLinkChange = (event) => {
    setTenantLinkExists(false);
    setTenantLink(event.target.value);

    if (tenantLinks.includes(event.target.value)) {
      setTenantLinkExists(true);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();

    if (tenantLinkExists || !tenantLink) {
      return 0;
    }

    setAlreadyRendered(false);

    let payload = {
      group_id: groupId,
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      background_color: backgroundColor,
      duplicates_allowed: duplicatesAllowed,
      tenant_link: tenantLink,
      logo_url: logoURL,
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        if (!fileName) {
          dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
          dispatch(groupSettingsChanged(payload));
          setAlreadyRendered(true);
        } else {
          const formData = new FormData();
          formData.append("file", file);
          axios
            .post(
              configData.backendURL + "/upload_logo?groupId=" + groupId,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
              setAlreadyRendered(true);
              payload.logo_url = response.data.logo_url;
              dispatch(groupSettingsChanged(payload));
              setFile(null);
              setFileName(null);
              setImage(null);
            });
        }
      });
  }

  // To preload image
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setImage(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleUndoChanges = () => {
    setPrimaryColor(groupSettings.primary_color);
    setSecondaryColor(groupSettings.secondary_color);
    setBackgroundColor(groupSettings.background_color);
    setDuplicatesAllowed(groupSettings.duplicates_allowed)
    setTenantLink(groupSettings.tenant_link);
    setFile(null);
    setFileName(null);
    setImage(null);
    setTenantLinkExists(false);
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 1,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Landing Page")}
      </Typography>
      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Landing Page")}
      </Typography>
      <Grid container spacing={2} sx={{ maxWidth: "1400px" }}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} lg={6} sx={{ display: "grid" }}>
              <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                <Card sx={cardStyle} style={{ height: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          margin: 1,
                          marginBottom: 2,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Settings")}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: "14px",
                          marginLeft: 8,
                        }}
                      >
                        {t("Landing Page Settings text")}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginLeft: 8,
                          marginTop: 40,
                        }}
                      >
                        URL
                      </Typography>

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        mt={1}
                        ml={1}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                          }}
                          sx={{ display: { md: "block", xs: "none" } }}
                        >
                          https://app.properize.com/
                        </Typography>
                        <TextField
                          size="small"
                          variant="outlined"
                          value={tenantLink}
                          onChange={handleTenantLinkChange}
                          helperText={
                            tenantLinkExists
                              ? t("This URL already exists")
                              : !tenantLink
                                ? t("It cannot be blank")
                                : ""
                          }
                          error={tenantLinkExists || !tenantLink}
                          sx={{ maxWidth: "155px" }}
                        />

                        <CopyToClipboard
                          message={"https://app.properize.com/" + tenantLink}
                        />

                        <Button
                          color="secondary"
                          variant="standard"
                          onClick={() => {
                            window.open(
                              "https://app.properize.com/" + tenantLink
                            );
                          }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </Grid>

                      <Grid container wrap="nowrap" mt={1} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Grid container direction="column" wrap="nowrap" spacing={2}>
                            <Grid item>
                              <TextField
                                label={t("Primary Color")}
                                variant="outlined"
                                value={primaryColor}
                                onChange={(event) =>
                                  setPrimaryColor(event.target.value)
                                }
                                type={"color"}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                variant="outlined"
                                label={t("Secondary Color")}
                                value={secondaryColor}
                                onChange={(event) =>
                                  setSecondaryColor(event.target.value)
                                }
                                type={"color"}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                variant="outlined"
                                label={t("Background Color")}
                                value={backgroundColor}
                                onChange={(event) =>
                                  setBackgroundColor(event.target.value)
                                }
                                type={"color"}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            justifyContent={{ xs: "center", sm: "flex-start" }}
                          >
                            <TextField
                              label={t("Logo")}
                              InputProps={{
                                startAdornment: (
                                  <div sx={{ maxWidth: "50px" }}>
                                    <br />
                                    <img
                                      src={image || logoURL}
                                      alt="logo"
                                      width="275"
                                    />
                                    <Button
                                      variant="contained"
                                      component="label"
                                      style={{
                                        width: "100%",
                                        marginTop: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      {t("Change")}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={handleFileSelect}
                                      />
                                    </Button>
                                  </div>
                                ),
                              }}
                              style={{ width: "300px" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>



                      <Typography
                        sx={{
                          margin: 1,
                          marginBottom: 2,
                          marginTop: 4,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Allow duplicates title")}
                      </Typography>
                      <Typography
                        m={1}
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {t("Allow duplicates body")}
                      </Typography>

                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={2}
                      >
                        <Switch
                          checked={duplicatesAllowed}
                          onChange={() => setDuplicatesAllowed(!duplicatesAllowed)}
                          sx={{
                            marginRight: 5,
                          }}
                        />
                        {t("Allow duplicates toggle label")}
                      </Grid>

                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Button
                            color="secondary"
                            variant="outlined"
                            fullWidth
                            style={{
                              borderRadius: 16,
                              fontSize: "18px",
                              marginTop: 30,
                            }}
                            onClick={handleUndoChanges}
                            disabled={
                              tenantLink === groupSettings.tenant_link &&
                              primaryColor === groupSettings.primary_color &&
                              secondaryColor ===
                              groupSettings.secondary_color &&
                              backgroundColor ===
                              groupSettings.background_color &&
                              duplicatesAllowed === groupSettings.duplicates_allowed &&
                              image === null
                            }
                          >
                            {t("RESET")}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            fullWidth
                            style={{
                              borderRadius: 16,
                              fontSize: "18px",
                            }}
                            disabled={
                              tenantLink === groupSettings.tenant_link &&
                              primaryColor === groupSettings.primary_color &&
                              secondaryColor ===
                              groupSettings.secondary_color &&
                              backgroundColor ===
                              groupSettings.background_color &&
                              duplicatesAllowed === groupSettings.duplicates_allowed &&
                              image === null
                            }
                          >
                            {t("APPLY CHANGES")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: "grid" }}>
              <Card sx={webStyle}>
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginBottom: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    flexGrow: 1,
                  }}
                >
                  {t("Preview")}
                </Typography>
                <Card
                  sx={{
                    color: "#bbbbbb",
                    borderRadius: 2,
                    boxShadow: 3,
                    border: 2,
                  }}
                >
                  <TenantPage
                    loadedFromDashboard={true}
                    primaryColorPreview={primaryColor}
                    secondaryColorPreview={secondaryColor}
                    backgroundColorPreview={backgroundColor}
                    imagePreview={image}
                  />
                </Card>
              </Card>
            </Grid>
            <LoadingDataComponent alreadyRendered={alreadyRendered} />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: { xs: "none", lg: "block" } }}>
          <Card sx={cardStyle}>
            <Typography
              sx={{
                marginLeft: 1,
                marginBottom: 2,
                fontSize: 20,
                fontWeight: "bold",
                flexGrow: 1,
              }}
            >
              {t("Tips on communication with your candidates")}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                marginLeft: 8,
                marginBottom: 20,
              }}
            >
              {t("After the viewing, send an email like this")}:
            </Typography>

            <Grid container wrap="nowrap" spacing={3}>
              <Grid item>
                <div style={{ position: "relative", maxWidth: 800 }}>
                  <img src={emailBg} alt="Email Client" width="850" height={language === "en" ? "590" : "640"} />
                  <div
                    style={{
                      position: "absolute",
                      top: "110px",
                      left: "40px",
                      //transform: 'translate(-50%, -50%)',
                      //textAlign: 'center',
                      color: "black",
                    }}
                  >
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          border: "none",
                          padding: 0.3,
                        },
                        [`& .${tableRowClasses.root}`]: {
                          height: "20px",
                        },
                        marginBottom: 2,
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "70%" }} />
                      </colgroup>
                      <TableBody>
                        <TableRow key="from">
                          <TableCell>{t("From")}:</TableCell>
                          <TableCell>{groupSettings.company_name}</TableCell>
                        </TableRow>
                        <TableRow key="to">
                          <TableCell>{t("To")}:</TableCell>
                          <TableCell>{translationJSON[language]["candidate"]}</TableCell>
                        </TableRow>
                        <TableRow key="subject">
                          <TableCell>{t("Subject")}:</TableCell>
                          <TableCell>
                            {translationJSON[language]["email_subject"]}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Typography fontSize={14}>
                      {translationJSON[language]["dear_line"]},
                    </Typography>

                    <Typography fontSize={14} mt={2}>
                      {translationJSON[language]["intro"]}
                    </Typography>

                    <Typography fontSize={14} mt={2}>
                      {translationJSON[language]["before_you_begin"]}:
                      <ul>
                        <li>{translationJSON[language]["bullet_point1"]}</li>
                        <li>{translationJSON[language]["bullet_point2"]}</li>
                        <li>{translationJSON[language]["bullet_point3"]}</li>
                      </ul>
                    </Typography>

                    <Typography fontSize={14} mt={2}>
                      {translationJSON[language]["ending_line1"]}:{" "}
                      <Link
                        fontSize={14}
                        href={"https://app.properize.com/" + tenantLink}
                        color="primary"
                        target="_blank"
                      >
                        {"https://app.properize.com/" + tenantLink}
                      </Link>
                      .
                    </Typography>

                    <Typography fontSize={14} mt={1}>
                      {translationJSON[language]["ending_line2"]}
                    </Typography>

                    <Typography fontSize={14} mt={1}>
                      {translationJSON[language]["ending_line3"]}
                    </Typography>

                    <Typography fontSize={14} mt={1}>
                      {translationJSON[language]["ending_line4"]}
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item ml={5}>
                <TextField
                  value={language}
                  onChange={(event) => setLanguage(event.target.value)}
                  select
                  fullWidth
                  required
                  InputLabelProps={{
                    required: false,
                    shrink: true,
                  }}
                  style={{ marginTop: 15 }}
                >
                  {languages}
                </TextField>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default LandingPage;
