import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectGroupSettings,
  groupSettingsChanged,
} from "../../../slicers/userDataSlice";

import {
  snackbarMessageChanged,
} from "../../../slicers/globalStateVariablesSlice";

import TooltipHelp from "../../../components/TooltipHelp";
import configData from "../../../utils/config.json";

import axios from "axios";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  maxWidth: "700px",
};


export default function Notifications() {
  const { t } = useTranslation();

  const [groupId, setGroupId] = useState("");
  const [email, setEmail] = useState("");
  const [isNewTenantNotification, setIsNewTenantNotification] = useState(false);
  const [isHouseHoldCompletionNotification, setIsHouseHoldCompletionNotification] = useState(false);
  const [isNoResponseNotification, setIsNoResponseNotification] = useState(false);
  const [isExtraDocNotification, setIsExtraDocNotification] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const groupSettings = useSelector(selectGroupSettings);

  useEffect(() => {
    setGroupId(groupSettings.group_id);
    setEmail(groupSettings.email_master);
    setIsNewTenantNotification(groupSettings.isNewTenantNotification);
    setIsHouseHoldCompletionNotification(groupSettings.isHouseHoldCompletionNotification);
    setIsNoResponseNotification(groupSettings.isNoResponseNotification);
    setIsExtraDocNotification(groupSettings.isExtraDocNotification)
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let payload = {
      group_id: groupId,
      isNewTenantNotification: isNewTenantNotification,
      isHouseHoldCompletionNotification: isHouseHoldCompletionNotification,
      isNoResponseNotification: isNoResponseNotification,
      isExtraDocNotification: isExtraDocNotification,
      email_master: email,
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        dispatch(groupSettingsChanged(payload));
        dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        setLoading(false);
      });
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Notifications")}
      </Typography>

      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Notifications")}
      </Typography>
      <Card sx={cardStyle}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column">
            <TextField
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label={
                <div>
                  {t("Notifications email")}{" "}
                  <TooltipHelp json_flag={"Company email tooltip"} />
                </div>
              }
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{
                required: false,
                shrink: true,
              }}
              style={{ marginTop: 15 }}
            />

            <Typography
              sx={{
                margin: 1,
                marginTop: 3,
                fontSize: 18,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {t("Notifications title")}
            </Typography>
            <Typography
              m={1}
              sx={{
                fontSize: 14,
              }}
            >
              {t("Notifications paragraph")}
            </Typography>

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
              }}
              mt={2}
            >
              <Switch
                checked={isNewTenantNotification}
                onChange={() =>
                  setIsNewTenantNotification(!isNewTenantNotification)
                }
                sx={{
                  marginRight: 5,
                }}
              />
              {t("New tenant")}
            </Grid>

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
              }}
              mt={2}
            >
              <Switch
                checked={isHouseHoldCompletionNotification}
                onChange={() =>
                  setIsHouseHoldCompletionNotification(!isHouseHoldCompletionNotification)
                }
                sx={{
                  marginRight: 5,
                }}
              />
              {t("Household completed")}
            </Grid>

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
              }}
              mt={2}
            >
              <Switch
                checked={isNoResponseNotification}
                onChange={() =>
                  setIsNoResponseNotification(!isNoResponseNotification)
                }
                sx={{
                  marginRight: 5,
                }}
              />
              {t("No response")}
            </Grid>

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
              }}
              mt={2}
            >
              <Switch
                checked={isExtraDocNotification}
                onChange={() =>
                  setIsExtraDocNotification(!isExtraDocNotification)
                }
                sx={{
                  marginRight: 5,
                }}
              />
              {t("Extra docs submitted")}
            </Grid>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{
                borderRadius: 16,
                fontSize: "18px",
                marginTop: 40,
              }}
              disabled={
                loading ||
                (groupSettings.isHouseHoldCompletionNotification === isHouseHoldCompletionNotification &&
                  groupSettings.isNewTenantNotification === isNewTenantNotification &&
                  groupSettings.isNoResponseNotification === isNoResponseNotification &&
                  groupSettings.isExtraDocNotification === isExtraDocNotification &&
                  groupSettings.email_master === email)
              }
              fullWidth
            >
              {t("APPLY CHANGES")}
            </Button>
          </Grid>
        </form>
      </Card>
    </motion.div>
  );
}
