import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import configData from "../utils/config.json";

const URLAddress = configData.backendURL;

const initialState = {
  userSettings: "",
  groupSettings: "",
  organizationUsers: "",
  tenants: [],
  landingPageLinks: [],
  tenantsForAdvancedList: [],
  landlords: [],
  landlordInScreen: null,
  status: {
    userSettings: "idle",
    groupSettings: "idle",
    organizationUsers: "idle",
    tenants: "idle",
    landingPageLinks: "idle",
    tenantsForAdvancedList: "idle",
    landlords: "idle",
  }, //'idle' | 'loading' | 'succeeded' | 'failed'}
  error: null,
};

export const fetchUserData = createAsyncThunk(
  "userDataSlice/fetchUserData",
  async (email) => {
    const payload = {
      key: "email",
      value: email,
    };

    const response = await axios.post(
      URLAddress + "/get_properize_users_data",
      payload
    );

    return response.data;
  }
);

export const fetchGroupSettings = createAsyncThunk(
  "userDataSlice/fetchGroupSettings",
  async (groupId) => {
    const payload = {
      key: "group_id",
      value: groupId,
    };

    const response = await axios.post(
      URLAddress + "/get_properize_groups_data",
      payload
    );

    return response.data;
  }
);

export const fetchOrganizationMembers = createAsyncThunk(
  "userDataSlice/fetchOrganizationMembers",
  async (groupId) => {
    const payload = {
      group_id: groupId,
    };

    const response = await axios.post(
      URLAddress + "/get_properize_organization_users",
      payload
    );

    return response.data;
  }
);

export const fetchTenants = createAsyncThunk(
  "userDataSlice/fetchTenants",
  async (groupId) => {
    let payload = {
      group_id: groupId,
    };

    const response = await axios.post(URLAddress + "/fetch_tenants", payload);

    return response.data;
  }
);

export const fetchTenantsForAdvancedList = createAsyncThunk(
  "userDataSlice/fetchTenantsForAdvancedList",
  async (groupId) => {
    let payload = {
      group_id: groupId,
    };

    const response = await axios.post(
      URLAddress + "/fetch_tenants_for_advanced_list",
      payload
    );
    return response.data;
  }
);

export const getLandingPageLinks = createAsyncThunk(
  "userDataSlice/getLandingPageLinks",
  async (groupId) => {
    const payload = {
      groupId: groupId,
    };

    const response = await axios.post(
      URLAddress + "/get_tenant_links",
      payload
    );

    return response.data;
  }
);

export const fetchLandlords = createAsyncThunk(
  "userDataSlice/fetchLandlords",
  async (groupId) => {
    let payload = {
      group_id: groupId,
    };

    const response = await axios.post(URLAddress + "/fetch_landlords", payload);

    return response.data;
  }
);

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    userSettingsChanged(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state.userSettings[key] = value;
      }
    },
    groupSettingsChanged(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state.groupSettings[key] = value;
      }
    },
    statusChanged(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state.status[key] = value;
      }
    },
    newUserCreated(state, action) {
      state.organizationUsers.push(action.payload);
    },
    landlordAccessed(state, action) {
      state.landlordInScreen = action.payload;
    },
    tenantsForAdvancedListEdited(state, action) {
      state.tenantsForAdvancedList = action.payload;
    },
    tenantsEdited(state, action) {
      state.tenants = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status.userSettings = "succeeded";
        state.userSettings = action.payload;
      })
      .addCase(fetchUserData.pending, (state, action) => {
        state.status.userSettings = "pending";
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status.userSettings = "rejected";
      });
    builder
      .addCase(fetchGroupSettings.fulfilled, (state, action) => {
        state.groupSettings = action.payload;
        state.status.groupSettings = "succeeded";
      })
      .addCase(fetchGroupSettings.pending, (state, action) => {
        state.status.groupSettings = "pending";
      })
      .addCase(fetchGroupSettings.rejected, (state, action) => {
        state.status.groupSettings = "rejected";
      });
    builder
      .addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
        state.organizationUsers = action.payload;
        state.status.organizationUsers = "succeeded";
      })
      .addCase(fetchOrganizationMembers.pending, (state, action) => {
        state.status.organizationUsers = "pending";
      })
      .addCase(fetchOrganizationMembers.rejected, (state, action) => {
        state.status.organizationUsers = "rejected";
      });
    builder
      .addCase(getLandingPageLinks.fulfilled, (state, action) => {
        state.status.landingPageLinks = "succeeded";
        state.landingPageLinks = action.payload;
      })
      .addCase(getLandingPageLinks.pending, (state, action) => {
        state.status.landingPageLinks = "pending";
      })
      .addCase(getLandingPageLinks.rejected, (state, action) => {
        state.status.landingPageLinks = "rejected";
      });
    builder
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.status.tenants = "succeeded";
        state.tenants = action.payload;
      })
      .addCase(fetchTenants.pending, (state, action) => {
        state.status.tenants = "pending";
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.status.tenants = "rejected";
      });
    builder
      .addCase(fetchTenantsForAdvancedList.fulfilled, (state, action) => {
        state.status.tenantsForAdvancedList = "succeeded";
        state.tenantsForAdvancedList = action.payload;
      })
      .addCase(fetchTenantsForAdvancedList.pending, (state, action) => {
        state.status.tenantsForAdvancedList = "pending";
      })
      .addCase(fetchTenantsForAdvancedList.rejected, (state, action) => {
        state.status.tenantsForAdvancedList = "rejected";
      });
    builder
      .addCase(fetchLandlords.fulfilled, (state, action) => {
        state.status.landlords = "succeeded";
        state.landlords = action.payload;
      })
      .addCase(fetchLandlords.pending, (state, action) => {
        state.status.landlords = "pending";
      })
      .addCase(fetchLandlords.rejected, (state, action) => {
        state.status.landlords = "rejected";
      });
  },
});

export const selectUserSettings = (state) => state.userData.userSettings;
export const selectGroupSettings = (state) => state.userData.groupSettings;
export const selectOrganizationUsers = (state) =>
  state.userData.organizationUsers;
export const selectTenants = (state) => state.userData.tenants.constructor === Array ? state.userData.tenants : JSON.parse(state.userData.tenants);
export const selectTenantsForAdvancedList = (state) =>
  state.userData.tenantsForAdvancedList.constructor === Array
    ? state.userData.tenantsForAdvancedList
    : JSON.parse(state.userData.tenantsForAdvancedList);
export const selectLandlords = (state) => state.userData.landlords;
export const getActiveLandlord = (state) => state.userData.landlordInScreen;
export const selectLandingPageLinks = (state) =>
  state.userData.landingPageLinks;
export const getSelectStatus = (state) => state.userData.status;
export const getSelectValuesError = (state) => state.userData.error;

export const {
  userSettingsChanged,
  groupSettingsChanged,
  statusChanged,
  landlordAccessed,
  tenantsEdited,
  tenantsForAdvancedListEdited,
  newUserCreated,
} = userDataSlice.actions;

export default userDataSlice.reducer;
