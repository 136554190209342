import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";

import {
  selectGroupSettings,
  fetchLandlords,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import LoadingDataComponent from "../../../components/LoadingDataComponent";
import MessagePopup from "../../../components/MessagePopup";

import { useTranslation } from "react-i18next";

const paperStyle = {
  padding: 3,
  maxWidth: "500px",
  minWidth: "350px",
  bgcolor: "#FFFFFF",
};

function makeid(length) {
  var result = "";
  var characters =
    "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function AddEditLandlord({
  addLandlordWindow,
  setAddLandlordWindow,
  landlord,
}) {
  const { t } = useTranslation();
  const initialsRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const ibanNumberRef = useRef();

  const [hasPropertyManager, setHasPropertyManager] = useState(
    landlord ? landlord["property_manager"] !== null : false
  );
  const [propertyManager, setPropertyManager] = useState(
    landlord ? landlord["property_manager"] : ""
  );
  const [emergencyContactDetails, setEmergencyContactDetails] = useState(
    landlord ? landlord["emergency_contact_details"] : ""
  );

  const [message, setMessage] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);

  const groupValues = useSelector(selectGroupSettings);
  const dispatch = useDispatch();

  async function handleSubmit(e = null) {
    if (e != null) e.preventDefault();

    if (groupValues.suspended)
      return setMessage(t("Suspended account") + "hello@properize.com");

    setDataSubmitted(true);

    const payload = {
      landlord_id: landlord ? landlord["id"] : "LAND" + makeid(16),
      group_id: groupValues.group_id,
      initials: initialsRef.current.value,
      last_name: lastNameRef.current.value,
      email: emailRef.current.value,
      phone_number: phoneNumberRef.current.value,
      iban_number: ibanNumberRef.current.value,
      has_property_manager: hasPropertyManager,
      property_manager: propertyManager,
      emergency_contact_details: emergencyContactDetails,
      is_new_landlord: landlord === undefined,
    };

    await axios.post(configData.backendURL + "/create_edit_landlord", payload);
    dispatch(fetchLandlords(groupValues.group_id));
    dispatch(
      snackbarMessageChanged(
        landlord
          ? "Landlord edited succesfully"
          : "Landlord created succesfully"
      )
    );
    setAddLandlordWindow(false);
    setDataSubmitted(false);
  }

  return (
    <div>
      <Dialog
        open={addLandlordWindow}
        PaperProps={{ style: paperStyle }}
        onClose={() => setAddLandlordWindow(false)}
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column">
              <h3 style={{ textAlign: "center" }}>
                {landlord
                  ? t("Edit landlord profile")
                  : t("Create a new landlord profile")}
              </h3>
              <Grid container wrap="nowrap" mt={2} spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    label={t("Initials")}
                    variant="outlined"
                    inputRef={initialsRef}
                    defaultValue={landlord ? landlord["initials"] : ""}
                    required
                    fullWidth
                    InputLabelProps={{
                      required: false,
                    }}
                  />
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    label={t("Last Name")}
                    variant="outlined"
                    inputRef={lastNameRef}
                    defaultValue={landlord ? landlord["last_name"] : ""}
                    required
                    fullWidth
                    InputLabelProps={{
                      required: false,
                    }}
                  />
                </Grid>
              </Grid>

              <TextField
                label={t("Email")}
                variant="outlined"
                inputRef={emailRef}
                defaultValue={landlord ? landlord["email"] : ""}
                required
                type="email"
                InputLabelProps={{
                  required: false,
                }}
                style={{
                  marginTop: 10,
                }}
              />

              <TextField
                label={t("Telephone number")}
                variant="outlined"
                inputRef={phoneNumberRef}
                defaultValue={landlord ? landlord["phone_number"] : ""}
                required
                fullWidth
                InputLabelProps={{
                  required: false,
                }}
                style={{
                  marginTop: 10,
                }}
              />

              <TextField
                label={t("IBAN number")}
                variant="outlined"
                inputRef={ibanNumberRef}
                defaultValue={landlord ? landlord["iban_number"] : ""}
                required
                fullWidth
                InputLabelProps={{
                  required: false,
                }}
                style={{
                  marginTop: 10,
                }}
              />

              <FormControlLabel
                style={{
                  marginTop: 30,
                  marginLeft: 2,
                }}
                control={
                  <Switch
                    checked={hasPropertyManager}
                    onChange={() => setHasPropertyManager(!hasPropertyManager)}
                  />
                }
                label={t("Works with a professional property manager")}
              />

              {hasPropertyManager && (
                <Grid>
                  <TextField
                    label={t("Property manager name")}
                    variant="outlined"
                    value={propertyManager}
                    onChange={(e) => setPropertyManager(e.target.value)}
                    required
                    fullWidth
                    InputLabelProps={{
                      required: false,
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <TextField
                    label={t("Emergency contact information")}
                    variant="outlined"
                    value={emergencyContactDetails}
                    onChange={(e) => setEmergencyContactDetails(e.target.value)}
                    required
                    fullWidth
                    InputLabelProps={{
                      required: false,
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  />
                </Grid>
              )}

              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 40,
                }}
                onClick={() => setAddLandlordWindow(false)}
              >
                {t("CANCEL")}
              </Button>

              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 10,
                }}
              >
                {landlord ? t("APPLY CHANGES") : t("ADD")}
              </Button>
            </Grid>
          </form>
          <MessagePopup
            state={message}
            setState={setMessage}
            message={message}
          />
          <LoadingDataComponent alreadyRendered={!dataSubmitted} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
