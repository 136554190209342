import Profile from "../pages/Dashboard/DashboardComponents/Profile";

import TenantTableSelector from "../pages/Dashboard/DashboardComponents/TenantTableSelector";
import Dossier from "../pages/Dashboard/DashboardComponents/Dossier";
import GenerateContract from "../pages/Dashboard/DashboardComponents/GenerateContract";
import TenantDetail from "../pages/Dashboard/DashboardComponents/TenantDetail";
import LandingPage from "../pages/Dashboard/DashboardComponents/LandingPage";
import CompanySettings from "../pages/Dashboard/DashboardComponents/CompanySettings";
import Notifications from "../pages/Dashboard/DashboardComponents/Notifications";
import Billing from "../pages/Dashboard/DashboardComponents/Billing";

import ContractPreferences from "../pages/Dashboard/DashboardComponents/ContractPreferences";
import Landlords from "../pages/Dashboard/DashboardComponents/Landlords";
import LandlordProfile from "../pages/Dashboard/DashboardComponents/LandlordProfile";

const componentsMap = [
  {
    name: "Tenant Overview",
    componentName: TenantTableSelector,
    route: "/",
    requiredAdminCredential: false,
  },
  {
    name: "Profile",
    componentName: Profile,
    route: "/profile",
    requiredAdminCredential: false,
  },
  {
    name: "Dossier Summary",
    componentName: Dossier,
    route: "/dossier_summary",
    requiredAdminCredential: false,
  },
  {
    name: "Contract Generator",
    componentName: GenerateContract,
    route: "/contract_generator",
    requiredAdminCredential: false,
  },
  {
    name: "Tenant Detail",
    componentName: TenantDetail,
    route: "/tenant_detail",
    requiredAdminCredential: false,
  },
  {
    name: "Landlords",
    componentName: Landlords,
    route: "/landlords",
    requiredAdminCredential: false,
  },
  {
    name: "Landlord profile",
    componentName: LandlordProfile,
    route: "/landlord_profile",
    requiredAdminCredential: false,
  },
  {
    name: "Landing Page",
    componentName: LandingPage,
    route: "/landing_page",
    requiredAdminCredential: false,
  },
  {
    name: "Company Settings",
    componentName: CompanySettings,
    route: "/company_settings",
    requiredAdminCredential: true,
  },
  {
    name: "Notifications",
    componentName: Notifications,
    route: "/notifications",
    requiredAdminCredential: true,
  },
  {
    name: "Billing",
    componentName: Billing,
    route: "/billing",
    requiredAdminCredential: true,
  },
  {
    name: "Contract Preferences",
    componentName: ContractPreferences,
    route: "/contract_preferences",
    requiredAdminCredential: false,
  },
];

export default componentsMap;
