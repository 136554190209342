
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import properizeLogo from'../logos/properize.png';

const paperStyle = {
  padding: 4,
  minHeight: "20vw",
  maxWidth: 450,
  bgcolor: "primary.main",
  color: "#FFFFFF",
  borderColor: "#257cff",
  borderRadius: 8,
  border: 2,
  boxShadow: 8,
};

export default function EmailNotVerified() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      align="center"
      justifyContent="center"
      height="90vh"
    >
      <Grid item mt={2}>
        <img
          src={properizeLogo}
          alt="properizelogo"
          height="50"
        />
      </Grid>
      <Grid item>
        <Paper sx={paperStyle}>
          <Typography sx={{ margin: 2, fontSize: 24 }}>
            {t("Your account is not yet verified")}
          </Typography>
          <Typography align="left" mt={5} mx={2} sx={{ fontSize: 16 }}>
            {t("An email has been sent to your address")}
          </Typography>
          <Typography align="left" mt={2} mx={2} sx={{ fontSize: 16 }}>
            {t("Check your inbox")}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
