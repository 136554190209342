import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import properizeLogo from '../logos/properize.png';

import MessagePopup from "../components/MessagePopup";
import configData from "../utils/config.json";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import navigatorLanguage from "../utils/navigatorLanguage";

const CssTextField = styled(TextField)({
  input: {
    color: "white",
    "&::placeholder": {
      color: "white",
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #257cff inset",
      WebkitTextFillColor: "#fff",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "30px",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  marginTop: 15,
});

const minicardStyle = {
  p: 2,
  backgroundColor: "#f5f5f5",
  borderRadius: 8,
  boxShadow: 3,
  maxWidth: 350,
};

const bulletPoints = ['ID and credit checks', 'Income verification', 'AI-driven fraud detection', 'Extensive PDF reports', 'Pay per completed candidate screening']

export default function Signup() {
  const { t } = useTranslation();

  const nameRef = useRef();
  const companyNameRef = useRef();
  const phoneNumberRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState(false);
  const { logout, signup } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const language = navigatorLanguage();

  useEffect(() => {
    i18next.changeLanguage(language)
  }, [language])

  const paperStyle = {
    padding: 3,
    minHeight: "20vw",
    maxWidth: 400,
    bgcolor: "primary.main",
    color: "#FFFFFF",
    borderColor: "#257cff",
    borderRadius: 8,
    border: 2,
    boxShadow: 8,
    margin: 2,
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (!termsAndConditionsCheckbox) {
      return setError(t("You need to agree with terms and conditions"));
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError(t("Passwords do not match"));
    }

    try {
      setError(false);
      setLoading(true);

      await signup(emailRef.current.value.toLowerCase(), passwordRef.current.value);

      const payload = {
        name: nameRef.current.value,
        email: emailRef.current.value.toLowerCase(),
        companyName: companyNameRef.current.value,
        phoneNumber: phoneNumberRef.current.value,
        language: language,
      };

      axios
        .post(configData.backendURL + "/create_properize_user", payload)
        .then((response) => {
          logout();
          navigate("/complete_registration");
        });
    } catch (signUpError) {
      if (
        signUpError.toString().includes("The email address is already in use")
      ) {
        setError(t("The email address is already in use"));
      } else if (
        signUpError
          .toString()
          .includes("Password should be at least 6 characters")
      ) {
        setError(t("Password should be at least 6 characters long"));
      } else {
        setError(signUpError.toString());
      }

      setLoading(false);
    }
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Grid container spacing={1} direction="column" alignItems="center" width="100%">
        <Grid item maxWidth="350px" mt={2}>
          <img
            src={properizeLogo}
            alt="properizelogo"
            height="50"
          />
        </Grid>

        <Grid item width="100%">
          <Grid container justifyContent="center">
            <Grid item>
              <Grid container sx={{ ...minicardStyle, mt: 2 }}>
                <Typography sx={{ margin: 2, fontSize: 20, fontWeight: "bold", color: "darkblue" }}>
                  {t("Invite 3 candidates for free")}
                </Typography>
                <Typography sx={{ margin: 2, marginTop: 1, fontSize: 16, color: "darkblue" }}>
                  {t("€9 per completed screening afterwards")}
                </Typography>
                {bulletPoints.map(bulletPoint => <Grid item xs={12}>
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs={2}>
                      <IconButton disabled>
                        <CheckCircleIcon style={{ color: "limegreen" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        {t(bulletPoint)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>)}
              </Grid>
            </Grid>

            <Grid item align="center">
              <Paper sx={paperStyle}>
                <Avatar style={{ backgroundColor: "white" }}>
                  <AppRegistrationIcon color={"secondary"} />
                </Avatar>
                <form onSubmit={handleSubmit}>
                  <CssTextField
                    label={t("Your name")}
                    variant="outlined"
                    inputRef={nameRef}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />
                  <CssTextField
                    label={t("Company name")}
                    variant="outlined"
                    inputRef={companyNameRef}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />
                  <CssTextField
                    label={t("Phone number")}
                    variant="outlined"
                    inputRef={phoneNumberRef}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />
                  <CssTextField
                    label={t("Company email")}
                    variant="outlined"
                    inputRef={emailRef}
                    type="email"
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />
                  <CssTextField
                    label={t("Password")}
                    variant="outlined"
                    type="password"
                    inputRef={passwordRef}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />
                  <CssTextField
                    label={t("Repeat password")}
                    variant="outlined"
                    type="password"
                    inputRef={passwordConfirmRef}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      required: false,
                    }}
                    fullWidth
                    required
                  />

                  <FormControlLabel
                    sx={{ alignItems: 'flex-end' }}
                    control={
                      <Checkbox
                        onChange={() =>
                          setTermsAndConditionsCheckbox(!termsAndConditionsCheckbox)
                        }
                        value={termsAndConditionsCheckbox}
                        style={{
                          color: "#FFF",
                        }}
                      />
                    }
                    label={
                      <Typography
                        mt={3}
                        align="left"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {t("By ticking, you agree to our")}
                        <Link
                          href="https://properize.com/terms-and-conditions/"
                          color="inherit"
                          target="_blank"
                        >
                          {t("terms and conditions")}
                        </Link>
                        {t("and")}
                        <Link
                          href="https://properize.com/privacy-policy/"
                          color="inherit"
                          target="_blank"
                        >
                          {t("privacy policy")}
                        </Link>
                      </Typography>
                    }
                  />

                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: 16,
                      fontSize: "18px",
                      marginTop: 40,
                    }}
                    disabled={loading}
                  >
                    {t("SIGN UP")}
                  </Button>
                </form>
              </Paper>
              <Grid item align="center">
                <Typography
                  style={{
                    color: "#257cff",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Are you already a customer?")}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/login")}
                  style={{
                    color: "#257cff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    width: "350px",
                  }}
                  sx={{
                    marginBottom: 5,
                  }}
                >
                  {t("LOG IN HERE")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <MessagePopup state={error} setState={setError} message={error} />
    </motion.div>
  );
}
