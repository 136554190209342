import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import TooltipHelp from "../../../components/TooltipHelp";
import LoadingDataComponent from "../../../components/LoadingDataComponent";

import {
  selectOrganizationUsers,
  selectGroupSettings,
  groupSettingsChanged,
  fetchUserData,
  fetchOrganizationMembers,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import { useSelector, useDispatch } from "react-redux";
import AddNewUser from "./AddNewUser";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import MessagePopup from "../../../components/MessagePopup";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginBottom: 1,
};

function Row(props) {
  const { row } = props;

  const { t } = useTranslation();

  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const groupSettings = useSelector(selectGroupSettings);

  const dispatch = useDispatch();

  const DeleteUserDialog = () => {
    return (
      <Dialog open={deleteUserDialogOpen}>
        <DialogContent>
          <DialogContentText>
            {t("Removing user 1") + row["name_agent"] + t("Removing user 2")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteUserDialogOpen(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={handleDeleteUser}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  async function handleDeleteUser() {
    setDeleteUserDialogOpen(false);
    const payload = {
      email: row["email"],
      company_name: groupSettings.company_name,
    };

    await axios.post(configData.backendURL + "/delete_user", payload);
    dispatch(fetchOrganizationMembers(groupSettings.group_id));
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Typography
            sx={{
              fontSize: 16,
            }}
          >
            {row["name_agent"]}
          </Typography>
        </TableCell>
        <TableCell>
          {!row["master_user"] && (
            <IconButton onClick={() => setDeleteUserDialogOpen(true)}>
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <DeleteUserDialog />
    </React.Fragment>
  );
}

const CompanySettings = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [groupId, setGroupId] = useState("");
  const [isEmployerDeclarationForced, setIsEmployerDeclarationForced] = useState(false);
  const [isEmploymentContractForced, setIsEmploymentContractForced] = useState(false);
  const [daysDeadline, setDaysDeadline] = useState("");

  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [addNewUserWindow, setAddNewUserWindow] = useState(false);
  const [error, setError] = useState(false);

  const [changeAdminDialogOpen, setChangeAdminDialogOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const dispatch = useDispatch();

  const groupSettings = useSelector(selectGroupSettings);
  const organizationUsers = useSelector(selectOrganizationUsers);

  const organizationUserNames = organizationUsers.map((user) => (
    <MenuItem key={user.name_agent} value={user.name_agent}>
      {user.name_agent}
    </MenuItem>
  ));

  const [masterUserName, setMasterUserName] = useState(
    organizationUsers.find((user) => user.master_user === true).name_agent
  );

  const oldMasterUserEmail = organizationUsers.find(
    (user) => user.master_user === true
  ).email;

  useEffect(() => {
    setGroupId(groupSettings.group_id);
    setIsEmployerDeclarationForced(groupSettings.force_employer_declaration);
    setIsEmploymentContractForced(groupSettings.force_employment_contract);
    setDaysDeadline(groupSettings.days_deadline);
    setAlreadyRendered(true);
    if (organizationUsers.length <= 3) {
      setRowsPerPage(0);
    }
  }, [groupSettings, organizationUsers]);

  function labelDisplayedRows({ from, to, count }) {
    return (
      from +
      "–" +
      to +
      " " +
      t("of") +
      " " +
      (count !== -1 ? count : t("more than") + " " + to)
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function changeMasterUser(newMasterUserName) {
    setChangeAdminDialogOpen(false);

    const masterUserEmail = organizationUsers.find(
      (user) => user.name_agent === newMasterUserName
    ).email;

    if (masterUserEmail !== oldMasterUserEmail) {
      let payload = {
        email: oldMasterUserEmail,
        master_user: false,
      };

      await axios.post(
        configData.backendURL + "/update_properize_users_data",
        payload
      );
      payload = {
        email: masterUserEmail,
        master_user: true,
      };

      await axios.post(
        configData.backendURL + "/update_properize_users_data",
        payload
      );
      dispatch(fetchUserData(currentUser.email));
      dispatch(fetchOrganizationMembers(groupId));
      navigate("/");
    }
  }

  async function handleMasterUser(event) {
    setMasterUserName(event.target.value);
    setChangeAdminDialogOpen(true);
  }

  const MasterUserChangeDialog = () => {
    return (
      <Dialog open={changeAdminDialogOpen}>
        <DialogContent>
          <DialogContentText>
            {t("Admin change 1") + masterUserName + t("Admin change 2")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => {
                  setMasterUserName(
                    organizationUsers.find((user) => user.master_user === true)
                      .name_agent
                  );
                  setChangeAdminDialogOpen(false);
                }}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => changeMasterUser(masterUserName)}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  function handleSubmit(e) {
    e.preventDefault();
    setAlreadyRendered(false);

    let payload = {
      group_id: groupId,
      force_employer_declaration: isEmployerDeclarationForced,
      force_employment_contract: isEmploymentContractForced,
      days_deadline: daysDeadline,
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        dispatch(groupSettingsChanged(payload));
        dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        setAlreadyRendered(false);
      });
  }

  function handleNewUser(e) {
    e.preventDefault();
    setAddNewUserWindow(true);
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 1,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Company Settings")}
      </Typography>

      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Company Settings")}
      </Typography>

      <Grid container direction="row" spacing={2} sx={{ maxWidth: "1200px" }}>
        <Grid item xs={12} lg={6}>
          <Card sx={cardStyle}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              sx={{ minHeight: "650px" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    margin: 1,
                    marginBottom: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                    flexGrow: 1,
                  }}
                >
                  {t("Preferences")}
                </Typography>
                <Typography
                  sx={{
                    margin: 1,
                    marginTop: 3,
                    fontSize: 18,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {t("Document requirements to be requested")}
                </Typography>
                <Typography
                  m={1}
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {t("Document requirements to be requested paragraph")}
                </Typography>

                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    opacity: 1,
                  }}
                  mt={2}
                >
                  <Switch
                    checked={isEmploymentContractForced}
                    onChange={() => setIsEmploymentContractForced(!isEmploymentContractForced)}
                    sx={{
                      marginRight: 5,
                    }}
                  />
                  {t("Force employment contract")}
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    opacity: 1,
                  }}
                >
                  <Switch
                    checked={isEmployerDeclarationForced}
                    onChange={() => setIsEmployerDeclarationForced(!isEmployerDeclarationForced)}
                    sx={{
                      marginRight: 5,
                    }}
                  />
                  {t("Force request of employer's declaration")}
                  {"\u00a0"}
                  <TooltipHelp json_flag={"Force request of employer's declaration tooltip"} />
                </Grid>
                <Typography
                  sx={{
                    margin: 1,
                    marginTop: 6,
                    fontSize: 18,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {t("Deadline")}
                </Typography>
                <Typography
                  m={1}
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {t("Deadline paragraph")}
                </Typography>
                <Grid container mt={2}>
                  <Grid item>
                    <Grid container>
                      <Button
                        variant={
                          daysDeadline === "2" ? "contained" : "outlined"
                        }
                        onClick={() => setDaysDeadline("2")}
                        sx={{
                          margin: 1,
                        }}
                      >
                        2 {t("DAYS")}
                      </Button>
                      <Button
                        variant={
                          daysDeadline === "3" ? "contained" : "outlined"
                        }
                        onClick={() => setDaysDeadline("3")}
                        sx={{
                          margin: 1,
                        }}
                      >
                        3 {t("DAYS")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Button
                        variant={
                          daysDeadline === "5" ? "contained" : "outlined"
                        }
                        onClick={() => setDaysDeadline("5")}
                        sx={{
                          margin: 1,
                        }}
                      >
                        5 {t("DAYS")}
                      </Button>
                      <Button
                        variant={
                          daysDeadline === "7" ? "contained" : "outlined"
                        }
                        onClick={() => setDaysDeadline("7")}
                        sx={{
                          margin: 1,
                        }}
                      >
                        7 {t("DAYS")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                    marginTop: 40,
                    width: "100%",
                  }}
                  onClick={handleSubmit}
                  disabled={
                    isEmployerDeclarationForced === groupSettings.force_employer_declaration &&
                    isEmploymentContractForced === groupSettings.force_employment_contract &&
                    daysDeadline === groupSettings.days_deadline
                  }
                >
                  {t("APPLY CHANGES")}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card sx={cardStyle}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              sx={{ minHeight: "650px" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    margin: 1,
                    marginBottom: 2,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {t("User administration")}
                </Typography>
                <Typography
                  mx={1}
                  my={3}
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {t("User administration text")}
                </Typography>

                <TableContainer>
                  <Table>
                    <colgroup>
                      <col style={{ width: "90%" }} />
                      <col style={{ width: "10%" }} />
                    </colgroup>
                    <TableBody>
                      {organizationUsers.length !== 0 &&
                        (rowsPerPage > 0
                          ? organizationUsers.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : organizationUsers
                        ).map((row) => <Row key={row.name_agent} row={row} />)}
                    </TableBody>
                  </Table>
                  {rowsPerPage !== 0 && (
                    <TablePagination
                      labelRowsPerPage={t("Items per page")}
                      labelDisplayedRows={labelDisplayedRows}
                      rowsPerPageOptions={[3, 5, 10]}
                      component="div"
                      count={organizationUsers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TableContainer>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<PersonAddIcon />}
                  style={{
                    borderRadius: 8,
                    fontSize: "14px",
                    minWidth: "50%",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  onClick={handleNewUser}
                >
                  {t("ADD A NEW USER")}
                </Button>
              </Grid>
              <Grid item>
                <TextField
                  label={t("Admin")}
                  value={masterUserName}
                  onChange={handleMasterUser}
                  select
                  disabled={
                    (organizationUsers.find(
                      (user) => user.name_agent === masterUserName
                    ).email !==
                      oldMasterUserEmail)
                  }
                  style={{ width: "100%", marginBottom: 50 }}
                >
                  {organizationUserNames}
                </TextField>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <LoadingDataComponent alreadyRendered={alreadyRendered} />

        <AddNewUser
          addNewUserWindow={addNewUserWindow}
          setAddNewUserWindow={setAddNewUserWindow}
          setError={setError}
        />
        <MasterUserChangeDialog />
      </Grid>
      <MessagePopup state={error} setState={setError} message={error} />
    </motion.div>
  );
};

export default CompanySettings;
