import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import PrivateRoute from "../components/PrivateRoute";
import ForgotPassword from "../pages/ForgotPassword";
import CompletingSignUp from "../pages/CompletingSignUp";
import VerifyingEmail from "../pages/VerifyingEmail";
import EmailNotVerified from "../pages/EmailNotVerified";
import TenantPage from "../pages/TenantPage";

import componentsMap from "../utils/componentsMap";

import { AnimatePresence } from "framer-motion";

const privateElements = componentsMap.map((component) => (
  <Route
    key={component.name}
    path={component.route}
    element={
      <PrivateRoute>
        <Dashboard content={component.name} />
      </PrivateRoute>
    }
  ></Route>
));

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait' initial={false}>
      <Routes location={location} key={location.pathname}>
        {privateElements}
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/complete_registration" element={<CompletingSignUp />} />
        <Route path="/verify_mail" element={<VerifyingEmail />} />
        <Route path="/email_not_verified" element={<EmailNotVerified />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/tenant_page/:id" element={<TenantPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
