import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ArticleIcon from '@mui/icons-material/Article';

import { ReactComponent as Unfurnished } from "../../../logos/unfurnished.svg";
import { ReactComponent as Upholstered } from "../../../logos/upholstered.svg";
import { ReactComponent as Furnished } from "../../../logos/furnished.svg";

import { motion } from "framer-motion";

import {
  selectUserSettings,
  selectGroupSettings,
  groupSettingsChanged,
  selectTenants,
  selectLandlords,
  fetchLandlords,
  tenantsEdited,
} from "../../../slicers/userDataSlice";

import {
  snackbarMessageChanged,
  selectHouseholdActive,
} from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import LoadingDataComponent from "../../../components/LoadingDataComponent";
import TooltipHelp from "../../../components/TooltipHelp";

import { useTranslation } from "react-i18next";
import ContractPreferences from "./ContractPreferences";

const cardStyle = {
  bgcolor: "#FFFFFF",
  borderRadius: 2,
  boxShadow: 3,
  marginTop: 3,
};

const accordionStyle = {
  padding: 0.5,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginY: 1,
};

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

const AccordionNoBorder = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: null,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const defaultProps = {
  direction: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  p: 0.5,
  border: 2,
  borderRadius: 4,
  style: { minWidth: "150px", minHeight: "150px" },
  sx: [
    {
      "&:hover": {
        color: "#f45a2a",
        cursor: "pointer",
      },
    },
  ],
};

function daysInMonth(month, year) {
  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
  // but by using 0 as the day it will give us the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  return new Date(year, month, 0).getDate();
}

function getPaymentDeadline(month, year) {
  let date;
  if (month === "11") {
    date = new Date(Number(year) + 1, 0, 0);
  } else if (month === "12") {
    date = new Date(Number(year) + 1, 1, 0);
  } else {
    date = new Date(year, Number(month) + 1, 0);
  }
  return (
    String(date.getFullYear()) +
    "-" +
    String(date.getMonth() + 1) +
    "-" +
    String(date.getDate())
  );
}

function makeid(length) {
  var result = "";
  var characters =
    "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const contractSteps = [
  {
    number: 1,
    label: "Introduction",
  },
  {
    number: 2,
    label: "Parties",
  },
  {
    number: 3,
    label: "Property",
  },
  {
    number: 4,
    label: "Terms",
  },
  {
    number: 5,
    label: "Special conditions",
  },
];

const breadcumbcardStyle = {
  padding: 1,
  px: 3,
  bgcolor: "#ffffff",
  borderRadius: 10,
  boxShadow: 3,
  marginBottom: 1,
};

const paperStyle = {
  padding: 3,
  maxWidth: "500px",
  minWidth: "350px",
  bgcolor: "#FFFFFF",
};

const BreadcrumbsCard = ({ contractParameters }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let propertyNumber = contractParameters["Property number"];

  if (propertyNumber === "X") {
    propertyNumber = "";
  } else if (contractParameters["Property address line 2"]) {
    propertyNumber =
      " " +
      propertyNumber +
      " " +
      contractParameters["Property address line 2"];
  } else {
    propertyNumber = " " + propertyNumber;
  }

  const propertyLocation =
    contractParameters["Property address"] +
    propertyNumber +
    ", " +
    contractParameters["Post code"] +
    " " +
    contractParameters["Property city"];

  return (
    <Grid container justifyContent="flex-start">
      <Card sx={breadcumbcardStyle}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            startIcon={<HomeIcon sx={{ fontWeight: "bold" }} />}
            variant="text"
            onClick={() => navigate("/")}
          >
            <Typography sx={{ fontSize: 16 }}>
              {t("Tenant Overview")}
            </Typography>
          </Button>
          <Button variant="text" onClick={() => navigate("/dossier_summary")}>
            <Typography sx={{ fontSize: 16 }}>
              {t("Dossier Summary")}: {propertyLocation}
            </Typography>
          </Button>

          <Typography sx={{ fontSize: 16 }}>
            {t("Rental contract generator")}
          </Typography>
        </Breadcrumbs>
      </Card>
    </Grid>
  );
};

const IntroductionStep = ({
  contractParameters,
  setContractParameters,
  setTabValue,
}) => {
  const { t } = useTranslation();
  const [changeAddressDialogOpen, setChangeAddressDialogOpen] = useState(false);

  const tenantsSelector = useSelector(selectTenants);

  let propertyNumber = " " + contractParameters["Property number"];
  let addressAdditional = " " + contractParameters["Property address line 2"];
  if (contractParameters["Property number"] === "X") {
    propertyNumber = "";
  }
  if (!contractParameters["Property address line 2"]) {
    addressAdditional = "";
  }

  const propertyLocation1 =
    contractParameters["Property address"] + propertyNumber + addressAdditional;

  const propertyLocation2 =
    contractParameters["Post code"] + " " + contractParameters["Property city"];

  function goToNextStep() {
    let nextStep = 2;
    if (contractParameters["currentStep"] !== 1) {
      nextStep = contractParameters["currentStep"];
    }

    // Update state variable
    setContractParameters({
      ...contractParameters,
      currentStep: nextStep,
    });
    setTabValue(1);
  }

  const ChangeAddressDialog = () => {
    const propertyAddressRef = useRef();
    const propertyNumberRef = useRef();
    const propertyAdditionalRef = useRef();
    const propertyPostCodeRef = useRef();
    const propertyCityRef = useRef();

    const dispatch = useDispatch();

    async function handleChangeAddress(event) {
      event.preventDefault();

      // Update records in Tenants table
      const payload = {
        parentRequest: contractParameters["Parent request"],
        table: "Tenants",
        "Property address": propertyAddressRef.current.value,
        "Property number": propertyNumberRef.current.value,
        "Property address line 2": propertyAdditionalRef.current.value,
        "Property city": propertyCityRef.current.value,
        "Post code": propertyPostCodeRef.current.value,
      };

      axios.post(configData.backendURL + "/update_household_records", payload);

      // Update state variable
      setContractParameters({
        ...contractParameters,
        "Property address": propertyAddressRef.current.value,
        "Property number": propertyNumberRef.current.value,
        "Property address line 2": propertyAdditionalRef.current.value,
        "Post code": propertyPostCodeRef.current.value,
        "Property city": propertyCityRef.current.value,
      });

      dispatch(
        tenantsEdited(
          tenantsSelector.map((t) =>
            t["Parent request"] === contractParameters["Parent request"]
              ? {
                ...t,
                "Property address": propertyAddressRef.current.value,
                "Property number": propertyNumberRef.current.value,
                "Property address line 2":
                  propertyAdditionalRef.current.value,
                "Property city": propertyCityRef.current.value,
                "Post code": propertyPostCodeRef.current.value,
              }
              : t
          )
        )
      );

      setChangeAddressDialogOpen(false);
    }

    return (
      <Dialog
        open={changeAddressDialogOpen}
        PaperProps={{ style: paperStyle }}
        onClose={() => setChangeAddressDialogOpen(false)}
      >
        <DialogContent>
          <form onSubmit={handleChangeAddress}>
            <Grid container direction="column" spacing={1.5}>
              <Grid item align="center" mb={5}>
                <Typography variant="h6">
                  {" "}
                  {t("What is the right address?")}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    <TextField
                      label={t("Rental property address")}
                      variant="outlined"
                      inputRef={propertyAddressRef}
                      defaultValue={contractParameters["Property address"]}
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={t("Number")}
                      variant="outlined"
                      inputRef={propertyNumberRef}
                      defaultValue={contractParameters["Property number"]}
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label={t("Address Line 2")}
                  variant="outlined"
                  defaultValue={contractParameters["Property address line 2"]}
                  inputRef={propertyAdditionalRef}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label={t("Post code")}
                      variant="outlined"
                      inputRef={propertyPostCodeRef}
                      defaultValue={contractParameters["Post code"]}
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      label={t("City")}
                      variant="outlined"
                      inputRef={propertyCityRef}
                      defaultValue={contractParameters["Property city"]}
                      value={contractParameters["Property "]}
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={8}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => setChangeAddressDialogOpen(false)}
                    >
                      {t("CANCEL")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      {t("CONTINUE")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      spacing={5}
      mt={-3}
    >
      <Grid item>
        <Typography>{t("Is this the right address")}</Typography>
      </Grid>

      <Grid item>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {propertyLocation1}
        </Typography>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {propertyLocation2}
        </Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={5}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: "130px", minHeight: "60px" }}
              style={{ fontSize: "20px" }}
              onClick={() => setChangeAddressDialogOpen(true)}
            >
              {t("NO")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              sx={{ minWidth: "130px", minHeight: "60px" }}
              style={{ fontSize: "20px" }}
              onClick={goToNextStep}
            >
              {t("YES")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ChangeAddressDialog />
    </Grid>
  );
};

const PartiesStep = ({
  contractParameters,
  setContractParameters,
  tenants,
  setTenants,
  setTabValue,
}) => {
  const { t } = useTranslation();
  const theme = createTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const tenantsSelector = useSelector(selectTenants);

  const [saveToLandlord, setSaveToLandlord] = useState(false);

  const groupValues = useSelector(selectGroupSettings);
  const landlords = useSelector(selectLandlords);

  const [showAll, setShowAll] = useState(
    contractParameters["Landlord Initial"] || landlords.length === 0
  );

  const dispatch = useDispatch();

  function isTenantOrResident(tenant, contractParameters) {
    if ("residents" in contractParameters) {
      if (
        contractParameters["residents"].includes(
          tenant["ID first name"] + " " + tenant["ID last name"]
        )
      ) {
        return "Resident";
      }
    }
    if ("tenants" in contractParameters) {
      if (!contractParameters["tenants"].includes(tenant.properize_id)) {
        return "Not include";
      }
    }
    return "Tenant";
  }

  const [tenantsArray, setTenantsArray] = useState(
    [...tenants].map((tenant) => ({
      role: isTenantOrResident(tenant, contractParameters),
      ...tenant,
    }))
  );

  const [showEditTenantDialog, setShowEditTenantDialog] = useState(false);
  const [currentTenant, setCurrentTenant] = useState({});

  const residentNamesArray = [...tenants]
    .map((tenant) => ({
      role: isTenantOrResident(tenant, contractParameters),
      ...tenant,
    }))
    .filter((tenant) => tenant.role === "Resident")
    .map(
      (resident) => resident["ID first name"] + " " + resident["ID last name"]
    );

  const [residentsArray, setResidentsArray] = useState(
    "residents" in contractParameters
      ? contractParameters["residents"].filter(
        (resident) => !residentNamesArray.includes(resident)
      )
      : []
  );
  const [residentDialogOpen, setResidentDialogOpen] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    let landlordId = null;
    // Save new landlord
    if (saveToLandlord) {
      landlordId = "LAND" + makeid(16);
      const payload = {
        landlord_id: landlordId,
        group_id: groupValues.group_id,
        initials: contractParameters["Landlord Initial"],
        last_name: contractParameters["Landlord Last name"],
        email: contractParameters["Landlord Email"],
        phone_number: "",
        iban_number: contractParameters["Landlord IBAN"],
        has_property_manager:
          contractParameters["Property Manager"] !== "landlord",
        property_manager:
          contractParameters["Property Manager"] === "landlord"
            ? ""
            : contractParameters["Property Manager"],
        emergency_contact_details:
          contractParameters["Property Manager"] === "landlord"
            ? ""
            : contractParameters["Emergency contact details"],
        is_new_landlord: true,
      };
      axios
        .post(configData.backendURL + "/create_edit_landlord", payload)
        .then((response) => {
          dispatch(fetchLandlords(groupValues.group_id));
        });
    }

    // Save data and move to next step
    let nextStep = 3;
    if (contractParameters["currentStep"] !== 2) {
      nextStep = contractParameters["currentStep"];
    }

    setContractParameters({
      ...contractParameters,
      currentStep: nextStep,
      landlord_id: contractParameters['landlord_id'] ? contractParameters['landlord_id'] : landlordId,
      tenants: tenantsArray
        .filter((tenant) => tenant.role === "Tenant")
        .map((tenant) => tenant.properize_id),
      residents: [
        ...tenantsArray
          .filter((tenant) => tenant.role === "Resident")
          .map(
            (tenant) => tenant["ID first name"] + " " + tenant["ID last name"]
          ),
        ...residentsArray,
      ],
    });

    setTenants(tenantsArray);

    setTabValue(2);
  }

  const EditTenantDialog = () => {
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    async function handleChangeTenantData(e) {
      e.preventDefault();
      setLoading(true);
      let payload = {
        properize_id: currentTenant["properize_id"],
        "ID first name": firstNameRef.current.value,
        "ID last name": lastNameRef.current.value,
        Email: emailRef.current.value,
      };
      await axios.post(configData.backendURL + "/update_airtable_row", payload);

      let newArr = [...tenants];
      const objIndex = newArr.findIndex(
        (obj) => obj.properize_id === currentTenant.properize_id
      );
      newArr[objIndex]["ID first name"] = firstNameRef.current.value;
      newArr[objIndex]["ID last name"] = lastNameRef.current.value;
      newArr[objIndex]["Email"] = emailRef.current.value;

      setTenants(newArr);
      setTenantsArray(newArr.map((tenant) => ({ ...tenant, role: "Tenant" })));

      dispatch(
        tenantsEdited(
          tenantsSelector.map((t) =>
            t["properize_id"] === currentTenant["properize_id"]
              ? {
                ...t,
                "ID first name": firstNameRef.current.value,
                "ID last name": lastNameRef.current.value,
                Email: emailRef.current.value,
              }
              : t
          )
        )
      );

      setShowEditTenantDialog(false);
    }

    return (
      <Dialog open={showEditTenantDialog}>
        <DialogContent sx={{ maxWidth: "450px" }}>
          <Grid item align="center" mb={5}>
            <Typography variant="h6">
              {t("Change tenant personal data")}
            </Typography>
          </Grid>
          <form onSubmit={handleChangeTenantData}>
            <TextField
              label={t("First Name")}
              defaultValue={currentTenant["ID first name"]}
              variant="outlined"
              inputRef={firstNameRef}
              fullWidth
              required
              style={{ marginTop: 15 }}
              InputLabelProps={{
                required: false,
              }}
            />
            <TextField
              label={t("Last Name")}
              defaultValue={currentTenant["ID last name"]}
              variant="outlined"
              inputRef={lastNameRef}
              fullWidth
              required
              style={{ marginTop: 15 }}
              InputLabelProps={{
                required: false,
              }}
            />
            <TextField
              label={t("Email")}
              defaultValue={currentTenant["Email"]}
              variant="outlined"
              inputRef={emailRef}
              type="email"
              fullWidth
              required
              style={{ marginTop: 15 }}
              InputLabelProps={{
                required: false,
              }}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              mt={7}
              spacing={2}
            >
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  onClick={() => setShowEditTenantDialog(false)}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  disabled={loading}
                >
                  CONFIRM
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const AddResidentDialog = () => {
    const residentRef = useRef();

    async function addResident(event) {
      event.preventDefault();
      setResidentsArray([...residentsArray, residentRef.current.value]);
      setResidentDialogOpen(false);
    }

    return (
      <Dialog
        open={residentDialogOpen}
        PaperProps={{ style: paperStyle }}
        onClose={() => setResidentDialogOpen(false)}
      >
        <DialogContent>
          <form onSubmit={addResident}>
            <Grid container direction="column" spacing={1.5}>
              <Grid item align="center" mb={5} xs={12}>
                <Typography variant="h6">{t("Add a new resident")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("Full name")}
                  variant="outlined"
                  inputRef={residentRef}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                  rows={2}
                  multiline
                />
              </Grid>

              <Grid item xs={12} mt={4}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => setResidentDialogOpen(false)}
                    >
                      {t("CANCEL")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      {t("ADD")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const roles = ["Tenant", "Resident", "Not include"].map((role) => (
    <MenuItem key={role} value={role}>
      {t(role)}
    </MenuItem>
  ));

  function pullLandlordInfo(event, selectedLandlord) {
    setContractParameters({
      ...contractParameters,
      landlord_id: selectedLandlord["id"],
      "Autocomplete Clicked": true,
      "Landlord Initial": selectedLandlord["initials"],
      "Landlord Last name": selectedLandlord["last_name"],
      "Landlord Email": selectedLandlord["email"],
      "Landlord IBAN": selectedLandlord["iban_number"],
      "Property Manager": selectedLandlord["property_manager"]
        ? selectedLandlord["property_manager"]
        : "landlord",
      "Emergency contact details":
        selectedLandlord["emergency_contact_details"],
    });

    setShowAll(true);
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {!showAll && (
          <div>
            <Typography
              sx={{
                margin: 1,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {t("Landlord information")}
            </Typography>

            <Grid container alignItems="center" spacing={3} mb={8} mt={1}>
              <Grid item xs={6} ml={2}>
                <Autocomplete
                  disablePortal
                  options={landlords.map((landlord) => ({
                    ...landlord,
                    label:
                      landlord["initials"] +
                      ". " +
                      landlord["last_name"] +
                      " (" +
                      landlord["email"] +
                      ")",
                  }))}
                  sx={{ minWidth: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Start typing last name or email address")}
                      required
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  )}
                  onChange={pullLandlordInfo}
                />
              </Grid>

              <Grid item align="center" xs={0.5}>
                <Typography>{t("or")}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => setShowAll(true)}
                >
                  {t("INSERT LANDLORD MANUALLY")}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}

        <Grid container direction="row" spacing={isLgUp ? 20 : 2}>
          <Grid item xs={12} lg={6}>
            {showAll && (
              <div>
                <Typography
                  sx={{
                    margin: 1,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {t("Landlord information")}
                </Typography>

                <Grid container direction="row" spacing={1.5} mt={2}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label={t("Initials")}
                      variant="outlined"
                      value={contractParameters["Landlord Initial"]}
                      onChange={(e) =>
                        setContractParameters({
                          ...contractParameters,
                          "Landlord Initial": e.target.value,
                        })
                      }
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      label={t("Last Name")}
                      variant="outlined"
                      value={contractParameters["Landlord Last name"]}
                      onChange={(e) =>
                        setContractParameters({
                          ...contractParameters,
                          "Landlord Last name": e.target.value,
                        })
                      }
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("Email")}
                      variant="outlined"
                      value={contractParameters["Landlord Email"]}
                      onChange={(e) =>
                        setContractParameters({
                          ...contractParameters,
                          "Landlord Email": e.target.value,
                        })
                      }
                      required
                      fullWidth
                      type="email"
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={t("IBAN number")}
                      variant="outlined"
                      value={contractParameters["Landlord IBAN"]}
                      onChange={(e) =>
                        setContractParameters({
                          ...contractParameters,
                          "Landlord IBAN": e.target.value,
                        })
                      }
                      required
                      fullWidth
                      InputLabelProps={{
                        required: false,
                      }}
                    />
                  </Grid>
                  {!contractParameters["Autocomplete Clicked"] && (
                    <Grid item xs={12} ml={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={saveToLandlord}
                            onChange={() => setSaveToLandlord(!saveToLandlord)}
                          />
                        }
                        label={t("Save this person to landlord directory")}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container alignItems="center" wrap="nowrap" mt={4}>
                  <Typography
                    sx={{
                      margin: 1,
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    {t("Tenant information")}
                  </Typography>

                  <TooltipHelp json_flag={"Tenant information tooltip"} />
                </Grid>
              </div>
            )}
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  border: "none",
                  padding: 1.5,
                },
                [`& .${tableRowClasses.root}`]: {
                  height: "40px",
                },
                margin: 1,
              }}
            >
              <TableBody>
                {tenantsArray.map((tenant) => (
                  <TableRow key={tenant.properize_id}>
                    <TableCell
                      align="left"
                      sx={{ display: { sm: "block", xs: "none" } }}
                    >
                      <Grid
                        container
                        wrap="nowrap"
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography
                                style={{ fontSize: 14, fontWeight: "bold" }}
                              >
                                {tenant["ID first name"]}{" "}
                                {tenant["ID last name"]}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography style={{ fontSize: 12 }}>
                                {tenant["Email"]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setCurrentTenant(tenant);
                              setShowEditTenantDialog(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label={t("Role")}
                        value={tenant["role"]}
                        size="small"
                        onChange={(event) => {
                          let newArr = [...tenantsArray];
                          const objIndex = newArr.findIndex(
                            (obj) => obj.properize_id === tenant.properize_id
                          );
                          newArr[objIndex].role = event.target.value;
                          setTenantsArray(newArr);
                        }}
                        select
                        sx={{ minWidth: "200px" }}
                      >
                        {roles}
                      </TextField>
                    </TableCell>
                  </TableRow>
                ))}

                {residentsArray.map((resident) => (
                  <TableRow key={resident}>
                    <TableCell>
                      <Grid container wrap="nowrap" alignItems="center">
                        <Typography
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          {resident}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            const newResidentsArray = [...residentsArray];
                            setResidentsArray(
                              removeItemAll(newResidentsArray, resident)
                            );
                          }}
                          style={{ borderRadius: 0 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography>{t("Resident")}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button
              color="primary"
              variant="outlined"
              startIcon={<PersonAddIcon />}
              style={{
                borderRadius: 8,
                fontSize: "14px",
                minWidth: "250px",
                maxWidth: "400px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
              onClick={() => setResidentDialogOpen(true)}
            >
              {t("ADD A RESIDENT")}
            </Button>
          </Grid>

          {showAll && (
            <Grid item xs={12} lg={6} p={1}>
              <Typography
                sx={{
                  margin: 1,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {t("Property Manager")}
              </Typography>
              <Typography sx={{ margin: 1, marginTop: 3 }}>
                {t("Who is going to act as a property manager?")}
              </Typography>

              <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Radio
                    checked={
                      contractParameters["Property Manager"] === "landlord"
                    }
                    onChange={() =>
                      setContractParameters({
                        ...contractParameters,
                        "Property Manager": "landlord",
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography>{t("Landlord")}</Typography>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Radio
                    checked={
                      contractParameters["Property Manager"] !== "landlord"
                    }
                    onChange={() =>
                      setContractParameters({
                        ...contractParameters,
                        "Property Manager": "",
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography>{t("Property Manager")}</Typography>
                </Grid>
              </Grid>

              {contractParameters["Property Manager"] !== "landlord" && (
                <TextField
                  label={t("Property Manager")}
                  variant="outlined"
                  value={contractParameters["Property Manager"]}
                  onChange={(e) =>
                    setContractParameters({
                      ...contractParameters,
                      "Property Manager": e.target.value,
                    })
                  }
                  required
                  fullWidth
                  sx={{ marginTop: 2 }}
                  InputLabelProps={{
                    required: false,
                  }}
                />
              )}

              <TextField
                label={t("Emergency contact details")}
                variant="outlined"
                value={contractParameters["Emergency contact details"]}
                onChange={(e) =>
                  setContractParameters({
                    ...contractParameters,
                    "Emergency contact details": e.target.value,
                  })
                }
                required
                fullWidth
                sx={{ marginTop: 5 }}
                InputLabelProps={{
                  required: false,
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          wrap="nowrap"
          mt={2}
          spacing={2}
        >
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              onClick={() => {
                if (contractParameters["Autocomplete Clicked"]) {
                  setShowAll(false);
                  setContractParameters({
                    ...contractParameters,
                    landlord_id: null,
                    "Autocomplete Clicked": false,
                    "Landlord Initial": "",
                    "Landlord Last name": "",
                    "Landlord Email": "",
                    "Landlord IBAN": "",
                    "Property Manager": "landlord",
                    "Emergency contact details": "",
                  });
                }
                setTabValue(0);
              }}
            >
              {t("BACK")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              disabled={
                tenantsArray.filter((tenant) => tenant.role === "Tenant")
                  .length === 0
              }
            >
              {t("CONTINUE")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <AddResidentDialog />
      <EditTenantDialog />
    </div>
  );
};

const FurnitureItemBox = ({
  contractParameters,
  setContractParameters,
  Title,
  Picture,
}) => {
  const { t } = useTranslation();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function changeFurnitureStatus() {
    setContractParameters({
      ...contractParameters,
      interiorState: Title,
    });
  }
  return (
    <Grid
      container
      {...defaultProps}
      borderColor={
        contractParameters["interiorState"] === Title ? "#f45a2a" : "#000000"
      }
      onClick={changeFurnitureStatus}
    >
      <Grid item>
        <Picture
          width="80"
          height="80"
          fill={
            contractParameters["interiorState"] === Title
              ? "#f45a2a"
              : "#000000"
          }
        />
      </Grid>
      <Grid item>
        <Typography
          variant="title"
          align="center"
          style={{
            color:
              contractParameters["interiorState"] === Title
                ? "#f45a2a"
                : "#000000",
          }}
        >
          {t(toTitleCase(Title))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PropertyStep = ({
  contractParameters,
  setContractParameters,
  setTabValue,
}) => {
  const { t } = useTranslation();
  const theme = createTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const serviceCosts = [
    "General administration costs",
    "Maintenance equipment",
    "Glass and home insurance",
    "Services caretaker building",
    "Entrance and intercom installation",
    "Cleaning common areas in building",
    "Energy cost of common areas in the building",
    "Use and maintenance of the central heating",
    "Use of inventory and appliances",
    "Maintenance common areas in building",
    "Use of private parking spot",
    "Access to internet",
    "Use of storage place",
  ];

  const [customServiceCosts1, setCustomServiceCosts1] = useState(
    contractParameters["customServiceCosts1"]
  );
  const [customServiceCosts2, setCustomServiceCosts2] = useState(
    contractParameters["customServiceCosts2"]
  );
  const [customServiceCosts3, setCustomServiceCosts3] = useState(
    contractParameters["customServiceCosts3"]
  );
  const [customServiceCosts4, setCustomServiceCosts4] = useState(
    contractParameters["customServiceCosts4"]
  );
  const [customServiceCosts5, setCustomServiceCosts5] = useState(
    contractParameters["customServiceCosts5"]
  );

  const customServiceCosts = [
    ["customServiceCosts1", customServiceCosts1, setCustomServiceCosts1],
    ["customServiceCosts2", customServiceCosts2, setCustomServiceCosts2],
    ["customServiceCosts3", customServiceCosts3, setCustomServiceCosts3],
    ["customServiceCosts4", customServiceCosts4, setCustomServiceCosts4],
    ["customServiceCosts5", customServiceCosts5, setCustomServiceCosts5],
  ];

  function handleSubmit(event) {
    event.preventDefault();

    let nextStep = 4;
    if (contractParameters["currentStep"] !== 3) {
      nextStep = contractParameters["currentStep"];
    }

    setContractParameters({
      ...contractParameters,
      currentStep: nextStep,
    });

    setTabValue(3);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={isLgUp ? 20 : 2}>
        <Grid item xs={12} lg={5}>
          <Grid container alignItems="center" wrap="nowrap">
            <Typography
              sx={{
                margin: 1,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {t("Type of property")}
            </Typography>

            <TooltipHelp json_flag={"Type of property tooltip"} />
          </Grid>
          <Typography
            sx={{
              margin: 1,
              marginTop: 3,
              fontSize: 16,
            }}
          >
            {t("What kind of property is the")}{" "}
            {contractParameters["Property address"]}{" "}
            {contractParameters["Property number"]}?
          </Typography>

          <FormControl
            sx={{
              margin: 1.25,
            }}
          >
            <RadioGroup
              name="isSelfContained"
              value={contractParameters["isSelfContained"]}
              onChange={(e) =>
                setContractParameters({
                  ...contractParameters,
                  isSelfContained: e.target.value,
                })
              }
            >
              <FormControlLabel
                value={"yes"}
                control={<Radio size="small" required={true} />}
                label={t("Self-contained living space")}
              />
              <FormControlLabel
                value={"no"}
                control={<Radio size="small" required={true} />}
                label={t("Non-self-contained living space")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid container alignItems="center" wrap="nowrap">
            <Typography
              sx={{
                margin: 1,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {t("Interior")}
            </Typography>

            <TooltipHelp json_flag={"Interior tooltip"} />
          </Grid>
          <Typography
            sx={{
              margin: 1,
              marginTop: 3,
              fontSize: 16,
            }}
          >
            {t("Interior state")}
          </Typography>

          <Grid container spacing={3} p={2}>
            <Grid item>
              <FurnitureItemBox
                contractParameters={contractParameters}
                setContractParameters={setContractParameters}
                Title={"unfurnished"}
                Picture={Unfurnished}
              />
            </Grid>
            <Grid item>
              <FurnitureItemBox
                contractParameters={contractParameters}
                setContractParameters={setContractParameters}
                Title={"upholstered"}
                Picture={Upholstered}
              />
            </Grid>
            <Grid item>
              <FurnitureItemBox
                contractParameters={contractParameters}
                setContractParameters={setContractParameters}
                Title={"furnished"}
                Picture={Furnished}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Typography
        sx={{
          margin: 1,
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {t("Service costs")}
      </Typography>

      <Typography
        sx={{
          margin: 1,
          marginTop: 3,
          fontSize: 16,
        }}
      >
        {t("Service costs paragraph1")} {contractParameters["Property address"]}{" "}
        {contractParameters["Property number"]}? {t("Service costs paragraph2")}
      </Typography>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} lg={8}>
          <Grid container direction="row" spacing={1} p={1}>
            {contractParameters.interiorState !== "furnished" && (
              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        contractParameters.interiorState === "upholstered"
                      }
                      disabled
                    />
                  }
                  label={t("Use of upholstery")}
                />
              </Grid>
            )}
            {contractParameters.interiorState === "furnished" && (
              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={<Checkbox checked disabled />}
                  label={t("Use of upholstery and furniture")}
                />
              </Grid>
            )}
            {serviceCosts.map((cost) => (
              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        const contractParametersNew = { ...contractParameters };
                        contractParametersNew[cost] =
                          !contractParametersNew[cost];
                        setContractParameters(contractParametersNew);
                      }}
                      checked={contractParameters[cost]}
                    />
                  }
                  label={t(cost)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Grid container spacing={1} p={1}>
            {customServiceCosts.map(([label, state, setState]) => (
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Grid item>
                    <Checkbox
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setState(false);
                          const contractParametersNew = {
                            ...contractParameters,
                          };
                          delete contractParametersNew[label];
                          setContractParameters(contractParametersNew);
                        }
                        setState(!state);
                      }}
                      checked={state}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <TextField
                      label={t(label)}
                      variant="outlined"
                      value={contractParameters[label]}
                      onChange={(e) => {
                        const contractParametersNew = { ...contractParameters };
                        contractParametersNew[label] = e.target.value;
                        setContractParameters(contractParametersNew);
                      }}
                      required
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        required: false,
                      }}
                      disabled={!state}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        wrap="nowrap"
        mt={4}
        spacing={2}
      >
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 8,
              fontSize: "18px",
              minWidth: "200px",
            }}
            onClick={() => setTabValue(1)}
          >
            {t("BACK")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              fontSize: "18px",
              minWidth: "200px",
            }}
            disabled={!("interiorState" in contractParameters) || !("isSelfContained" in contractParameters)}
          >
            {t("CONTINUE")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const CSSSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  margin: 20,
  marginTop: 54,

  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 28,
    fontWeight: "normal",
    //top: -6,
    //backgroundColor: "unset",
    //color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-markLabel": {
    fontSize: 18,
    transform: "translate(-50%, -280%)",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#7e7e7e",
    height: 12,
    width: 12,
    borderRadius: 10,
  },
}));

const SliderComponent = ({
  contractParameters,
  setContractParameters,
  basePrice,
}) => {
  const { t } = useTranslation();

  const sliderMarks = [
    {
      value: 1,
      label: "1 " + t("month"),
    },
    {
      value: 2,
      label: "2 " + t("months"),
    },
    {
      value: 3,
      label: "3 " + t("months"),
    },
    {
      value: 4,
      label: t("Custom"),
    },
  ];
  const [sliderValue, setSliderValue] = useState(
    "Deposit Months" in contractParameters
      ? contractParameters["Deposit Months"]
      : 1
  );

  function onDepositMonthsChange(event) {
    setSliderValue(event.target.value);
    if (event.target.value !== 4) {
      setContractParameters({
        ...contractParameters,
        "Deposit Months": event.target.value,
        Deposit: event.target.value * basePrice,
      });
    } else {
      setContractParameters({
        ...contractParameters,
        "Deposit Months": event.target.value,
      });
    }
  }

  return (
    <Box sx={{ margin: 2, marginLeft: 5, marginRight: 8, maxWidth: 600 }}>
      <CSSSlider
        aria-label="Deposit"
        track={false}
        value={sliderValue}
        onChange={onDepositMonthsChange}
        valueLabelDisplay="off"
        step={1}
        marks={sliderMarks}
        min={1}
        max={4}
      />

      {sliderValue === 4 && (
        <TextField
          label={t("Security deposit")}
          variant="outlined"
          value={contractParameters["Deposit"]}
          onChange={(e) =>
            setContractParameters({
              ...contractParameters,
              Deposit: e.target.value,
            })
          }
          type="number"
          required
          fullWidth
          InputLabelProps={{
            required: false,
            shrink: true,
          }}
          sx={{ margin: 2 }}
        />
      )}
    </Box>
  );
};

const TermsStep = ({
  contractParameters,
  setContractParameters,
  setTabValue,
}) => {
  const { t } = useTranslation();
  const theme = createTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  let totalPrice = 0;

  if (contractParameters["Base rental price"]) {
    totalPrice = totalPrice + Number(contractParameters["Base rental price"]);
  }
  if (contractParameters["Service costs"]) {
    totalPrice = totalPrice + Number(contractParameters["Service costs"]);
  }
  if (
    contractParameters["Landlord responsible for supplies"] &&
    contractParameters["Supplies amount"]
  ) {
    totalPrice = totalPrice + Number(contractParameters["Supplies amount"]);
  }

  function handleSubmit(event) {
    event.preventDefault();

    let nextStep = 5;
    if (contractParameters["currentStep"] !== 4) {
      nextStep = contractParameters["currentStep"];
    }

    setContractParameters({
      ...contractParameters,
      currentStep: nextStep,
    });

    setTabValue(4);
  }

  function onStartingDateChange(event) {
    const starting_date = event.target.value;
    let firstPaymentPercentage;

    if (starting_date.slice(8, 10) === "01") {
      firstPaymentPercentage = 1;
    } else {
      const remainingDays =
        daysInMonth(starting_date.slice(5, 7), starting_date.slice(0, 4)) -
        Number(starting_date.slice(8, 10)) +
        1;
      firstPaymentPercentage =
        1 +
        remainingDays /
        daysInMonth(starting_date.slice(5, 7), starting_date.slice(0, 4));
    }

    setContractParameters({
      ...contractParameters,
      "Starting Date": event.target.value,
      firstPaymentPercentage: firstPaymentPercentage,
      "Year to revise contract": Number(starting_date.slice(0, 4)) + 1,
      "First payment deadline": getPaymentDeadline(
        starting_date.slice(5, 7),
        starting_date.slice(0, 4)
      ),
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={isLgUp ? 20 : 2}>
        <Grid item xs={12} lg={5}>
          <Typography
            sx={{
              margin: 1,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {t("Type of rental contract")}
          </Typography>

          <FormControl
            sx={{
              margin: 1.25,
            }}
          >
            <RadioGroup
              name="contractTerm"
              value={contractParameters["contractTerm"]}
              onChange={(e) =>
                setContractParameters({
                  ...contractParameters,
                  contractTerm: e.target.value,
                })
              }
            >
              <FormControlLabel
                value={"Indefinite term with 12 minimum"}
                control={<Radio size="small" required={true} />}
                label={t("Indefinite term with 12 minimum")}
              />
              <FormControlLabel
                value={"Fixed term"}
                control={<Radio size="small" required={true} />}
                label={t("Fixed term")}
              />
            </RadioGroup>
          </FormControl>

          <Typography
            sx={{
              margin: 1,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {t("Price determination")}
          </Typography>

          <TextField
            label={t("Base rental price")}
            variant="outlined"
            value={contractParameters["Base rental price"]}
            type="number"
            onChange={(e) =>
              setContractParameters({
                ...contractParameters,
                "Base rental price": e.target.value,
              })
            }
            required
            fullWidth
            sx={{ marginTop: 2 }}
            InputLabelProps={{
              required: false,
            }}
          />

          <TextField
            label={t("Service costs")}
            variant="outlined"
            value={contractParameters["Service costs"]}
            type="number"
            onChange={(e) =>
              setContractParameters({
                ...contractParameters,
                "Service costs": e.target.value,
              })
            }
            required
            fullWidth
            sx={{ marginTop: 2 }}
            InputLabelProps={{
              required: false,
            }}
          />

          <FormControlLabel
            sx={{ margin: 0, marginBottom: 1, marginTop: 2 }}
            control={
              <Checkbox
                checked={
                  contractParameters["Landlord responsible for supplies"]
                }
                onChange={(e) =>
                  setContractParameters({
                    ...contractParameters,
                    "Landlord responsible for supplies": e.target.checked,
                  })
                }
              />
            }
            label={t("Landlord responsible for supplies")}
          />

          {contractParameters["Landlord responsible for supplies"] && (
            <TextField
              label={t("Supplies amount")}
              variant="outlined"
              value={contractParameters["Supplies amount"]}
              type="number"
              onChange={(e) =>
                setContractParameters({
                  ...contractParameters,
                  "Supplies amount": e.target.value,
                })
              }
              required
              fullWidth
              InputLabelProps={{
                required: false,
              }}
              sx={{ marginBottom: 2 }}
            />
          )}

          <div style={{ borderTop: "2px solid blue " }}></div>
          <Grid
            container
            p={1}
            mt={0.25}
            style={{ backgroundColor: "#e7f3fc", minHeight: "40px" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {t("Total rental price")}€ {totalPrice}
            </Typography>
          </Grid>

          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            sx={{ marginTop: 4 }}
          >
            <Typography
              sx={{
                margin: 1,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {t("Maximum price increase")}
            </Typography>

            <TooltipHelp json_flag={"Maximum price increase tooltip"} />
          </Grid>

          <TextField
            value={contractParameters["rental_increase_cap"]}
            type="number"
            onChange={(e) =>
              setContractParameters({
                ...contractParameters,
                rental_increase_cap: e.target.value,
              })
            }
            label="%"
            variant="outlined"
            required
            InputLabelProps={{
              required: false,
              shrink: true,
            }}
            sx={{ margin: 1 }}
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <Typography
            sx={{
              margin: 1,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {t("Rental contract duration")}
          </Typography>

          <Grid container wrap="nowrap" mt={2} spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                label={t("Starting Date")}
                variant="outlined"
                value={contractParameters["Starting Date"]}
                onChange={onStartingDateChange}
                type="date"
                required
                fullWidth
                InputLabelProps={{
                  required: false,
                  shrink: true,
                }}
              />
            </Grid>

            {contractParameters["contractTerm"] === "Fixed term" && (
              <Grid item xs={12} lg={6}>
                <TextField
                  label={t("Ending Date")}
                  variant="outlined"
                  value={contractParameters["End Date"]}
                  onChange={(e) =>
                    setContractParameters({
                      ...contractParameters,
                      "End Date": e.target.value,
                    })
                  }
                  type="date"
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                    shrink: true,
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Typography
            sx={{
              margin: 1,
              marginTop: 8,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {t("Security deposit")}
          </Typography>

          <SliderComponent
            contractParameters={contractParameters}
            setContractParameters={setContractParameters}
            basePrice={contractParameters["Base rental price"]}
          />
          {contractParameters["Deposit Months"] !== 4 && (
            <Grid pl={4} pr={15}>
              <div style={{ borderTop: "2px solid blue " }}></div>
              <Grid
                container
                p={1}
                mt={0.25}
                style={{ backgroundColor: "#e7f3fc", minHeight: "40px" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Security deposit")}: €{" "}
                  {!("Deposit Months" in contractParameters) &&
                    contractParameters["Base rental price"]}
                  {"Deposit Months" in contractParameters &&
                    contractParameters["Base rental price"] *
                    contractParameters["Deposit Months"]}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        wrap="nowrap"
        mt={4}
        spacing={2}
      >
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 8,
              fontSize: "18px",
              minWidth: "200px",
            }}
            onClick={() => setTabValue(2)}
          >
            {t("BACK")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              borderRadius: 8,
              fontSize: "18px",
              minWidth: "200px",
            }}
          >
            {t("CONTINUE")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const SpecialConditionsStep = ({
  contractParameters,
  setContractParameters,
  setTabValue,
}) => {
  const { t } = useTranslation();

  const [behaviourExpanded, setBehaviourExpanded] = useState(false);
  const [endContractExpanded, setEndContractExpanded] = useState(false);
  const [upkeepExpanded, setUpkeepExpanded] = useState(false);
  const [otherCondExpanded, setOtherCondExpanded] = useState(false);
  const [customExpanded, setCustomExpanded] = useState(false);

  const [behaviourSelected, setBehaviourSelected] = useState(0);
  const [endContractSelected, setEndContractSelected] = useState(0);
  const [upkeepSelected, setUpkeepSelected] = useState(0);
  const [otherCondSelected, setOtherCondSelected] = useState(0);

  const [createCustomConditionOpen, setCreateCustomConditionOpen] =
    useState(false);
  const [penaltiesDialogOpen, setPenaltiesDialogOpen] = useState(false);

  const [amount195Default, setAmount195Default] = useState(
    contractParameters["special_conditions"]["cleaning_costs"]
  );
  const [months12Default, setMonths12Default] = useState(
    contractParameters["special_conditions"]["minimum_commitment"]
  );
  const [kilometers50Default, setKilometers50Default] = useState(
    contractParameters["special_conditions"]["relocation_kilometers"]
  );
  const [earlyTerminationFee500Default, setEarlyTerminationFee500Default] =
    useState(contractParameters["special_conditions"]["early_termination_fee"]);
  const [companyNameList, setCompanyNameList] = useState(
    contractParameters["special_conditions"]["company_name_list"]
  );

  const [loading, setLoading] = useState(false);

  const groupValues = useSelector(selectGroupSettings);
  const contractFeatures = groupValues["contract_features"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const behaviourConditions = [
    { title: "Smoking not allowed" },
    { title: "Pets not allowed" },
    { title: "BBQ and open fire prohibition" },
    { title: "Co-habitation and subletting not allowed" },
    { title: "Keeping common areas tidy" },
    { title: "No nuisance" },
  ];

  const endOfContractConditions = [
    {
      title: "Final cleaning",
      parameters: [
        ["paragraph1", amount195Default, setAmount195Default, "paragraph2"],
      ],
    },
    { title: "Collaboration with viewings" },
    { title: "Final delivery" },
    { title: "Final month and security deposit" },
    {
      title: "Early termination clause on grounds of relocation",
      parameters: [
        ["paragraph 1", months12Default, setMonths12Default, "paragraph 2"],
        [
          "paragraph 3",
          kilometers50Default,
          setKilometers50Default,
          "paragraph 4",
        ],
        ["paragraph 5", months12Default, setMonths12Default, "paragraph 6"],
        [
          "paragraph 7",
          earlyTerminationFee500Default,
          setEarlyTerminationFee500Default,
          "paragraph 8",
        ],
      ],
    },
    {
      title:
        "Early termination clause on grounds of dismissal of not-renewal of residence permit",
      parameters: [
        ["paragraph 1", months12Default, setMonths12Default, "paragraph 2"],
        ["paragraph 3", months12Default, setMonths12Default, "paragraph 4"],
        [
          "paragraph 5",
          earlyTerminationFee500Default,
          setEarlyTerminationFee500Default,
          "paragraph 6",
        ],
      ],
    },
  ];

  const upkeepConditions = [
    { title: "Entering for maintenance work" },
    { title: "Leaks and water damage" },
    { title: "Maintenance property" },
    { title: "Changes to rented object" },
    { title: "Replacing locks" },
    { title: "Tenant costs in case of damage" },
  ];

  const otherConditions = [
    {
      title: "Personal data shared",
      parameters: [
        ["paragraph 1", companyNameList, setCompanyNameList, "paragraph 2"],
      ],
    },
    { title: "Third party liability insurance" },
    {
      title: "Household contents and third-party liability insurance",
    },
    { title: "Extrajudicial costs" },
  ];

  const cardsValues = [
    {
      title: "Behaviour",
      expanded: behaviourExpanded,
      setExpanded: setBehaviourExpanded,
      selected: behaviourSelected,
      setSelected: setBehaviourSelected,
      conditionsArray: behaviourConditions,
    },
    {
      title: "End of the contract",
      expanded: endContractExpanded,
      setExpanded: setEndContractExpanded,
      selected: endContractSelected,
      setSelected: setEndContractSelected,
      conditionsArray: endOfContractConditions,
    },
    {
      title: "Upkeep",
      expanded: upkeepExpanded,
      setExpanded: setUpkeepExpanded,
      selected: upkeepSelected,
      setSelected: setUpkeepSelected,
      conditionsArray: upkeepConditions,
    },
    {
      title: "Other special conditions",
      expanded: otherCondExpanded,
      setExpanded: setOtherCondExpanded,
      selected: otherCondSelected,
      setSelected: setOtherCondSelected,
      conditionsArray: otherConditions,
    },
  ];

  useEffect(() => {
    const toIterate = [
      [behaviourConditions, setBehaviourSelected],
      [endOfContractConditions, setEndContractSelected],
      [upkeepConditions, setUpkeepSelected],
      [otherConditions, setOtherCondSelected],
    ];
    for (const [conditions, setSelected] of toIterate) {
      let selectedCounter = 0;

      const ConditionsArray = conditions.map((condition) => condition.title);

      for (const condition of ConditionsArray) {
        if (condition in contractParameters) {
          if (contractParameters[condition]) {
            selectedCounter = selectedCounter + 1;
          }
        }
      }
      setSelected(selectedCounter);
    }
    const paramsArrayToIterate = [
      ["cleaning_costs", setAmount195Default],
      ["minimum_commitment", setMonths12Default],
      ["relocation_kilometers", setKilometers50Default],
      ["early_termination_fee", setEarlyTerminationFee500Default],
      ["company_name_list", setCompanyNameList],
    ];
    for (const [param, setParam] of paramsArrayToIterate) {
      if (param in contractParameters["special_conditions"]) {
        setParam(contractParameters["special_conditions"][param]);
      }
    }

    // We check if any custom condition has been previously deleted
    const customConditionsArray = contractFeatures["custom_conditions"].map(
      (condition) => condition.title
    );
    const customConditions = contractParameters["custom_conditions"].filter(
      (cond) => customConditionsArray.includes(cond.title)
    );
    setContractParameters({
      ...contractParameters,
      custom_conditions: customConditions,
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    let nextStep = 6;
    if (contractParameters["currentStep"] !== 5) {
      nextStep = contractParameters["currentStep"];
    }

    setContractParameters({
      ...contractParameters,
      currentStep: nextStep,
      special_conditions: {
        cleaning_costs: amount195Default,
        minimum_commitment: months12Default,
        relocation_kilometers: kilometers50Default,
        early_termination_fee: earlyTerminationFee500Default,
        company_name_list: companyNameList,
      },
    });

    setTabValue(5);
  }

  function onClickCheckbox(event, label, selected, setSelected) {
    if (event.target.checked) {
      setSelected(selected + 1);
    } else {
      setSelected(selected - 1);
    }
    const contractParametersNew = contractParameters;
    contractParametersNew[label] = event.target.checked;
    setContractParameters(contractParametersNew);
  }

  function onClickCustomCondition(event, condition) {
    let customConditions = [...contractParameters["custom_conditions"]];

    // If it is ticked, we add the condition to the array
    if (event.target.checked) {
      customConditions.push(condition);
    } else {
      // else, we remove it
      customConditions = customConditions.filter(
        (cond) => cond.title !== condition.title
      );
    }

    setContractParameters({
      ...contractParameters,
      custom_conditions: customConditions,
    });
  }

  function deleteCustomCondition(condition) {
    let customConditions = [...contractFeatures["custom_conditions"]];

    customConditions = customConditions.filter(
      (cond) => cond.title !== condition.title
    );

    let payload = {
      group_id: groupValues.group_id,
      contract_features: {
        ...contractFeatures,
        custom_conditions: customConditions,
      },
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        dispatch(groupSettingsChanged(payload));
      });

    setContractParameters({
      ...contractParameters,
      custom_conditions: customConditions,
    });
  }

  async function downloadDraftContract() {
    setLoading(true);

    if ("landlord_id" in contractParameters) {
      const payload = {
        parentRequest: contractParameters["Parent request"],
        landlord_id: contractParameters["landlord_id"],
      };
      axios.post(configData.backendURL + "/update_airtable_row", payload);
    }

    const response = await axios.post(
      configData.backendURL + "/generate_draft_contract",
      contractParameters,
      { responseType: "blob" }
    );

    setTimeout(() => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", response.headers["x-filename"]); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
    setLoading(false);
    navigate("/dossier_summary?par_request=" + contractParameters["Parent request"])
  }

  const CustomConditionDialog = () => {
    const customConditionTitleRef = useRef();
    const customConditionParagraphRef = useRef();

    async function createCustomCondition(event) {
      event.preventDefault();

      const customConditions = [
        ...contractFeatures["custom_conditions"],
        {
          title: customConditionTitleRef.current.value,
          paragraph: customConditionParagraphRef.current.value,
        },
      ];

      let payload = {
        group_id: groupValues.group_id,
        contract_features: {
          ...contractFeatures,
          custom_conditions: customConditions,
        },
      };

      axios
        .post(configData.backendURL + "/update_properize_groups_data", payload)
        .then((response) => {
          dispatch(groupSettingsChanged(payload));
          dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        });

      setCreateCustomConditionOpen(false);
    }

    return (
      <Dialog
        open={createCustomConditionOpen}
        PaperProps={{ style: paperStyle }}
        onClose={() => setCreateCustomConditionOpen(false)}
      >
        <DialogContent>
          <form onSubmit={createCustomCondition}>
            <Grid container direction="column" spacing={1.5}>
              <Grid item align="center" mb={5}>
                <Typography variant="h6">
                  {t("Enter your new custom condition")}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label={t("Title")}
                  variant="outlined"
                  inputRef={customConditionTitleRef}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                  rows={2}
                  multiline
                />
              </Grid>

              <Grid item>
                <TextField
                  label={t("Paragraph")}
                  variant="outlined"
                  inputRef={customConditionParagraphRef}
                  required
                  fullWidth
                  InputLabelProps={{
                    required: false,
                  }}
                  rows={4}
                  multiline
                />
              </Grid>

              <Grid item xs={12} mt={4}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => setCreateCustomConditionOpen(false)}
                    >
                      {t("CANCEL")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      {t("ADD")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const PenaltiesDialog = () => {
    return (
      <Dialog
        open={penaltiesDialogOpen}
        //PaperProps={{ style: paperStyle }}
        onClose={() => setPenaltiesDialogOpen(false)}
        fullWidth
      >
        <DialogContent>
          <ContractPreferences
            contractParameters={contractParameters}
            setContractParameters={setContractParameters}
            setPenaltiesDialogOpen={setPenaltiesDialogOpen}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {cardsValues.map((card) => (
          <Grid style={{ width: "100%" }} sx={accordionStyle}>
            <AccordionNoBorder
              expanded={card.expanded}
              onChange={() => card.setExpanded(!card.expanded)}
              key={card.title}
            >
              <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                      {t(card.title)}
                    </Typography>
                  </Grid>
                  <Grid item align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      {card.selected > 0 && card.selected}{" "}
                      {card.selected > 0 && t("conditions selected")}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  m={1}
                  ml={2}
                >
                  {card.conditionsArray.map((condition) => (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              onClickCheckbox(
                                e,
                                condition.title,
                                card.selected,
                                card.setSelected
                              )
                            }
                            checked={contractParameters[condition.title]}
                          />
                        }
                        label={t(condition.title)}
                      />
                      {contractParameters[condition.title] &&
                        !("parameters" in condition) && (
                          <Typography
                            sx={{
                              marginLeft: 4,
                              marginBottom: 1,
                              marginRight: 4,
                              fontSize: "14px",
                              fontWeight: "light",
                              fontStyle: "italic",
                            }}
                          >
                            "{t(condition.title + " paragraph")}"
                          </Typography>
                        )}
                      {contractParameters[condition.title] &&
                        "parameters" in condition && (
                          <Grid
                            sx={{
                              marginLeft: 4,
                              marginBottom: 1,
                              marginRight: 4,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "light",
                                fontStyle: "italic",
                              }}
                            >
                              "
                              {condition.parameters.map(
                                ([
                                  paragraphBefore,
                                  state,
                                  setState,
                                  paragraphAfter,
                                ]) => (
                                  <>
                                    {t(condition.title + " " + paragraphBefore)}{" "}
                                    <input
                                      type="text"
                                      id={condition.title}
                                      name={condition.title}
                                      value={state}
                                      onChange={(e) => setState(e.target.value)}
                                      required
                                      size="10"
                                    ></input>{" "}
                                    {t(condition.title + " " + paragraphAfter)}
                                  </>
                                )
                              )}
                              "
                            </Typography>
                          </Grid>
                        )}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </AccordionNoBorder>
          </Grid>
        ))}
        <Grid style={{ width: "100%" }} sx={accordionStyle}>
          <AccordionNoBorder
            expanded={customExpanded}
            onChange={() => setCustomExpanded(!customExpanded)}
            key={"customConditions"}
          >
            <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                    {t("Custom condition")} {groupValues.company_name}
                  </Typography>
                </Grid>
                <Grid item align="center">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {contractParameters["custom_conditions"].length > 0 &&
                      contractParameters["custom_conditions"].length}{" "}
                    {contractParameters["custom_conditions"].length > 0 &&
                      t("conditions selected")}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {contractFeatures["custom_conditions"].length === 0 && (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  alignItems="center"
                  spacing={1}
                  m={3}
                >
                  <Grid item>
                    <Typography>
                      {t("You have not created any custom conditions yet")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{
                        borderRadius: 8,
                        fontSize: "14px",
                        minWidth: "200px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                      onClick={() => setCreateCustomConditionOpen(true)}
                    >
                      {t("CREATE YOUR FIRST CUSTOM CONDITION")}
                    </Button>
                  </Grid>
                </Grid>
              )}

              {contractFeatures["custom_conditions"].length > 0 && (
                <div>
                  {contractFeatures["custom_conditions"].map((condition) => (
                    <Grid
                      container
                      direction="column"
                      xs={12}
                      m={1}
                      ml={2}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Grid container wrap="nowrap">
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    onClickCustomCondition(e, condition)
                                  }
                                  checked={
                                    contractParameters[
                                      "custom_conditions"
                                    ].filter(
                                      (cond) => cond.title === condition.title
                                    ).length > 0
                                  }
                                />
                              }
                              label={t(condition.title)}
                            />
                          </Grid>
                          <Grid item>
                            {" "}
                            <IconButton
                              onClick={() => deleteCustomCondition(condition)}
                              style={{ borderRadius: 0 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {contractParameters["custom_conditions"].filter(
                          (cond) => cond.title === condition.title
                        ).length > 0 && (
                            <Typography
                              sx={{
                                marginLeft: 4,
                                marginBottom: 1,
                                marginRight: 4,
                                fontSize: "14px",
                                fontWeight: "light",
                                fontStyle: "italic",
                              }}
                            >
                              "{t(condition.paragraph)}"
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  ))}

                  {contractFeatures["custom_conditions"].length < 5 && (
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{
                        borderRadius: 8,
                        fontSize: "14px",
                        minWidth: "250px",
                        maxWidth: "400px",
                        margin: "20px",
                      }}
                      onClick={() => setCreateCustomConditionOpen(true)}
                    >
                      {t("ADD CUSTOM CONDITION")}
                    </Button>
                  )}
                </div>
              )}
            </AccordionDetails>
          </AccordionNoBorder>
        </Grid>
        <Grid>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<ReportProblemIcon />}
            style={{
              borderRadius: 8,
              fontSize: "14px",
              minWidth: "250px",
              maxWidth: "400px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
            onClick={() => setPenaltiesDialogOpen(true)}
          >
            {t("REVIEW PENALTIES")}
          </Button>
        </Grid>
        <Grid container justifyContent="flex-end" wrap="nowrap" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              onClick={() => setTabValue(3)}
            >
              {t("BACK")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              startIcon={<ArticleIcon />}
              onClick={downloadDraftContract}
            >
              {t("DOWNLOAD EDITABLE DRAFT")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <LoadingDataComponent alreadyRendered={!loading} />
      <CustomConditionDialog />
      <PenaltiesDialog />
    </div>
  );
};

export default function GenerateContract() {
  const { t } = useTranslation();

  const [contractParameters, setContractParameters] = useState({
    currentStep: 1,
    custom_conditions: [],
    "Autocomplete Clicked": false,
  });

  const tenantsSelector = useSelector(selectTenants);
  const householdActive = useSelector(selectHouseholdActive);
  const parentRequest = householdActive.parentRequest;

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);
  const [tenants, setTenants] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (parentRequest) {
      setTenants(
        tenantsSelector.filter((row) => row["Parent request"] === parentRequest)
      );

      const householdData = tenantsSelector.find(
        (row) => row["Parent request"] === parentRequest
      );

      const addressObject = {
        "Property address": householdData["Property address"],
        "Property number": householdData["Property number"],
        "Property address line 2": householdData["Property address line 2"],
        "Property city": householdData["Property city"],
        "Post code": householdData["Post code"],
      }

      setContractParameters({
        ...contractParameters,
        ...addressObject
      })

      axios
        .post(
          configData.backendURL + "/find_contract_parameters_api",
          { "parent_request": parentRequest }
        )
        .then(async (response) => {
          setContractParameters({
            ...contractParameters,
            ...addressObject,
            "Parent request": parentRequest,
            ...response.data,
          })
        })

    }
  }, [parentRequest, tenantsSelector]);


  if (!parentRequest) {
    return navigate("/");
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Rental contract generator")}
      </Typography>

      <Grid>
        {contractParameters["Property address"] && (
          <Grid item sx={{ display: { sm: "block", xs: "none" } }}>
            <BreadcrumbsCard contractParameters={contractParameters} />
          </Grid>
        )}

        <Grid sx={cardStyle}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            scrollButtons="auto"
            sx={{ margin: 0, borderTopLeftRadius: 2, borderTopRightRadius: 2 }}
          >
            {contractSteps.map((step) => (
              <Tab
                sx={{
                  backgroundColor:
                    tabValue === step.number - 1 ? "#FFFFFF" : "#e7f3fc",
                  borderLeft:
                    step.number !== 1 && tabValue === step.number - 2 ? 1 : 0,
                  borderRight:
                    step.number !== 5 && tabValue === step.number ? 1 : 0,
                  borderBottom: tabValue === step.number - 1 ? 0 : 1,
                  borderColor: "#CCCCCC",
                  margin: 0,
                }}
                label={
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {contractParameters["currentStep"] > step.number && (
                      <Checkbox checked={true} />
                    )}

                    <Typography
                      color={
                        tabValue < step.number - 1 ? "inherited" : "primary"
                      }
                    >
                      {step.number}. {t(step.label)}
                    </Typography>
                  </Grid>
                }
                disabled={tabValue < step.number}
                {...a11yProps(step.number - 1)}
              />
            ))}
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <IntroductionStep
              contractParameters={contractParameters}
              setContractParameters={setContractParameters}
              setTabValue={setTabValue}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <PartiesStep
              contractParameters={contractParameters}
              setContractParameters={setContractParameters}
              tenants={tenants}
              setTenants={setTenants}
              setTabValue={setTabValue}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <PropertyStep
              contractParameters={contractParameters}
              setContractParameters={setContractParameters}
              setTabValue={setTabValue}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <TermsStep
              contractParameters={contractParameters}
              setContractParameters={setContractParameters}
              setTabValue={setTabValue}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <SpecialConditionsStep
              contractParameters={contractParameters}
              setContractParameters={setContractParameters}
              setTabValue={setTabValue}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </motion.div>
  );
}
