import React, { useRef, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import axios from "axios";

import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  selectGroupSettings,
  newUserCreated,
} from "../../../slicers/userDataSlice";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import configData from "../../../utils/config.json";

import LoadingDataComponent from "../../../components/LoadingDataComponent";

import { useTranslation } from "react-i18next";

const CssTextField = styled(TextField)({
  marginTop: 15,
});

export default function AddNewUser({ addNewUserWindow, setAddNewUserWindow, setError }) {
  const { t } = useTranslation();

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, signup, updateCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(true);

  const paperStyle = {
    padding: 3,
    minHeight: "20vw",
    maxWidth: "400px",
    bgcolor: "#ffffff",
    borderColor: "#257cff",
    borderRadius: 16,
    border: 2,
    boxShadow: 8,
  };

  const userSettings = useSelector(selectUserSettings);
  const groupSettings = useSelector(selectGroupSettings);
  const dispatch = useDispatch();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError(false);
      setLoading(true);
      setAlreadyRendered(false);

      let originalUser = currentUser;
      await signup(emailRef.current.value, passwordRef.current.value);
      updateCurrentUser(originalUser);

      const payload = {
        email: emailRef.current.value,
        name: nameRef.current.value,
        groupId: groupSettings.group_id,
        companyName: groupSettings.company_name,
        language: userSettings.language,
      };

      axios
        .post(configData.backendURL + "/add_account_to_properize_user", payload)
        .then((response) => {
          setLoading(false);
          setAlreadyRendered(true);
          setError(
            "An email was sent to " +
              emailRef.current.value +
              ". Please confirm the account."
          );
          dispatch(
            newUserCreated({
              name_agent: nameRef.current.value,
              email: emailRef.current.value,
              master_user: false,
            })
          );
          setAddNewUserWindow(false)
        });
    } catch (signUpError) {
      if (
        signUpError.toString().includes("The email address is already in use")
      ) {
        setError("The email address is already in use");
        setAlreadyRendered(true);
      } else if (
        signUpError
          .toString()
          .includes("Password should be at least 6 characters")
      ) {
        setError("Password should be at least 6 characters");
        setAlreadyRendered(true);
      } else {
        setError(signUpError.toString());
        setAlreadyRendered(true);
      }
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={addNewUserWindow}
      PaperProps={{ style: paperStyle }}
      onClose={() => setAddNewUserWindow(false)}
    >
      <DialogContent>
        <Grid
          container
          direction="column"
          align="center"
          justifyContent="flex-end"
        >
          <Grid item maxWidth="350px">
            <Avatar style={{ backgroundColor: "white" }}>
              <PersonAddIcon color={"secondary"} />
            </Avatar>
            <Typography sx={{ margin: 2, fontSize: 20, fontWeight: "bold" }}>
            {t("Add a new user")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <CssTextField
                label={t("Name")}
                variant="outlined"
                inputRef={nameRef}
                InputLabelProps={{
                  required: false,
                }}
                fullWidth
                required
              />
              <CssTextField
                label={t("Email")}
                variant="outlined"
                inputRef={emailRef}
                type="email"
                InputLabelProps={{
                  required: false,
                }}
                fullWidth
                required
              />
              <CssTextField
                label={t("Password")}
                variant="outlined"
                type="password"
                inputRef={passwordRef}
                InputLabelProps={{
                  required: false,
                }}
                fullWidth
                required
              />
              <CssTextField
                label={t("Repeat password")}
                variant="outlined"
                type="password"
                inputRef={passwordConfirmRef}
                InputLabelProps={{
                  required: false,
                }}
                fullWidth
                required
              />

              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 40,
                }}
                onClick={() => setAddNewUserWindow(false)}
                disabled={loading}
              >
                {t("CANCEL")}
              </Button>

              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 10,
                }}
                disabled={loading}
              >
                {t("ADD")}
              </Button>
            </form>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <LoadingDataComponent alreadyRendered={alreadyRendered} />
      </DialogContent>
    </Dialog>
  );
}
