import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbarMessage: "",
  householdActive: { parentRequest: false, tenantDetail: false },
};

const globalStateVariablesSlice = createSlice({
  name: "globalStateVariables",
  initialState,
  reducers: {
    snackbarMessageChanged(state, action) {
      state.snackbarMessage = action.payload;
    },
    householdClicked(state, action) {
      state.householdActive = action.payload;
    },
  },
});

export const selectSnackbarMessage = (state) =>
  state.globalStateVariables.snackbarMessage;

  export const selectHouseholdActive = (state) =>
  state.globalStateVariables.householdActive;

export const { snackbarMessageChanged, householdClicked } = globalStateVariablesSlice.actions;

export default globalStateVariablesSlice.reducer;
