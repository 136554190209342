import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import LoadingDataComponent from "../../../components/LoadingDataComponent";

import {
  selectGroupSettings,
  groupSettingsChanged,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import { useSelector, useDispatch } from "react-redux";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import MessagePopup from "../../../components/MessagePopup";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginBottom: 1,
  maxWidth: "600px",
};

const Billing = () => {
  const { t } = useTranslation();

  const [groupId, setGroupId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [accountType, setAccountType] = useState(false);

  const [alreadyRendered, setAlreadyRendered] = useState(false);

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const groupSettings = useSelector(selectGroupSettings);

  useEffect(() => {
    setGroupId(groupSettings.group_id);
    setCompanyName(groupSettings.company_name);
    setPhoneNumber(groupSettings.phone_number);
    setBillingEmail(groupSettings.billing_email);
    setAccountType(groupSettings.account_type);
    setAlreadyRendered(true);
  }, [groupSettings]);

  function handleSubmit(e) {
    e.preventDefault();
    setAlreadyRendered(false);

    let payload = {
      group_id: groupId,
      company_name: companyName,
      phone_number: phoneNumber,
      billing_email: billingEmail,
      account_type: accountType,
    };

    axios
      .post(configData.backendURL + "/update_properize_groups_data", payload)
      .then((response) => {
        dispatch(groupSettingsChanged(payload));
        dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        setAlreadyRendered(false);
      });
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 1,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Billing")}
      </Typography>

      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Billing")}
      </Typography>

      <Grid container direction="row" spacing={2} sx={{ maxWidth: "1200px" }}>
        <Grid item>
          <Card sx={cardStyle}>
            <Typography
              sx={{
                margin: 1,
                marginBottom: 2,
                fontSize: 20,
                fontWeight: "bold",
                flexGrow: 1,
              }}
            >
              {t("Main information")}
            </Typography>
            <TextField
              label={t("Company name")}
              variant="outlined"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              style={{ width: "100%", marginTop: 20 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t("Company email")}
              variant="outlined"
              value={billingEmail}
              onChange={(event) => setBillingEmail(event.target.value)}
              style={{ width: "100%", marginTop: 20 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t("Phone number")}
              variant="outlined"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              style={{ width: "100%", marginTop: 20 }}
              InputLabelProps={{ shrink: true }}
            />

            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{
                borderRadius: 16,
                fontSize: "18px",
                marginTop: 40,
                width: "100%",
              }}
              onClick={handleSubmit}
              disabled={
                companyName === groupSettings.company_name &&
                billingEmail === groupSettings.billing_email &&
                phoneNumber === groupSettings.phone_number
              }
            >
              {t("APPLY CHANGES")}
            </Button>
          </Card>
        </Grid>
      </Grid>
      <MessagePopup state={error} setState={setError} message={error} />
      <LoadingDataComponent alreadyRendered={alreadyRendered} />
    </motion.div>
  );
};

export default Billing;
