import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Draggable from "react-draggable";

import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, alpha } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  selectUserSettings,
  selectLandlords,
  landlordAccessed,
  fetchTenantsForAdvancedList,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";
import { motion } from "framer-motion";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import {
  DataGrid,
  enUS,
  frFR,
  GridToolbarContainer,
  nlNL,
} from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Point } from "../../../utils/Point";

import { useTranslation } from "react-i18next";
import AddLandlord from "./AddEditLandlord";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  minWidth: "800px",
  marginRight: 2,
};

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#f45a2a",
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "#f45a2a",
        },
      },
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "32ch",
  },
}));

function Toolbar({ setSearchValue, setDeleteClicked, selectionModel }) {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer
        style={{
          minHeight: "45px",
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t("Search") + "…"}
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                </Search>
              </Grid>

              {selectionModel.length !== 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={() => setDeleteClicked(true)}
                    style={{ borderRadius: 0 }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                    <Typography
                      sx={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("REMOVE")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    </ThemeProvider>
  );
}

const Landlords = () => {
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [addLandlordWindow, setAddLandlordWindow] = useState(false);

  const { t } = useTranslation();

  const nodeRef = useRef(null);

  const dispatch = useDispatch();
  const landlords = useSelector(selectLandlords);
  const userSettings = useSelector(selectUserSettings);

  let localeText;
  if (userSettings.language === "nl") {
    localeText = nlNL;
  } else {
    localeText = enUS;
  }

  function useClickObserver(callback) {
    const [dragStartPos, setDragStartPos] = useState(new Point());
    const onStart = (_, data) => {
      setDragStartPos(new Point(data.x, data.y));
    };
    const onStop = (_, data) => {
      const dragStopPoint = new Point(data.x, data.y);
      if (Point.dist(dragStartPos, dragStopPoint) < 5) {
        callback();
      }
    };
    return { onStart, onStop };
  }

  useEffect(() => {
    if (landlords.length !== 0) {
      
      setRows(landlords);
    }
  }, [landlords]);

  function MoveRecords(action) {
    setDeleteClicked(false);
    const selectedIDs = new Set(selectionModel);
    setRows((r) => r.filter((x) => !selectedIDs.has(x.id)));

    let payload = {
      parent_request_array: selectionModel,
      delete: false,
      archive: false,
    };

    if (action === "archive") {
      payload.archive = true;
      dispatch(
        snackbarMessageChanged(
          selectionModel.length + t("records moved to archive")
        )
      );
    } else if (action === "delete") {
      payload.delete = true;
      dispatch(
        snackbarMessageChanged(selectionModel.length + t("records deleted"))
      );
    } else {
      dispatch(
        snackbarMessageChanged(
          selectionModel.length + t("records moved to ongoing")
        )
      );
    }

    axios
      .post(configData.backendURL + "/move_records2", payload)
      .then((response) => {
        dispatch(fetchTenantsForAdvancedList(userSettings.group_id));
      });
  }

  const [searchValue, setSearchValue] = useState(null);

  const DeleteDialog = () => {
    return (
      <Dialog open={deleteClicked}>
        <DialogContent>
          <DialogContentText>
            {t("You are removing") +
              selectionModel.length +
              t("Do you want to continue")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => MoveRecords("delete")}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const renderFullName = (params) => {
    const initials = params.row["initials"];
    const lastName = params.row["last_name"];

    return (
      <div>
        <strong>
          {initials}. {lastName}
        </strong>
      </div>
    );
  };

  const RenderDetailsButton = (params) => {
    const landlordId = params.row["id"];
    const navigate = useNavigate();

    const onClick = () => {
      dispatch(landlordAccessed(landlordId));
      navigate("/landlord_profile?landlordId=" + landlordId);
    };
    return (
      <Button variant="contained" onClick={onClick} sx={{ width: "90%" }}>
        {t("VIEW")}
      </Button>
    );
  };

  const columns = [
    { field: "id", headerName: "landlord_id", hide: true },
    {
      field: "landlord_full_name",
      headerName: t("Landlord"),
      minWidth: 150,
      flex: true,
      disableColumnMenu: true,
      renderCell: renderFullName,
      disableClickEventBubbling: true,
    },
    {
      field: "email",
      headerName: t("E-mail"),
      minWidth: 150,
      flex: true,
      disableColumnMenu: true,
    },
    {
      field: "Details",
      headerName: "",
      minWidth: 100,
      maxWidth: 200,
      flex: true,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "ConcatAllColumns",
      headerName: "ConcatAllColumns",
      hide: true,
      valueGetter: (params) => {
        return (
          params.row["initials"] +
          ". " +
          params.row["last_name"] +
          " " +
          params.row["email"]
        );
      },
    },
  ];

  function CustomNoRowsOverlay() {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {t("There are no landlords. Add your first one")}
      </Grid>
    );
  }

  return (
    <motion.div
    initial="initial"
    animate="in"
    variants={{
      initial: {
        x: "-100%",
      },
      in: {
        x: 0,
      },
      out: {
        x: "100%",
      },
    }}
    transition={{
      type: "spring",
      mass: 0.2,
      duration: 100,
    }}
    exit="out"
  >
      <Typography
        color="secondary"
        sx={{ marginLeft: 2, marginBottom: 1, fontSize: 24 }}
      >
        {t("Landlords")}
      </Typography>

      <Card sx={cardStyle}>
        <Grid container style={{ height: "78vh", width: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              localeText={
                localeText.components.MuiDataGrid.defaultProps.localeText
              }
              components={{
                Toolbar: Toolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                toolbar: {
                  setSearchValue: setSearchValue,
                  setDeleteClicked: setDeleteClicked,

                  selectionModel: selectionModel,
                },
              }}
              filterModel={{
                items: [
                  {
                    columnField: "ConcatAllColumns",
                    operatorValue: "contains",
                    value: searchValue,
                  },
                ],
              }}
              rows={rows}
              columns={columns}
              autoPageSize
              pagination
              disableColumnSelector
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              selectionModel={selectionModel}
            />
          </ThemeProvider>
          <Draggable
            nodeRef={nodeRef}
            {...useClickObserver(() => setAddLandlordWindow(true))}
          >
            <Grid
              ref={nodeRef}
              style={{
                position: "absolute",
                bottom: "15%",
                right: "3%",
                zIndex: "999",
              }}
            >
              <Fab aria-label="add" color="secondary">
                <AddIcon />
              </Fab>
            </Grid>
          </Draggable>
        </Grid>
      </Card>
      <DeleteDialog />
      {addLandlordWindow && (
        <AddLandlord
          addLandlordWindow={addLandlordWindow}
          setAddLandlordWindow={setAddLandlordWindow}
        />
      )}
    </motion.div>
  );
};

export default Landlords;
