import React from "react";
import { TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

function AutoCompleteAddress({ setStreet, setHouseNumber, setPostCode, setCity, addressLabel, placeHolderLabel}) {
  const { t } = useTranslation();
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    onPlaceSelected: (place) => {
      setStreet(place.address_components.find((element) => element.types.includes('route')).short_name)
      setPostCode(place.address_components.find((element) => element.types.includes('postal_code')).short_name)
      setCity(place.address_components.find((element) => element.types.includes('locality')).short_name)
      setHouseNumber(place.address_components.find((element) => element.types.includes('street_number')).short_name)
    },
    //inputAutocompleteValue: "country",
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: ["nl", "be", "fr"] },
    },
  });

  return (
    <div style={{ marginTop: "15px"}}>
      <TextField
        fullWidth
        placeholder={placeHolderLabel || t("AddressGooglePlaceholder")}
        label={addressLabel || t("Rental property address")}
        variant="outlined"
        inputRef={materialRef}
        required
        InputLabelProps={{ required: false }}
      />
    </div>
  );
}

export default AutoCompleteAddress;
