import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert, AlertTitle } from "@mui/material";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LinkIcon from "@mui/icons-material/Link";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";

import LogoutIcon from "@mui/icons-material/Logout";

import { ReactComponent as ProperizeWhiteLogo } from "../../logos/properize-monochrome-white.svg";

import { useAuth } from "../../contexts/AuthContext";
import {
  selectUserSettings,
  selectGroupSettings,
} from "../../slicers/userDataSlice";
import { useSelector } from "react-redux/es/exports";

import { useTranslation } from "react-i18next";

const categories = [
  {
    id: "Main",
    children: [
      {
        id: "Tenant Overview",
        icon: <HomeIcon />,
        navigateTo: "/",
        requiredAdminCredential: false,
      },
      {
        id: "Landlords",
        icon: <PeopleAltIcon />,
        navigateTo: "/landlords",
        requiredAdminCredential: false,
      },
      {
        id: "Landing Page",
        icon: <LinkIcon />,
        navigateTo: "/landing_page",
        requiredAdminCredential: false,
      },
    ],
  },
  {
    id: "Service",
    children: [
      {
        id: "Company Settings",
        icon: <SettingsIcon />,
        navigateTo: "/company_settings",
        requiredAdminCredential: true,
      },
      {
        id: "Notifications",
        icon: <NotificationsIcon />,
        navigateTo: "/notifications",
        requiredAdminCredential: true,
      },
    ],
  },
];

const item = {
  py: "7px",
  px: 2,
  color: "#FFFFFF",
  "&:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
  "&:hover": {
    bgcolor: "#f45a2a",
  },
};

const itemCategory = {
  //boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 3,
  px: 4,
};

export default function Navigator(props) {
  const { handleDrawerToggle, drawerWidth, ...other } = props;

  const isAdmin = useSelector(selectUserSettings).master_user;
  const groupSettings = useSelector(selectGroupSettings);

  const daysLeft = Math.ceil(
    15 - (new Date() - new Date(groupSettings.created)) / (1000 * 60 * 60 * 24)
  );
  const navigate = useNavigate();

  const { logout } = useAuth();

  const { t } = useTranslation();

  async function handleLogout() {
    await logout();
    window.location.assign("/login");
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...itemCategory, backgroundColor: "#257cff" }}>
          <Typography sx={{ display: { xs: "none", md: "block" } }}>
            <ProperizeWhiteLogo width="138" height="36" />
          </Typography>
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#257cff" }}>
            {children.map(
              ({ id: childId, icon, navigateTo, requiredAdminCredential }) =>
                (!requiredAdminCredential ||
                  (requiredAdminCredential && isAdmin)) && (
                  <ListItem
                    disablePadding
                    sx={{ ...item }}
                    key={childId}
                    onClick={() => {
                      handleDrawerToggle();
                      navigate(navigateTo);
                    }}
                  >
                    <ListItemButton sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{t(childId)}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                )
            )}

            {id !== "Service" && <Divider sx={{ my: 2 }} />}
          </Box>
        ))}

        <ListItem
          disablePadding
          sx={{
            ...item,
            bottom: 10,
            position: "fixed",
            width: drawerWidth,
            display: { xs: "block", md: "none" },
          }}
          key={t("Logout")}
          onClick={handleLogout}
        >
          <ListItemButton sx={item}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{"Logout"}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      {groupSettings.account_type === "Trial" && daysLeft >= 0 && (
        <Alert
          severity="info"
          sx={{
            margin: 2,
          }}
        >
          <AlertTitle>{t("Trial account")}</AlertTitle>
          {daysLeft > 0 && (
            <>
              {t("You have")} {daysLeft} {t("days left")}
            </>
          )}
          {daysLeft === 0 && <>{t("Today is the last day")}</>}
        </Alert>
      )}
    </Drawer>
  );
}
