import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import configData from "../utils/config.json";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import properizeLogo from'../logos/properize.png';

const paperStyle = {
  padding: 4,
  minHeight: "20vw",
  maxWidth: 450,
  bgcolor: "primary.main",
  color: "#FFFFFF",
  borderColor: "#257cff",
  borderRadius: 8,
  border: 2,
  boxShadow: 8,
};

export default function VerifyingEmail() {
  const { t } = useTranslation();

  const [emailVerified, setEmailVerified] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");

  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      user_id: user_id,
    };
  
    axios
      .post(configData.backendURL + "/verify_account", payload)
      .then((response) => {
        setEmailVerified(true);
      });
  }, [])

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      align="center"
      justifyContent="center"
      height="90vh"
    >
      <Grid item mt={2}>
        <img
          src={properizeLogo}
          alt="properizelogo"
          height="50"
        />
      </Grid>
      <Grid item>
        <Paper sx={paperStyle}>
          <Typography sx={{ margin: 2, fontSize: 24 }}>
            {t("Thanks for signing up!")}
          </Typography>
          {!emailVerified && (
            <Typography align="left" mt={5} mx={2} sx={{ fontSize: 16 }}>
              {t("Verifying email")}...
            </Typography>
          )}
          {!emailVerified && (
            <Typography align="left" mt={5} mx={2} sx={{ fontSize: 16 }}>
              {t("Please wait a few seconds")}...
            </Typography>
          )}
          {emailVerified && (
            <Typography align="left" mt={5} mx={2} sx={{ fontSize: 16 }}>
              {t("Email verified")}
            </Typography>
          )}
          {emailVerified && (
            <Button
              color="secondary"
              variant="contained"
              style={{
                fontSize: "18px",
                marginTop: 50,
              }}
              fullWidth
              onClick={() => {
                navigate("/");
              }}
            >
              {t("LOG IN")}
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
