import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
    background: {
      default: "#e7f3fc",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <AnimatedRoutes />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
