import React from "react";

import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  selectUserSettings,
  selectGroupSettings,
} from "../../slicers/userDataSlice";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Grid, Typography } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import LogoutIcon from '@mui/icons-material/Logout';

import { ReactComponent as ProperizeWhiteLogo } from "../../logos/properize-monochrome-white.svg";

import { useTranslation } from "react-i18next";

export default function Header(props) {
  const { onDrawerToggle } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const { logout } = useAuth();
  const navigate = useNavigate();
  const userValues = useSelector(selectUserSettings);
  const groupValues = useSelector(selectGroupSettings);

  const { t } = useTranslation();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const RenderMenu = () => {
    async function handleEditProfile() {
      navigate("/profile");
      handleMenuClose();
    }
    async function handleBilling() {
      navigate("/billing");
      handleMenuClose();
    }
    async function handleLogout() {
      await logout();
      window.location.assign("/login");
    }
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditProfile}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <EditIcon color="primary" />
            </Grid>
            <Grid item>
              <Typography>
                {t("Profile")}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        {userValues.master_user && <MenuItem onClick={handleBilling}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <EuroIcon color="primary" />
            </Grid>
            <Grid item>
              <Typography>
                {t("Billing")}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>}

        <MenuItem onClick={handleLogout}>
          <Grid container wrap="nowrap" spacing={2} sx={{ color: "red" }}>
            <Grid item>
              <LogoutIcon />
            </Grid>
            <Grid item>
              <Typography>
                {t("Logout")}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#257cff", padding: 1, height: "65px" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, padding: 1, display: { xs: "block", md: "none" } }}
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          <Grid
            container
            justifyContent="center"
            textAlign="center"
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <Typography
              key="logo"
              sx={{
                marginRight: 5,
                marginTop: 1,
              }}
            >
              <ProperizeWhiteLogo width="138" height="36" />
            </Typography>
          </Grid>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ borderRadius: 0 }}
            >
              <Grid
                container
                direction="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Grid container direction="column" mr={4}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {groupValues.company_name &&
                      groupValues.company_name.length < 50 &&
                      groupValues.company_name}
                    {groupValues.company_name &&
                      groupValues.company_name.length >= 50 &&
                      groupValues.company_name.split(" ")[0]}
                    {"\u00a0\u00a0"}
                  </Typography>
                  <Typography>
                    {userValues.name_agent &&
                      userValues.name_agent.length < 20 &&
                      userValues.name_agent}
                    {userValues.name_agent &&
                      userValues.name_agent.length >= 20 &&
                      userValues.name_agent.split(" ")[0]}
                    {"\u00a0\u00a0"}
                  </Typography>
                </Grid>
                <AccountCircle sx={{ fontSize: 32 }} />
              </Grid>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <RenderMenu />
    </Box>
  );
}
