import React, { useEffect, useState } from "react";
import axios from "axios";
import configData from "../../../utils/config.json";

import { useTranslation } from "react-i18next";

import properizeLogo from "../../../logos/properize.png";
import mockUper from "../../../logos/mockuper.png";

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import {
  selectUserSettings,
  userSettingsChanged,
} from "../../../slicers/userDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import welcomeLottie from "../../../lottie_files/lf20_puciaact.json";
import processLoading from "../../../lottie_files/125790-process-loading-animation.json";
import checkList from "../../../lottie_files/39995-check-list-animation.json";

const Screen1 = () => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Grid container alignItems="center" direction="column" wrap="nowrap">
        <img src={properizeLogo} alt="properizelogo" height="50" />

        <Typography color="secondary" fontSize={18} mt={2}>
          {t("Welcome to Properize")}
        </Typography>
        <Typography fontSize={14} mt={2} align="center">
          {t("Welcome screen paragraph 1")}
        </Typography>
        <Typography fontSize={14} mt={4}>
          {t("Welcome screen paragraph 2")}
        </Typography>

        <div
          style={{
            maxWidth: "325px",
            maxHeight: "500px",
            marginTop: "20px",
          }}
        >
          <Player autoplay loop src={welcomeLottie} />
        </div>
      </Grid>
    </DialogContent>
  );
};

const Screen2 = () => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Grid container alignItems="center" direction="column" wrap="nowrap">
        <Typography fontWeight="bold" mt={1}>
          {t("Get your first tenant screened")}
        </Typography>
        <Typography fontSize={14} mt={4}>
          {t("There are two ways to invite tenants to Properize")}:
        </Typography>
        <Typography fontSize={14} mt={1}>
          {t("Way 1")}
        </Typography>
        <Typography fontSize={14} mt={1}>
          {t("Way 2")}
        </Typography>
        <img src={mockUper} alt="mockuper" height="400" />
        <Typography fontSize={12}>
          <span style={{ color: "green", fontSize: 14, fontWeight: "bold" }}>
            {t("RECOMMENDATION")}:
          </span>{" "}
          {t("use the Landing page with your own logo and colours")}
        </Typography>
      </Grid>
    </DialogContent>
  );
};

const Screen3 = () => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Grid container alignItems="center" direction="column" wrap="nowrap">
        <Typography fontWeight="bold" mt={1}>
          {t("Review the screening results")}
        </Typography>
        <Typography fontSize={14} mt={4}>
          {t("Review the screening results paragraph 1")}
        </Typography>
        <Typography fontSize={14} mt={2}>
          {t("Review the screening results paragraph 2")}
        </Typography>
        <div
          style={{
            maxWidth: "325px",
            maxHeight: "500px",
            marginTop: "20px",
          }}
        >
          <Player autoplay loop src={processLoading} />
        </div>
        <Typography fontSize={14} my={1}>
          {t("As a real estate agent, you are in control of the process")}
        </Typography>

        <Typography fontSize={12} mt={2}>
          <span style={{ color: "green", fontSize: 14, fontWeight: "bold" }}>
            {t("RECOMMENDATION")}:{"\u00a0"}
          </span>{" "}
          {t("Try our ROZ contract generator")}
        </Typography>
      </Grid>
    </DialogContent>
  );
};

const Screen4 = () => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Grid container alignItems="center" direction="column" wrap="nowrap">
        <Typography fontWeight="bold" mb={1}>
          {t("Get the deal done!")}
        </Typography>
        <div
          style={{
            maxWidth: "325px",
            maxHeight: "500px",
            marginTop: "20px",
          }}
        >
          <Player autoplay keepLastFrame src={checkList} />
        </div>
        <Typography fontSize={14} my={3}>
          {t("Do you have any questions")}
        </Typography>
        <Typography fontSize={12} mt={2}>
          <span style={{ color: "green", fontSize: 14, fontWeight: "bold" }}>
            {t("RECOMMENDATION")}:{"\u00a0"}
          </span>{" "}
          {t("Watch our 2-minute “Getting started” video tutorial")}
          {"\u00a0"}
          <Link
            fontSize={12}
            href="https://properize.com/hoe-werkt-het/"
            color="primary"
            target="_blank"
          >
            {t("here")}
          </Link>
        </Typography>
      </Grid>
    </DialogContent>
  );
};

export default function WelcomePopUp() {
  const { t } = useTranslation();
  const [welcomeDialog, setWelcomeDialog] = useState(false);
  const userSettings = useSelector(selectUserSettings);
  const [screenStep, setScreenStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    setWelcomeDialog(userSettings.welcome_screen);
  }, [userSettings]);

  function nextStep() {
    if (screenStep < 4) return setScreenStep(screenStep + 1);
    const payload = {
      email: userSettings.email,
      welcome_screen: false,
    };

    axios.post(configData.backendURL + "/update_properize_users_data", payload);

    dispatch(userSettingsChanged(payload));

    setWelcomeDialog(false);
  }

  return (
    <Dialog open={welcomeDialog}>
      <div style={{ minWidth: "300px", maxWidth: "450px", padding: 3 }}>
        {screenStep === 1 && <Screen1 />}
        {screenStep === 2 && <Screen2 />}
        {screenStep === 3 && <Screen3 />}
        {screenStep === 4 && <Screen4 />}
        <Grid container direction="column" wrap="nowrap" p={2} spacing={1}>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              fullWidth
              onClick={() => {
                dispatch(
                  userSettingsChanged({
                    email: userSettings.email,
                    welcome_screen: false,
                  })
                );
                setWelcomeDialog(false);
              }}
            >
              {t("REMIND ME LATER")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{
                borderRadius: 8,
                fontSize: "18px",
                minWidth: "200px",
              }}
              fullWidth
              onClick={nextStep}
            >
              {screenStep !== 4 && t("CONTINUE")}
              {screenStep === 4 && t("LET'S GET STARTED!")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
