import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import properizeLogo from'../logos/properize.png';

import axios from "axios";

import LockIcon from "@mui/icons-material/Lock";
import MessagePopup from "../components/MessagePopup";
import configData from "../utils/config.json";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import navigatorLanguage from "../utils/navigatorLanguage";

const CssTextField = styled(TextField)({
  input: {
    color: "white",
    "&::placeholder": {
      color: "white",
    },
    ":-webkit-autofill": {
      "WebkitBoxShadow": "0 0 0 100px #257cff inset",
      "WebkitTextFillColor": "#fff",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "30px",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function Login() {
  const { t } = useTranslation();

  const emailRef = useRef();
  const passwordRef = useRef();
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const { login, logout, setPersistence } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const language = navigatorLanguage();
  useEffect(() => {
    i18next.changeLanguage(language)
  }, [language])

  const paperStyle = {
    padding: 4,
    minHeight: "20vw",
    width: 350,
    bgcolor: "primary.main",
    color: "#FFFFFF",
    borderColor: "#257cff",
    borderRadius: 8,
    border: 2,
    boxShadow: 8,
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError(false);
      setLoading(true);
      await login(emailRef.current.value.toLowerCase(), passwordRef.current.value);

      if (keepMeLoggedIn) {
        setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } else {
        setPersistence(firebase.auth.Auth.Persistence.NONE);
      }

      let payload = {
        key: "email",
        value: emailRef.current.value.toLowerCase(),
      };

      let response = await axios.post(
        configData.backendURL + "/get_properize_users_data",
        payload
      );

      if (response.data.account_verified) {
        navigate("/");
      } else {
        logout();
        navigate("/email_not_verified");
      }
    } catch (signUpError) {
      if (signUpError.toString().includes("The password is invalid")) {
        setError(t("Invalid password"));
      } else if (signUpError.toString().includes("There is no user record")) {
        setError(t("User does not exist. Please try again"));
      } else {
        setError(signUpError.toString());
      }
    }

    setLoading(false);
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Grid
        container
        spacing={3}
        direction="column"
        align="center"
        justifyContent="center"
        minHeight="100vh"
        mb={5}
      >
        <Grid item maxWidth="350px" mt={2}>
          <img
            src={properizeLogo}
            alt="properizelogo"
            height="50"
          />
        </Grid>
        <Grid item maxWidth="350px">
          <Paper sx={paperStyle}>
            <Avatar style={{ backgroundColor: "white" }}>
              <LockIcon color={"secondary"} />
            </Avatar>
            <Typography sx={{ margin: 2, fontSize: 24, fontWeight: "bold" }}>
              {t("Log In")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <CssTextField
                label={t("Email")}
                variant="outlined"
                type="email"
                inputRef={emailRef}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                required
              />

              <br />
              <br />

              <CssTextField
                label={t("Password")}
                variant="outlined"
                type="password"
                inputRef={passwordRef}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                required
              />
              <br />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    label="Session"
                    onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
                    value={keepMeLoggedIn}
                    style={{
                      color: "#FFF",
                    }}
                  />
                }
                label={t("Keep me logged in")}
              />
              <br />
              <br />
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                }}
                disabled={loading}
              >
                {t("SIGN IN")}
              </Button>
            </form>

            <Button
              variant="text"
              style={{
                color: "#FFF",
                padding: "18px 36px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              fullWidth
              onClick={() => navigate("/forgot-password")}
            >
              {t("FORGOT YOUR PASSWORD?")}
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <br />
          <Typography
            style={{
              color: "#257cff",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {t("Don't have an account yet?")}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => navigate("/signup")}
            style={{
              color: "#257cff",
              fontSize: "16px",
              fontWeight: "bold",
              width: "350px",
            }}
            sx={{
              marginBottom: 5,
            }}
          >
            {t("START YOUR FREE TRIAL")}
          </Button>
        </Grid>
      </Grid>
      <MessagePopup state={error} setState={setError} message={error} />
    </motion.div>
  );
}
