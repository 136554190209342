import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  DialogTitle,
} from "@mui/material";

import {
  selectGroupSettings,
  selectUserSettings,
  fetchTenantsForAdvancedList,
  fetchTenants,
} from "../../../slicers/userDataSlice";

import { snackbarMessageChanged } from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import LoadingDataComponent from "../../../components/LoadingDataComponent";
import MessagePopup from "../../../components/MessagePopup";

import { useTranslation } from "react-i18next";

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const languages = languagesData.map((language) => (
  <MenuItem key={language.country_code} value={language.code}>
    <Grid container wrap="nowrap">
      <Grid
        mr={1}
        className={`flag-icon flag-icon-${language.country_code} mx-2`}
      />
      <Typography>{language.name}</Typography>
    </Grid>
  </MenuItem>
));

const paperStyle = {
  padding: 3,
  maxWidth: "500px",
  minWidth: "350px",
  bgcolor: "#FFFFFF",
};

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const ForceTenantCreationDialog = ({
  existingDossierDialog,
  setExistingDossierDialog,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  async function handleForceSubmit() {
    await handleSubmit(null, true);
    setExistingDossierDialog(false);
  }

  return (
    <Dialog open={existingDossierDialog}>
      <DialogContent>
        <DialogContentText>
          {t("Active request dashboard")}
          <br />
          <br />
          <Grid container justifyContent="space-around">
            <Button
              variant="outlined"
              style={{ width: "47%" }}
              onClick={() => setExistingDossierDialog(false)}
            >
              {t("NO")}
            </Button>
            <Button
              variant="contained"
              style={{ width: "47%" }}
              color="primary"
              onClick={handleForceSubmit}
            >
              {t("YES")}
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default function SendInvitationToCandidate({
  addNewRequestWindow,
  setAddNewRequestWindow,
  householdData,
}) {
  const { t } = useTranslation();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();

  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [addressLine2, setAaddressLine2] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");

  const [notAllowCotenants, setNotAllowCotenants] = useState(false);

  const rentalPriceRef = useRef();

  const [tenantLanguage, setTenantLanguage] = useState(null);

  const [message, setMessage] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [existingDossierDialog, setExistingDossierDialog] = useState(false);

  const userValues = useSelector(selectUserSettings);
  const groupValues = useSelector(selectGroupSettings);
  const dispatch = useDispatch();

  function handleSubmit(e = null, forceDossierCreation = false) {
    if (e != null) e.preventDefault();

    if (groupValues.suspended) {
      return setMessage(t("Suspended account") + "hello@properize.com");
    } else if (!householdData & (!street | !postCode | !city)) {
      return setMessage(t("No valid address"));
    }

    setDataSubmitted(true);
    let number;
    if (houseNumber) {
      number = houseNumber;
    } else {
      number = "X";
    }

    let cost_of_screening;
    if (groupValues.account_type === "Trial") {
      cost_of_screening = 0;
    } else if (groupValues.account_type === "Standard") {
      cost_of_screening = 9;
    } else {
      cost_of_screening = Number(groupValues.cost_screen);
    }
    let payload = {
      forceDossierCreation: forceDossierCreation,
      created_from_dashboard: true,
      fields: {
        notAllowCotenants: notAllowCotenants,
        User_id: Number(groupValues.group_id),
        "Parent request": "PAR" + makeid(16),
        "First name": firstNameRef.current.value,
        "Last name": lastNameRef.current.value,
        Email: emailRef.current.value,
        "Notes (private)":
          "Agent " + userValues.name_agent + " added request from dashboard",
        "Name agent": groupValues.company_name,
        "Email agent": groupValues.email_master,
        "Account Type": groupValues.account_type,
        "Cost of the screen (EUR)": cost_of_screening,
        "Days deadline": Number(groupValues.days_deadline),
        ArchiveRemove: 0,
        Status: "In progress",
        CheckA_Status: "In progress",
        CheckB_Status: "In progress",
        CheckC_Status: "In progress",
        "Initiated by": userValues.name_agent,
        "Flow Completed": "No",
      },
      createdFromLandingPage: false,
    };

    if (householdData) {
      axios.post(configData.backendURL + "/update_household_records", {
        parentRequest: householdData["Parent request"],
        table: "Tenants",
        "Tenant report": null,
        Rating: null,
      });

      payload.fields["Parent request"] = householdData["Parent request"];
      payload.fields["Language"] = householdData["Language"];
      payload.fields["Property address"] = householdData["Property address"];
      payload.fields["Property number"] = householdData["Property number"];
      payload.fields["Property address line 2"] =
        householdData["Property address line 2"];
      payload.fields["Property city"] = householdData["Property city"];
      payload.fields["Post code"] = householdData["Post code"];
      payload.fields["Property price (EUR)"] =
        householdData["Property price (EUR)"];
      payload.fields["Account Type"] = householdData["Account Type"];
      payload.fields["Cost of the screen (EUR)"] = householdData["Cost of the screen (EUR)"];

      if (householdData["Number of children"]) {
        payload.fields["Number of children"] =
          householdData["Number of children"];
      }

      if (householdData["Pets"]) {
        payload.fields["Pets"] = householdData["Pets"];
      }
    } else {
      payload.fields["Language"] = tenantLanguage;
      payload.fields["Property address"] = street;
      payload.fields["Property number"] = number;
      payload.fields["Property city"] = city;
      payload.fields["Property address line 2"] = addressLine2;
      payload.fields["Post code"] = postCode;
      payload.fields["Property price (EUR)"] = Number(
        rentalPriceRef.current.value
      );
    }

    axios
      .post(configData.backendURL + "/create_airtable_record_api", payload)
      .then((response) => {
        if (response.data.existingRequest) {
          setExistingDossierDialog(true);
        } else {
          dispatch(fetchTenantsForAdvancedList(groupValues.group_id));
          dispatch(fetchTenants(groupValues.group_id));
          dispatch(
            snackbarMessageChanged(
              "Request sent to " +
              firstNameRef.current.value +
              " " +
              lastNameRef.current.value
            )
          );
          setAddNewRequestWindow(false);
        }
        setDataSubmitted(false);
      });
    return 0;
  }

  const regionNames = new Intl.DisplayNames([userValues.language], {
    type: "region",
  });

  const countries = [
    "NL",
    "BE",
  ].map((code) => (
    <MenuItem key={code} value={code}>
      <Grid
        sx={{ "& > img": { ml: 2, mr: 2, flexShrink: 0 } }}
      >
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
          alt=""
        />
        {regionNames.of(code)}
      </Grid>
    </MenuItem>
  ));

  return (
    <div>
      <ForceTenantCreationDialog
        existingDossierDialog={existingDossierDialog}
        setExistingDossierDialog={setExistingDossierDialog}
        handleSubmit={handleSubmit}
      />
      <Dialog
        open={addNewRequestWindow}
        PaperProps={{ style: paperStyle }}
        onClose={() => setAddNewRequestWindow(false)}
      >
        <DialogTitle>
          <Grid p={1} align="center">
            {householdData
              ? t("Add a tenant request")
              : t("Create new dossier")}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column">
              {householdData ? null : (
                <Alert severity="info" color="error">
                  <AlertTitle>{t("Please Read")}</AlertTitle>
                  {t("You only need to invite one person")}
                </Alert>
              )}
              <Grid container direction="row" mt={2} spacing={1}>
                <Grid item xs={12} sm={householdData ? 12 : 6}>
                  <TextField
                    label={t("First Name")}
                    variant="outlined"
                    inputRef={firstNameRef}
                    required
                    fullWidth
                    size={householdData ? "medium" : "small"}
                  />
                </Grid>
                <Grid item xs={12} sm={householdData ? 12 : 6}>
                  <TextField
                    label={t("Last Name")}
                    variant="outlined"
                    inputRef={lastNameRef}
                    required
                    fullWidth
                    size={householdData ? "medium" : "small"}
                  />
                </Grid>
                <Grid item xs={12} sm={householdData ? 12 : 7}>
                  <TextField
                    label={t("Email")}
                    variant="outlined"
                    inputRef={emailRef}
                    required
                    fullWidth
                    size={householdData ? "medium" : "small"}
                    type="email"
                  />
                </Grid>

                {!householdData && (
                  <>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        value={tenantLanguage}
                        onChange={(event) =>
                          setTenantLanguage(event.target.value)
                        }
                        label={t("Tenant Language")}
                        select
                        fullWidth
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {languages}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} mt={1} align="center">
                      <Typography fontWeight="bold">
                        {t("Rental property")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        label={t("Street")}
                        variant="outlined"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        required
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={t("Number")}
                        variant="outlined"
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                        required
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        label={t("Address Line 2")}
                        variant="outlined"
                        value={addressLine2}
                        onChange={(e) => setAaddressLine2(e.target.value)}
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={t("Post code")}
                        variant="outlined"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                        required
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("City")}
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("Country")}
                        size="small"
                        variant="outlined"
                        fullWidth
                        select
                      >
                        {countries}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <TextField
                        label={t("Monthly Rental Price")}
                        type="number"
                        variant="outlined"
                        inputRef={rentalPriceRef}
                        fullWidth
                        required
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} >
                      <FormControlLabel
                        style={{ paddingLeft: 5 }}
                        control={
                          <Checkbox
                            onChange={() =>
                              setNotAllowCotenants(!notAllowCotenants)
                            }
                            value={notAllowCotenants}
                          />
                        }
                        label={t("Do not allow to add co-tenants")}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 40,
                }}
                onClick={() => setAddNewRequestWindow(false)}
              >
                {t("CANCEL")}
              </Button>

              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                  marginTop: 10,
                }}
              >
                {t("SEND")}
              </Button>
            </Grid>
          </form>
          <MessagePopup
            state={message}
            setState={setMessage}
            message={message}
          />
          <LoadingDataComponent alreadyRendered={!dataSubmitted} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
