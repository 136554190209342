import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Rating,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Draggable from "react-draggable";

import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, alpha } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  statusChanged,
  selectUserSettings,
  selectGroupSettings,
  selectTenantsForAdvancedList,
  tenantsForAdvancedListEdited,
  tenantsEdited,
  fetchTenants,
  fetchTenantsForAdvancedList
} from "../../../slicers/userDataSlice";

import {
  snackbarMessageChanged,
  householdClicked,
} from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import RefreshIcon from "@mui/icons-material/Refresh";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import MergeIcon from '@mui/icons-material/Merge';
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FolderZipIcon from "@mui/icons-material/FolderZip";

import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ClearIcon from "@mui/icons-material/Clear";

import {
  DataGrid,
  enUS,
  frFR,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridToolbarContainer,
  nlNL,
  useGridApiContext,
} from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Point } from "../../../utils/Point";

import { useTranslation } from "react-i18next";
import SendInvitationToCandidate from "./SendInvitationToCandidate";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  minWidth: "800px",
  marginRight: 2,
};

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#f45a2a",
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "#f45a2a",
        },
      },
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "32ch",
  },
}));

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function Toolbar({
  setSearchValue,
  archiveView,
  setArchiveView,
  setDeleteClicked,
  setMergeClicked,
  bulkDownloadButtonEnabled,
  downloadBulkReport,
  MoveRecords,
  mergeRecords,
  selectionModel,
  nRows,
}) {
  const { t } = useTranslation();
  const groupSettings = useSelector(selectGroupSettings);
  const dispatch = useDispatch();
  const [oldRecordsDialogOpen, setOldRecordsDialogOpen] = useState(false);
  const [loadingAllRecords, setLoadingAllRecords] = useState(false);

  function refreshTable() {
    dispatch(statusChanged({ userSettings: "idle" }));
    dispatch(statusChanged({ tenants: "idle" }));
    dispatch(statusChanged({ tenantsForAdvancedList: "idle" }));
  }

  const LoadOldRecordsDialog = () => {
    function loadAllTenants() {
      setLoadingAllRecords(true);
      const payload = {
        group_id: groupSettings.group_id,
        getAll: true,
      };

      axios
        .post(
          configData.backendURL + "/fetch_tenants_for_advanced_list",
          payload
        )
        .then((response) => {
          dispatch(tenantsForAdvancedListEdited(JSON.parse(response.data)));
          setLoadingAllRecords(false);
        });

      axios
        .post(configData.backendURL + "/fetch_tenants", payload)
        .then((response) => dispatch(tenantsEdited(JSON.parse(response.data))));
      setOldRecordsDialogOpen(false);
    }

    return (
      <Dialog open={oldRecordsDialogOpen}>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to load all your records?")}
            <br />
            <br />
          </DialogContentText>
          <Grid container justifyContent="space-around">
            <Button
              variant="outlined"
              style={{ width: "47%" }}
              onClick={() => setOldRecordsDialogOpen(false)}
            >
              {t("NO")}
            </Button>
            <Button
              variant="contained"
              style={{ width: "47%" }}
              color="primary"
              onClick={loadAllTenants}
            >
              {t("YES")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const InformantExportMenuItem = (props) => {
    const apiRef = useGridApiContext();
    const handleExport = () => {
      apiRef.current.exportDataAsCsv({
        fields: [
          "Bedrijf",
          "Sorteercode",
          "tenant1_ID full name",
          "Naam1_Predikaat",
          "tenant1_ID gender",
          "tenant1_ID first name initials",
          "Naam1_Tussenvoegsel",
          "tenant1_ID last name",
          "tenant1_ID first name",
          "tenant1_ID Date of Birth",
          "tenant1_ID Place of Birth",
          "Naam1_BSN",
          "tenant1_Nationality",
          "Naam1_Identificatie",
          "Naam1_Id_nummer",
          "Naam1_Burgerlijke staat",
          "tenant2_ID full name",
          "Naam2_Predikaat",
          "tenant2_ID gender",
          "tenant2_ID first name initials",
          "Naam2_Tussenvoegsel",
          "tenant2_ID last name",
          "tenant2_ID first name",
          "tenant2_ID Date of Birth",
          "tenant2_ID Place of Birth",
          "Naam2_BSN",
          "tenant2_Nationality",
          "Naam2_Identificatie",
          "Naam2_Id_nummer",
          "tenant2_Employer",
          "Naam2_Burgerlijke staat",
          "Property address",
          "Property number",
          "Postadres_huisnummer_toevoeging",
          "Post code",
          "Property city",
          "Country",
          "Vestigingsadres",
          "Vestigingsadres_huisnummer",
          "Vestingingsadres_huisnummer_toevoeging",
          "Vestigingsadres_postcode",
          "Vestigingsadres_plaats",
          "Vestigingsadres_Land",
          "Hoofd_telefoonnummer",
          "Telefoonnummer_2",
          "Hoofd_faxnummer",
          "Faxnummer_2",
          "tenant1_Mobile phone",
          "tenant2_Mobile phone",
          "tenant1_Email",
          "Homepage",
          "relatiesoort",
          "Relatiesoort_ingangsdatum",
          "Kvknr",
          "Kvk_plaats",
          "BTW-nr",
          "Rechtsvorm",
          "Werknemers",
          "Betaaltermijn",
          "Rekeningnummer_TNV",
          "Rekeningnummer_plaats",
          "Rekeningnummer_BIC",
          "IBAN list",
          "Facturen toegestaan",
          "Automatische betaling",
          "Salderen betalingen",
          "Eigenaar",
          "Huurder",
          "Opdrachten toegestaan",
          "Voorkeur grootboekrekening",
        ],
        fileName: "Properize_Informant_" + t("Tenants"),
      });
    };

    return (
      <MenuItem onClick={handleExport}>{t("Export for Informant")}</MenuItem>
    );
  };

  const GridToolbarExport = () => (
    <GridToolbarExportContainer
      sx={{
        marginRight: 3,
        fontSize: 14,
        fontFamily: "roboto",
        fontWeight: "bold",
      }}
    >
      <GridCsvExportMenuItem
        options={{
          fields: [
            "Created",
            "Property location",
            "tenant1_Full name",
            "Status",
            "Rating",
          ],
          fileName: "Properize_" + t("Tenants"),
        }}
      />
      <InformantExportMenuItem />
    </GridToolbarExportContainer>
  );

  return (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer
        style={{
          minHeight: "45px",
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t("Search") + "…"}
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                </Search>
              </Grid>
              <Grid item>
                <GridToolbarExport />
              </Grid>

              {selectionModel.length === 0 && (
                <>
                  <Grid item>
                    <IconButton
                      onClick={refreshTable}
                      style={{ borderRadius: 0 }}
                    >
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </Grid>
                  {nRows === 100 && (
                    <Grid item>
                      <IconButton
                        onClick={() => setOldRecordsDialogOpen(true)}
                        style={{ borderRadius: 0 }}
                      >
                        {loadingAllRecords && <CircularProgress size={20} />}
                        {!loadingAllRecords && (
                          <CloudDownloadIcon color="primary" />
                        )}
                      </IconButton>
                      <LoadOldRecordsDialog />
                    </Grid>
                  )}
                </>
              )}
              {selectionModel.length >= 2 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={() => setMergeClicked(true)}
                    style={{ borderRadius: 0 }}
                  >
                    <MergeIcon color="primary" />
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("MERGE")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
              {!archiveView && selectionModel.length !== 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={() => MoveRecords("archive")}
                    style={{ borderRadius: 0 }}
                  >
                    <ArchiveIcon color="primary" />
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("ARCHIVE")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
              {archiveView && selectionModel.length !== 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={() => MoveRecords("")}
                    style={{ borderRadius: 0 }}
                  >
                    <HowToRegIcon color="primary" />
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("ONGOING")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
              {selectionModel.length !== 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={() => setDeleteClicked(true)}
                    style={{ borderRadius: 0 }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                    <Typography
                      sx={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("REMOVE")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
              {selectionModel.length !== 0 && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    onClick={downloadBulkReport}
                    style={{ borderRadius: 0 }}
                    disabled={!bulkDownloadButtonEnabled}
                  >
                    <FolderZipIcon
                      color={bulkDownloadButtonEnabled ? "primary" : "inherit"}
                    />
                    <Typography
                      color={bulkDownloadButtonEnabled ? "primary" : "inherit"}
                      sx={{
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 1,
                        display: { xl: "block", xs: "none" },
                      }}
                    >
                      {t("DOWNLOAD REPORT(S)")}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginRight: 5,
            }}
          >
            <Grid container direction="row" alignItems="center">
              <Typography
                color="primary"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                {t("ONGOING")}
              </Typography>
              <Switch
                checked={archiveView}
                onChange={() => setArchiveView(!archiveView)}
              />
              <Typography
                color="primary"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                {t("ARCHIVED")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    </ThemeProvider>
  );
}

const AdvancedTenantList = () => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [mergeClicked, setMergeClicked] = useState(false);

  const [archiveView, setArchiveView] = useState(false);

  const [bulkDownloadButtonEnabled, setBulkDownloadButtonEnabled] =
    useState(true);

  const [showNoRowsMessage, setShowNoRowsMessage] = useState(false);

  const [addNewRequestWindow, setAddNewRequestWindow] = useState(false);

  const { t } = useTranslation();

  const nodeRef = useRef(null);

  const dispatch = useDispatch();
  const tenantsForAdvancedList = useSelector(selectTenantsForAdvancedList);

  const userSettings = useSelector(selectUserSettings);
  const groupSettings = useSelector(selectGroupSettings);

  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));

  let localeText;
  if (userSettings.language === "nl") {
    localeText = nlNL;
  } else {
    localeText = enUS;
  }

  function useClickObserver(callback) {
    const [dragStartPos, setDragStartPos] = useState(new Point());
    const onStart = (_, data) => {
      setDragStartPos(new Point(data.x, data.y));
    };
    const onStop = (_, data) => {
      const dragStopPoint = new Point(data.x, data.y);
      if (Point.dist(dragStartPos, dragStopPoint) < 5) {
        callback();
      }
    };
    return { onStart, onStop };
  }

  useEffect(() => {
    setShowNoRowsMessage(tenantsForAdvancedList.length === 0 && !archiveView);
    let currentView;
    if (archiveView) {
      currentView = 1;
    } else {
      currentView = 0;
    }
    setRows(
      tenantsForAdvancedList.filter((t) => t["ArchiveRemove"] === currentView)
    );
  }, [tenantsForAdvancedList, dispatch, archiveView]);

  async function downloadBulkReport() {
    const selectedIDs = new Set(selectionModel);
    const rowsSelected = rows.filter((x) => selectedIDs.has(x.id));

    let reportLinksArray = rowsSelected.map((row) => row.pdf_report);
    reportLinksArray = removeItemAll(reportLinksArray, "");
    const reportLinks = reportLinksArray.join(", ");

    setBulkDownloadButtonEnabled(false);
    const payload = {
      urls_string: reportLinks,
      zip_filename: t("Report"),
    };
    const response = await axios.post(
      configData.backendURL + "/download_documents",
      payload,
      { responseType: "blob" }
    );

    if (response.data.size === 0) return setBulkDownloadButtonEnabled(true);

    setTimeout(() => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", response.headers["x-filename"]); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    setBulkDownloadButtonEnabled(true);
  }

  async function MoveRecords(action) {
    setDeleteClicked(false);
    const selectedIDs = new Set(selectionModel);
    setRows((r) => r.filter((x) => !selectedIDs.has(x.id)));

    let archiveRemove = 0;

    let payload = {
      parent_request_array: selectionModel,
      delete: false,
      archive: false,
    };

    if (action === "archive") {
      payload.archive = true;
      archiveRemove = 1;
      dispatch(
        snackbarMessageChanged(
          selectionModel.length + t("records moved to archive")
        )
      );
    } else if (action === "delete") {
      payload.delete = true;
      archiveRemove = 2;
      dispatch(
        snackbarMessageChanged(selectionModel.length + t("records deleted"))
      );
    } else {
      dispatch(
        snackbarMessageChanged(
          selectionModel.length + t("records moved to ongoing")
        )
      );
    }

    await axios.post(configData.backendURL + "/move_records2", payload);

    dispatch(
      tenantsForAdvancedListEdited(
        tenantsForAdvancedList.map((t) =>
          selectionModel.includes(t["id"])
            ? { ...t, ArchiveRemove: archiveRemove }
            : t
        )
      )
    );
  }

  async function mergeRecords(action) {
    const promises = [];

    setMergeClicked(false);
    const selectedIDs = new Set(selectionModel);
    const [parentRequest0] = selectedIDs

    // Collect household data from first tenant
    const baseTenant = rows.find(x => x.id === parentRequest0)

    for (let parentRequest of selectedIDs) {
      const payload = {
        parentRequest: parentRequest,
        "Parent request": baseTenant["id"],
        "Property address": baseTenant["Property address"],
        "Property number": baseTenant["Property number"],
        "Property address line 2": baseTenant["Property address line 2"],
        "Property city": baseTenant["Property city"],
        "Post code": baseTenant["Post code"],
        "Property price (EUR)": baseTenant["Property price (EUR)"],
        "Number of children": baseTenant["Number of children"],
        "Pets": baseTenant["Pets"],
        "Tenant report": null,
        Rating: null,
        rating_changed_by_agent: baseTenant["rating_changed_by_agent"],
        "Properize staff comments": null
      };

      promises.push(axios.post(configData.backendURL + "/update_airtable_row", payload));
    }

    Promise.all(promises)
      .then(() => {
        dispatch(fetchTenantsForAdvancedList(userSettings.group_id));
        dispatch(fetchTenants(userSettings.group_id));
        dispatch(
          snackbarMessageChanged(t("Dossiers merged successfully"))
        );
      })
  }

  const [searchValue, setSearchValue] = useState(null);

  const DeleteDialog = () => {
    return (
      <Dialog open={deleteClicked}>
        <DialogContent>
          <DialogContentText>
            {t("You are removing") +
              selectionModel.length +
              t("Do you want to continue")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setDeleteClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => MoveRecords("delete")}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const MergeDialog = () => {
    return (
      <Dialog open={mergeClicked}>
        <DialogContent>
          <DialogContentText>
            {t("You are merging") +
              selectionModel.length +
              t("Do you want to continue")}
            <br />
            <br />
            <Grid container justifyContent="space-around">
              <Button
                variant="outlined"
                style={{ width: "47%" }}
                onClick={() => setMergeClicked(false)}
              >
                {t("NO")}
              </Button>
              <Button
                variant="contained"
                style={{ width: "47%" }}
                color="primary"
                onClick={() => mergeRecords("delete")}
              >
                {t("YES")}
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const RenderPDFButton = (params) => {
    const [disabledButton, setDisabledButton] = useState(false);

    const onDownload = async () => {
      setDisabledButton(true);
      const payload = {
        url: params.row.pdf_report,
      };
      const response = await axios.post(
        configData.backendURL + "/create_presigned_url_api",
        payload
      );

      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = response.data;
      link.click();
      setDisabledButton(false);
    };
    return (
      <strong>
        {params.row.pdf_report.length > 1 && (
          <IconButton onClick={onDownload} disabled={disabledButton}>
            <PictureAsPdfIcon />
          </IconButton>
        )}
      </strong>
    );
  };
  const RenderDetailsButton = (params) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClick = () => {
      dispatch(
        householdClicked({ parentRequest: params.row.id, tenantDetail: false })
      );
      navigate("/dossier_summary?par_request=" + params.row.id);
    };
    return (
      <Button variant="contained" onClick={onClick} sx={{ width: "90%" }}>
        {t("VIEW")}
      </Button>
    );
  };

  const renderAddress = (params) => {
    let property_number = params.row["Property number"];

    if (property_number === "X") {
      property_number = "";
    } else if (params.row["Property address line 2"]) {
      property_number =
        " " + property_number + " " + params.row["Property address line 2"];
    } else {
      property_number = " " + property_number;
    }

    const property_location =
      params.row["Property address"] +
      property_number +
      ", " +
      params.row["Post code"] +
      " " +
      params.row["Property city"];

    const n_tenants = params.row["n_tenants"] - 1;
    const nameTenant = params.row["tenant1_Full name"];

    return (
      <div>
        <strong>{property_location}</strong>
        <br />
        {nameTenant} {n_tenants > 0 && t("and") + n_tenants + t("more")}
      </div>
    );
  };

  const renderStatus = (params) => {
    let status = params.row["Status"];
    let variant = "outlined";
    let color, Icon;

    if (params.row["tenant1_Contract"]) {
      const contract = JSON.parse(params.row["tenant1_Contract"]);
      const envelopeStatus = contract["envelope_status"];

      if (envelopeStatus === "In progress") {
        variant = "filled";
        status = "Signing";
        color = "#0f52ba";
        Icon = MailOutlineIcon;
      } else if (envelopeStatus === "Completed") {
        variant = "filled";
        status = "Signed";
        color = "green";
        Icon = DoneIcon;
      }
    }

    if (status === "Completed") {
      color = "green";
      Icon = DoneIcon;
    } else if (status === "Processing") {
      color = "#e47200";
      Icon = RestartAltIcon;
    } else if (status === "In progress") {
      color = "blue";
      Icon = AccessTimeIcon;
    } else if (status === "Failed") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<Icon />}
          disabled
          style={{
            color: variant === "outlined" ? color : "white",
            borderRadius: 50,
            height: "2em",
            borderColor: color,
            backgroundColor: variant === "filled" ? color : "white",
          }}
        >
          {t(status.toUpperCase())}
        </Button>
      </div>
    );
  };

  const renderRating = (params) => {
    const rating = params.row["Rating"];

    return <div>{rating > 0 && <Rating readOnly value={rating} />}</div>;
  };

  const renderCreated = (params) => {
    const created = params.row["Created"];

    return (
      <div>
        {created.slice(8, 10)}-{created.slice(5, 7)}-{created.slice(0, 4)}{" "}
        {created.slice(11, 16)}
      </div>
    );
  };

  const columnsBig = [
    { field: "id", headerName: "parent_id", hide: true },
    {
      field: "pdf_report",
      headerName: "pdf_report",
      hide: true,
    },
    {
      field: "Created",
      headerName: t("Created"),
      minWidth: 150,
      flex: true,
      disableColumnMenu: true,
      type: "date",
      renderCell: renderCreated,
    },
    {
      field: "Address",
      headerName: t("Dossier"),
      minWidth: 350,
      flex: true,
      renderCell: renderAddress,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      valueGetter: (params) => {
        let property_number = params.row["Property number"];

        if (property_number === "X") {
          property_number = "";
        } else if (params.row["Property address line 2"]) {
          property_number =
            " " + property_number + " " + params.row["Property address line 2"];
        } else {
          property_number = " " + property_number;
        }

        const property_location =
          params.row["Property address"] +
          property_number +
          ", " +
          params.row["Post code"] +
          " " +
          params.row["Property city"];

        const nameTenant = params.row["tenant1_Full name"];

        return property_location + " " + nameTenant;
      },
    },
    {
      field: "Property location",
      headerName: "Address",
      flex: true,
      hide: true,
    },
    { field: "Bedrijf", headerName: "Bedrijf", hide: true },
    { field: "Sorteercode", headerName: "Sorteercode", hide: true },
    {
      field: "tenant1_Full name",
      headerName: "Name",
      flex: true,
      hide: true,
    },
    { field: "tenant1_ID full name", headerName: "Naam 1", hide: true },
    { field: "Naam1_Predikaat", headerName: "Naam1_Predikaat", hide: true },
    { field: "tenant1_ID gender", headerName: "Naam1_Geslacht", hide: true },
    {
      field: "tenant1_ID first name initials",
      headerName: "Naam1_Voorletters",
      hide: true,
    },
    {
      field: "Naam1_Tussenvoegsel",
      headerName: "Naam1_Tussenvoegsel",
      hide: true,
    },
    {
      field: "tenant1_ID last name",
      headerName: "Naam1_Achternaam",
      hide: true,
    },
    {
      field: "tenant1_ID first name",
      headerName: "Naam1_Voornaam",
      hide: true,
    },
    {
      field: "tenant1_ID Date of Birth",
      headerName: "Naam1_Geboortedatum",
      hide: true,
    },
    {
      field: "tenant1_ID Place of Birth",
      headerName: "Naam1_Geboorteplaats",
      hide: true,
    },
    { field: "Naam1_BSN", headerName: "Naam1_BSN", hide: true },
    {
      field: "tenant1_Nationality",
      headerName: "Naam1_Nationaliteit",
      hide: true,
    },
    {
      field: "Naam1_Identificatie",
      headerName: "Naam1_Identificatie",
      hide: true,
    },
    { field: "Naam1_Id_nummer", headerName: "Naam1_Id_nummer", hide: true },
    { field: "tenant1_Employer", headerName: "Naam1_Beroep", hide: true },
    {
      field: "Naam1_Burgerlijke staat",
      headerName: "Naam1_Burgerlijke staat",
      hide: true,
    },
    { field: "tenant2_ID full name", headerName: "Naam 2", hide: true },
    { field: "Naam2_Predikaat", headerName: "Naam2_Predikaat", hide: true },
    { field: "tenant2_ID gender", headerName: "Naam2_Geslacht", hide: true },
    {
      field: "tenant2_ID first name initials",
      headerName: "Naam2_Voorletters",
      hide: true,
    },
    {
      field: "Naam2_Tussenvoegsel",
      headerName: "Naam2_Tussenvoegsel",
      hide: true,
    },
    {
      field: "tenant2_ID last name",
      headerName: "Naam2_Achternaam",
      hide: true,
    },
    {
      field: "tenant2_ID first name",
      headerName: "Naam2_Voornaam",
      hide: true,
    },
    {
      field: "tenant2_ID Date of Birth",
      headerName: "Naam2_Geboortedatum",
      hide: true,
    },
    {
      field: "tenant2_ID Place of Birth",
      headerName: "Naam2_Geboorteplaats",
      hide: true,
    },
    { field: "Naam2_BSN", headerName: "Naam2_BSN", hide: true },
    {
      field: "tenant2_Nationality",
      headerName: "Naam2_Nationaliteit",
      hide: true,
    },
    {
      field: "Naam2_Identificatie",
      headerName: "Naam2_Identificatie",
      hide: true,
    },
    { field: "Naam2_Id_nummer", headerName: "Naam2_Id_nummer", hide: true },
    { field: "tenant2_Employer", headerName: "Naam2_Beroep", hide: true },
    {
      field: "Naam2_Burgerlijke staat",
      headerName: "Naam2_Burgerlijke staat",
      hide: true,
    },
    { field: "Property address", headerName: "Postadres", hide: true },
    {
      field: "Property number",
      headerName: "Postadres_huisnummer",
      hide: true,
    },
    {
      field: "Postadres_huisnummer_toevoeging",
      headerName: "Postadres_huisnummer_toevoeging",
      hide: true,
    },
    { field: "Post code", headerName: "Postadres_postcode", hide: true },
    { field: "Property city", headerName: "Postadres_plaats", hide: true },
    { field: "Country", headerName: "Postadres_land", hide: true },
    { field: "Vestigingsadres", headerName: "Vestigingsadres", hide: true },
    {
      field: "Vestigingsadres_huisnummer",
      headerName: "Vestigingsadres_huisnummer",
      hide: true,
    },
    {
      field: "Vestingingsadres_huisnummer_toevoeging",
      headerName: "Vestingingsadres_huisnummer_toevoeging",
      hide: true,
    },
    {
      field: "Vestigingsadres_postcode",
      headerName: "Vestigingsadres_postcode",
      hide: true,
    },
    {
      field: "Vestigingsadres_plaats",
      headerName: "Vestigingsadres_plaats",
      hide: true,
    },
    {
      field: "Vestigingsadres_Land",
      headerName: "Vestigingsadres_Land",
      hide: true,
    },
    {
      field: "Hoofd_telefoonnummer",
      headerName: "Hoofd_telefoonnummer",
      hide: true,
    },
    { field: "Telefoonnummer_2", headerName: "Telefoonnummer_2", hide: true },
    { field: "Hoofd_faxnummer", headerName: "Hoofd_faxnummer", hide: true },
    { field: "Faxnummer_2", headerName: "Faxnummer_2", hide: true },
    {
      field: "tenant1_Mobile phone",
      headerName: "Hoofd_mobielnummer",
      hide: true,
    },
    { field: "tenant2_Mobile phone", headerName: "Mobielnummer_2", hide: true },
    { field: "tenant1_Email", headerName: "Hoofd_e-mailadres", hide: true },
    { field: "Homepage", headerName: "Homepage", hide: true },
    { field: "relatiesoort", headerName: "relatiesoort", hide: true },
    {
      field: "Relatiesoort_ingangsdatum",
      headerName: "Relatiesoort_ingangsdatum",
      hide: true,
    },
    { field: "Kvknr", headerName: "Kvknr", hide: true },
    { field: "Kvk_plaats", headerName: "Kvk_plaats", hide: true },
    { field: "BTW-nr", headerName: "BTW-nr", hide: true },
    { field: "Rechtsvorm", headerName: "Rechtsvorm", hide: true },
    { field: "Werknemers", headerName: "Werknemers", hide: true },
    { field: "Betaaltermijn", headerName: "Betaaltermijn", hide: true },
    {
      field: "Rekeningnummer_TNV",
      headerName: "Rekeningnummer_TNV",
      hide: true,
    },
    {
      field: "Rekeningnummer_plaats",
      headerName: "Rekeningnummer_plaats",
      hide: true,
    },
    {
      field: "Rekeningnummer_BIC",
      headerName: "Rekeningnummer_BIC",
      hide: true,
    },
    { field: "IBAN list", headerName: "Rekeningnummer_Iban", hide: true },
    {
      field: "Facturen toegestaan",
      headerName: "Facturen toegestaan",
      hide: true,
    },
    {
      field: "Automatische betaling",
      headerName: "Automatische betaling",
      hide: true,
    },
    {
      field: "Salderen betalingen",
      headerName: "Salderen betalingen",
      hide: true,
    },
    { field: "Eigenaar", headerName: "Eigenaar", hide: true },
    { field: "Huurder", headerName: "Huurder", hide: true },
    {
      field: "Opdrachten toegestaan",
      headerName: "Opdrachten toegestaan",
      hide: true,
    },
    {
      field: "Voorkeur grootboekrekening",
      headerName: "Voorkeur grootboekrekening",
      hide: true,
    },
    {
      field: "Voorkeur grootboekrekening",
      headerName: "Voorkeur grootboekrekening",
      hide: true,
    },
    {
      field: "Status",
      headerName: t("Status"),
      minWidth: 245,
      flex: true,
      disableColumnMenu: true,
      renderCell: renderStatus,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return t(params.row["Status"]);
      },
    },
    {
      field: "Rating",
      headerName: t("Rating"),
      minWidth: 150,
      //maxWidth: 250,
      flex: true,
      disableColumnMenu: true,
      renderCell: renderRating,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return params.row["Rating"];
      },
    },
    {
      field: "PDF",
      headerName: t("Report"),
      minWidth: 70,
      maxWidth: 170,
      flex: true,
      renderCell: RenderPDFButton,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: "Details",
      headerName: "",
      minWidth: 100,
      maxWidth: 200,
      flex: true,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "ConcatAllColumns",
      headerName: "ConcatAllColumns",
      hide: true,
      valueGetter: (params) => {
        const rating = params.row["Rating"];
        const status = t(params.row["Status"]);
        const address = params.row["Property location"];
        const nameTenant1 = params.row["tenant1_Full name"];
        const nameTenant2 = params.row["tenant2_Full name"];
        const created =
          params.row["Created"].slice(8, 10) +
          "-" +
          params.row["Created"].slice(5, 7) +
          "-" +
          params.row["Created"].slice(0, 4) +
          " " +
          params.row["Created"].slice(11);

        return (
          rating +
          " " +
          status +
          " " +
          address +
          " " +
          nameTenant1 +
          " " +
          nameTenant2 +
          " " +
          created
        );
      },
    },
  ];

  const columnsSmall = [
    { field: "id", headerName: "parent_id", hide: true },
    {
      field: "pdf_report",
      headerName: "pdf_report",
      hide: true,
    },
    {
      field: "Details",
      headerName: "",
      minWidth: 100,
      maxWidth: 200,
      flex: true,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Created",
      headerName: t("Created"),
      minWidth: 150,
      flex: true,
      disableColumnMenu: true,
      type: "date",
      renderCell: renderCreated,
    },
    {
      field: "Address",
      headerName: t("Dossier"),
      minWidth: 350,
      flex: true,
      renderCell: renderAddress,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      valueGetter: (params) => {
        const property_location = params.row["Property location"];
        const nameTenant = params.row["tenant1_Full name"];

        return property_location + " " + nameTenant;
      },
    },
    {
      field: "Property location",
      headerName: "Address",
      flex: true,
      hide: true,
    },
    { field: "Bedrijf", headerName: "Bedrijf", hide: true },
    { field: "Sorteercode", headerName: "Sorteercode", hide: true },
    {
      field: "tenant1_Full name",
      headerName: "Name",
      flex: true,
      hide: true,
    },
    { field: "tenant1_ID full name", headerName: "Naam 1", hide: true },
    { field: "Naam1_Predikaat", headerName: "Naam1_Predikaat", hide: true },
    { field: "tenant1_ID gender", headerName: "Naam1_Geslacht", hide: true },
    {
      field: "tenant1_ID first name initials",
      headerName: "Naam1_Voorletters",
      hide: true,
    },
    {
      field: "Naam1_Tussenvoegsel",
      headerName: "Naam1_Tussenvoegsel",
      hide: true,
    },
    {
      field: "tenant1_ID last name",
      headerName: "Naam1_Achternaam",
      hide: true,
    },
    {
      field: "tenant1_ID first name",
      headerName: "Naam1_Voornaam",
      hide: true,
    },
    {
      field: "tenant1_ID Date of Birth",
      headerName: "Naam1_Geboortedatum",
      hide: true,
    },
    {
      field: "tenant1_ID Place of Birth",
      headerName: "Naam1_Geboorteplaats",
      hide: true,
    },
    { field: "Naam1_BSN", headerName: "Naam1_BSN", hide: true },
    {
      field: "tenant1_Nationality",
      headerName: "Naam1_Nationaliteit",
      hide: true,
    },
    {
      field: "Naam1_Identificatie",
      headerName: "Naam1_Identificatie",
      hide: true,
    },
    { field: "Naam1_Id_nummer", headerName: "Naam1_Id_nummer", hide: true },
    { field: "tenant1_Employer", headerName: "Naam1_Beroep", hide: true },
    {
      field: "Naam1_Burgerlijke staat",
      headerName: "Naam1_Burgerlijke staat",
      hide: true,
    },
    { field: "tenant2_ID full name", headerName: "Naam 2", hide: true },
    { field: "Naam2_Predikaat", headerName: "Naam2_Predikaat", hide: true },
    { field: "tenant2_ID gender", headerName: "Naam2_Geslacht", hide: true },
    {
      field: "tenant2_ID first name initials",
      headerName: "Naam2_Voorletters",
      hide: true,
    },
    {
      field: "Naam2_Tussenvoegsel",
      headerName: "Naam2_Tussenvoegsel",
      hide: true,
    },
    {
      field: "tenant2_ID last name",
      headerName: "Naam2_Achternaam",
      hide: true,
    },
    {
      field: "tenant2_ID first name",
      headerName: "Naam2_Voornaam",
      hide: true,
    },
    {
      field: "tenant2_ID Date of Birth",
      headerName: "Naam2_Geboortedatum",
      hide: true,
    },
    {
      field: "tenant2_ID Place of Birth",
      headerName: "Naam2_Geboorteplaats",
      hide: true,
    },
    { field: "Naam2_BSN", headerName: "Naam2_BSN", hide: true },
    {
      field: "tenant2_Nationality",
      headerName: "Naam2_Nationaliteit",
      hide: true,
    },
    {
      field: "Naam2_Identificatie",
      headerName: "Naam2_Identificatie",
      hide: true,
    },
    { field: "Naam2_Id_nummer", headerName: "Naam2_Id_nummer", hide: true },
    { field: "tenant2_Employer", headerName: "Naam2_Beroep", hide: true },
    {
      field: "Naam2_Burgerlijke staat",
      headerName: "Naam2_Burgerlijke staat",
      hide: true,
    },
    { field: "Property address", headerName: "Postadres", hide: true },
    {
      field: "Property number",
      headerName: "Postadres_huisnummer",
      hide: true,
    },
    {
      field: "Postadres_huisnummer_toevoeging",
      headerName: "Postadres_huisnummer_toevoeging",
      hide: true,
    },
    { field: "Post code", headerName: "Postadres_postcode", hide: true },
    { field: "Property city", headerName: "Postadres_plaats", hide: true },
    { field: "Country", headerName: "Postadres_land", hide: true },
    { field: "Vestigingsadres", headerName: "Vestigingsadres", hide: true },
    {
      field: "Vestigingsadres_huisnummer",
      headerName: "Vestigingsadres_huisnummer",
      hide: true,
    },
    {
      field: "Vestingingsadres_huisnummer_toevoeging",
      headerName: "Vestingingsadres_huisnummer_toevoeging",
      hide: true,
    },
    {
      field: "Vestigingsadres_postcode",
      headerName: "Vestigingsadres_postcode",
      hide: true,
    },
    {
      field: "Vestigingsadres_plaats",
      headerName: "Vestigingsadres_plaats",
      hide: true,
    },
    {
      field: "Vestigingsadres_Land",
      headerName: "Vestigingsadres_Land",
      hide: true,
    },
    {
      field: "Hoofd_telefoonnummer",
      headerName: "Hoofd_telefoonnummer",
      hide: true,
    },
    { field: "Telefoonnummer_2", headerName: "Telefoonnummer_2", hide: true },
    { field: "Hoofd_faxnummer", headerName: "Hoofd_faxnummer", hide: true },
    { field: "Faxnummer_2", headerName: "Faxnummer_2", hide: true },
    {
      field: "tenant1_Mobile phone",
      headerName: "Hoofd_mobielnummer",
      hide: true,
    },
    { field: "tenant2_Mobile phone", headerName: "Mobielnummer_2", hide: true },
    { field: "tenant1_Email", headerName: "Hoofd_e-mailadres", hide: true },
    { field: "Homepage", headerName: "Homepage", hide: true },
    { field: "relatiesoort", headerName: "relatiesoort", hide: true },
    {
      field: "Relatiesoort_ingangsdatum",
      headerName: "Relatiesoort_ingangsdatum",
      hide: true,
    },
    { field: "Kvknr", headerName: "Kvknr", hide: true },
    { field: "Kvk_plaats", headerName: "Kvk_plaats", hide: true },
    { field: "BTW-nr", headerName: "BTW-nr", hide: true },
    { field: "Rechtsvorm", headerName: "Rechtsvorm", hide: true },
    { field: "Werknemers", headerName: "Werknemers", hide: true },
    { field: "Betaaltermijn", headerName: "Betaaltermijn", hide: true },
    {
      field: "Rekeningnummer_TNV",
      headerName: "Rekeningnummer_TNV",
      hide: true,
    },
    {
      field: "Rekeningnummer_plaats",
      headerName: "Rekeningnummer_plaats",
      hide: true,
    },
    {
      field: "Rekeningnummer_BIC",
      headerName: "Rekeningnummer_BIC",
      hide: true,
    },
    { field: "IBAN list", headerName: "Rekeningnummer_Iban", hide: true },
    {
      field: "Facturen toegestaan",
      headerName: "Facturen toegestaan",
      hide: true,
    },
    {
      field: "Automatische betaling",
      headerName: "Automatische betaling",
      hide: true,
    },
    {
      field: "Salderen betalingen",
      headerName: "Salderen betalingen",
      hide: true,
    },
    { field: "Eigenaar", headerName: "Eigenaar", hide: true },
    { field: "Huurder", headerName: "Huurder", hide: true },
    {
      field: "Opdrachten toegestaan",
      headerName: "Opdrachten toegestaan",
      hide: true,
    },
    {
      field: "Voorkeur grootboekrekening",
      headerName: "Voorkeur grootboekrekening",
      hide: true,
    },
    {
      field: "Voorkeur grootboekrekening",
      headerName: "Voorkeur grootboekrekening",
      hide: true,
    },
    {
      field: "Status",
      headerName: t("Status"),
      minWidth: 245,
      flex: true,
      disableColumnMenu: true,
      renderCell: renderStatus,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return t(params.row["Status"]);
      },
    },
    {
      field: "Rating",
      headerName: t("Rating"),
      minWidth: 150,
      //maxWidth: 250,
      flex: true,
      disableColumnMenu: true,
      renderCell: renderRating,
      disableClickEventBubbling: true,
      valueGetter: (params) => {
        return params.row["Rating"];
      },
    },
    {
      field: "PDF",
      headerName: t("Report"),
      minWidth: 70,
      maxWidth: 170,
      flex: true,
      renderCell: RenderPDFButton,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: "ConcatAllColumns",
      headerName: "ConcatAllColumns",
      hide: true,
      valueGetter: (params) => {
        const rating = params.row["Rating"];
        const status = t(params.row["Status"]);
        const address = params.row["Property location"];
        const nameTenant1 = params.row["tenant1_Full name"];
        const nameTenant2 = params.row["tenant2_Full name"];
        const created =
          params.row["Created"].slice(8, 10) +
          "-" +
          params.row["Created"].slice(5, 7) +
          "-" +
          params.row["Created"].slice(0, 4) +
          " " +
          params.row["Created"].slice(11);

        return (
          rating +
          " " +
          status +
          " " +
          address +
          " " +
          nameTenant1 +
          " " +
          nameTenant2 +
          " " +
          created
        );
      },
    },
  ];

  let columns = columnsBig;

  if (!isXlUp) {
    columns = columnsSmall;
  }

  function CustomNoRowsOverlay() {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {showNoRowsMessage && t("No Rows")}
      </Grid>
    );
  }

  return (
    <div>
      <Typography
        color="secondary"
        sx={{ marginLeft: 2, marginBottom: 1, fontSize: 24 }}
      >
        {t("Tenant Overview")}
      </Typography>
      <Card sx={cardStyle}>
        <Grid container style={{ height: "78vh", width: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              localeText={
                localeText.components.MuiDataGrid.defaultProps.localeText
              }
              components={{
                Toolbar: Toolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                toolbar: {
                  setSearchValue: setSearchValue,
                  archiveView: archiveView,
                  setArchiveView: setArchiveView,
                  setDeleteClicked: setDeleteClicked,
                  setMergeClicked: setMergeClicked,
                  bulkDownloadButtonEnabled: bulkDownloadButtonEnabled,
                  downloadBulkReport: downloadBulkReport,
                  MoveRecords: MoveRecords,
                  mergeRecords: mergeRecords,
                  selectionModel: selectionModel,
                  nRows: tenantsForAdvancedList.length,
                },
              }}
              filterModel={{
                items: [
                  {
                    columnField: "ConcatAllColumns",
                    operatorValue: "contains",
                    value: searchValue,
                  },
                ],
              }}
              rows={rows}
              columns={columns}
              autoPageSize
              pagination
              disableColumnSelector
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              selectionModel={selectionModel}
            />
          </ThemeProvider>
          <Draggable
            nodeRef={nodeRef}
            {...useClickObserver(() => setAddNewRequestWindow(true))}
          >
            <Grid
              ref={nodeRef}
              style={{
                position: "absolute",
                bottom: "15%",
                right: "3%",
                zIndex: "999",
              }}
            >
              <Fab aria-label="add" color="secondary">
                <AddIcon />
              </Fab>
            </Grid>
          </Draggable>
        </Grid>
      </Card>
      <DeleteDialog />
      <MergeDialog />
      <SendInvitationToCandidate
        addNewRequestWindow={addNewRequestWindow}
        setAddNewRequestWindow={setAddNewRequestWindow}
      />

    </div>
  );
};

export default AdvancedTenantList;
