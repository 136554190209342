import React from "react";

import { IconButton } from "@mui/material";

import { snackbarMessageChanged } from "../slicers/globalStateVariablesSlice";
import { useDispatch } from "react-redux";

import CopyAllIcon from "@mui/icons-material/CopyAll";

import { useTranslation } from "react-i18next";

const CopyToClipboard = ({ message }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <IconButton
      size="small"
      sx={{ marginLeft: 1 }}
      onClick={() => {
        navigator.clipboard.writeText(message);
        dispatch(snackbarMessageChanged(t("Copied to clipboard")));
      }}
    >
      <CopyAllIcon fontSize="small" />
    </IconButton>
  );
};

export default CopyToClipboard;
