import React, { useState, useRef } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { createTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import CopyToClipboard from "../../../components/CopyToClipboard";

import thirtyRegeling from "../../../logos/30-percent.png";

import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";

import redTrafficLight from "../../../logos/traffic-red-150px.png";
import yellowTrafficLight from "../../../logos/traffic-yellow-150px.png";
import greenTrafficLight from "../../../logos/traffic-green-150px.png";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";
import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LaunchIcon from "@mui/icons-material/Launch";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

import { useSelector, useDispatch } from "react-redux";

import {
  fetchTenants,
  fetchTenantsForAdvancedList,
  selectTenants,
  selectUserSettings,
  tenantsEdited,
} from "../../../slicers/userDataSlice";

import {
  householdClicked,
  selectHouseholdActive,
} from "../../../slicers/globalStateVariablesSlice";

import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import configData from "../../../utils/config.json";

import { Tooltip } from "@mui/material";
import TooltipHelp from "../../../components/TooltipHelp";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import DropzoneReact from "../../../components/DropzoneReact";

import {
  uploadFilesToS3Directly,
  uploadPictureToS3Directly,
} from "../../../utils/uploadToS3";
import { useEffect } from "react";
import EditableRow, { countries } from "../../../components/EditableRow";
import EditableField from "../../../components/EditableField";

const accordionStyle = {
  padding: 0.5,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginY: 1,
};

const accordionNoCompletedStyle = {
  padding: 2.5,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginY: 1,
};

const breadcumbcardStyle = {
  padding: 1,
  px: 3,
  bgcolor: "#ffffff",
  borderRadius: 10,
  boxShadow: 3,
  marginBottom: 1,
};

const minicardStyle = {
  p: 2,
  backgroundColor: "#f5f5f5",
  borderRadius: 2,
  boxShadow: 3,
  marginBottom: 1,
  width: "100%",
  minHeight: "120px",
};

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function decimalNotationInCommas(x) {
  return (Math.round((x + Number.EPSILON) * 100) / 100)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function calculateAge(birthday) {
  var ageDifMs = Date.now() - new Date(birthday);
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return String(Math.abs(ageDate.getUTCFullYear() - 1970));
}

function monthDiffFromToday(dateString) {
  const date = new Date(dateString);
  let months;
  months = (date.getFullYear() - new Date().getFullYear()) * 12;
  months -= new Date().getMonth() + 1;
  months += date.getMonth() + 1;
  months += date.getDate() > new Date().getDate() ? 1 : 0;

  if (months === 0 && date - new Date() > 0) return 1;
  return months < 0 ? 0 : months;
}

function isPastDate(dateString) {
  return new Date(dateString) < new Date();
}

const AccordionNoBorder = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: null,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const RenderStatus = ({ status }) => {
  const { t } = useTranslation();
  let color, Icon;

  if (status === "Completed") {
    color = "green";
    Icon = DoneIcon;
  } else if (status === "Processing") {
    color = "#e47200";
    Icon = RestartAltIcon;
  } else if (status === "In progress") {
    color = "blue";
    Icon = AccessTimeIcon;
  } else if (status === "Failed") {
    color = "red";
    Icon = ClearIcon;
  }

  return (
    <div>
      <Button
        variant="outlined"
        disabled
        style={{
          color: color,
          borderRadius: 50,
          borderColor: color,
        }}
        sx={{ display: { md: "inline-block", xs: "none" } }}
      >
        <Grid container spacing={1}>
          <Grid item>{<Icon fontSize="small" />}</Grid>
          <Grid item>{t(status)}</Grid>
        </Grid>
      </Button>
      <Button
        variant="outlined"
        disabled
        style={{
          color: color,
          borderRadius: 50,
          borderColor: color,
        }}
        sx={{ display: { md: "none", xs: "block" } }}
      >
        {<Icon fontSize="large" />}
      </Button>
    </div>
  );
};

const TenantDetail = () => {
  const { t } = useTranslation();

  const [idExpanded, setIdExpanded] = useState(true);
  const [incomeExpanded, setIncomeExpanded] = useState(true);
  const [creditExpanded, setCreditExpanded] = useState(true);
  const [documentsExpanded, setDocumentsExpanded] = useState(true);

  const [showUploadDocsDialog, setShowUploadDocsDialog] = useState(false);
  const [documentType, setDocumentType] = useState(false);
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);

  const [edrDialogOpen, setEdrDialogOpen] = useState(false);

  const tenantsDetails = useSelector(selectTenants);
  const userSettings = useSelector(selectUserSettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const properize_id = searchParams.get("properize_id");
  const par_request = searchParams.get("par_request");

  const theme = createTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const householdActive = useSelector(selectHouseholdActive);
  const parentRequest = householdActive.parentRequest || par_request;
  const properizeIdTenantSelected =
    householdActive.tenantDetail || properize_id;

  const tenant = tenantsDetails.find(
    (row) => row["properize_id"] === properizeIdTenantSelected
  );

  useEffect(() => {
    if (parentRequest && properizeIdTenantSelected) {
      dispatch(
        householdClicked({
          parentRequest: par_request,
          tenantDetail: properize_id,
        })
      );

      if (tenant !== undefined) {
        if (tenant["ID card"]) {
          axios
            .post(configData.backendURL + "/create_presigned_url_api", {
              url: tenant["ID card"],
            })
            .then((response) => {
              setFrontPhoto(response.data);
            });
        }
        if (tenant["ID card back"]) {
          axios
            .post(configData.backendURL + "/create_presigned_url_api", {
              url: tenant["ID card back"],
            })
            .then((response) => {
              setBackPhoto(response.data);
            });
        }
      }
    }
  }, []);

  if (
    !parentRequest ||
    !properizeIdTenantSelected ||
    tenantsDetails.length === 0
  ) {
    return navigate("/");
  }

  if (tenant === undefined) return navigate("/");

  let other_documents_array = [tenant["XXX Other Document"]].concat([
    tenant["Miscellaneous link"],
  ]);

  other_documents_array = removeItemAll(other_documents_array, "");
  const other_documents = other_documents_array.join(", ");

  let all_documents_array = [tenant["Bank_Statement_Link"]]
    .concat([tenant["Document_Payslips_link"]])
    .concat([tenant["Document_Employer_links"]])
    .concat([tenant["XXX Other Document"]])
    .concat([tenant["Miscellaneous link"]])
    .concat([tenant["Extra link"]])
    .concat([tenant["Supporting documents (Lite)"]]);

  all_documents_array = removeItemAll(all_documents_array, "");
  const all_documents = all_documents_array.join(", ");

  const currentCountry = ["Netherlands", ""].includes(tenant["Current country"])
    ? ""
    : " (" + tenant["Current country"] + ")";

  const wholeAddress =
    tenant["Current address"] +
    " " +
    tenant["Current number"] +
    ", " +
    tenant["Current post code"] +
    " " +
    tenant["Current city"] +
    currentCountry;

  const breadcrumbsCard = (
    <Grid justifyContent="flex-start" style={{ maxWidth: "1200px" }}>
      <Card sx={breadcumbcardStyle}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            startIcon={<HomeIcon sx={{ fontWeight: "bold" }} />}
            variant="text"
            onClick={() => navigate("/")}
          >
            <Typography sx={{ fontSize: 16 }}>
              {t("Tenant Overview")}
            </Typography>
          </Button>
          <Button
            variant="text"
            onClick={() =>
              navigate("/dossier_summary?par_request=" + parentRequest)
            }
          >
            <Typography sx={{ fontSize: 16 }}>
              {t("Dossier Summary")}: {tenant["Property location"]}
            </Typography>
          </Button>

          <Typography sx={{ fontSize: 16 }}>
            {t("Candidate tenant summary")}
          </Typography>
        </Breadcrumbs>
      </Card>
    </Grid>
  );

  const ExpandAllButton = () => {
    let areAllExpanded =
      idExpanded && incomeExpanded && creditExpanded && documentsExpanded;

    if (
      tenant["CheckA_Status"] !== "Completed" &&
      tenant["CheckB_Status"] !== "Completed" &&
      tenant["CheckC_Status"] !== "Completed"
    ) {
      areAllExpanded = documentsExpanded;
    } else if (
      tenant["CheckA_Status"] !== "Completed" &&
      tenant["CheckB_Status"] !== "Completed"
    ) {
      areAllExpanded = creditExpanded && documentsExpanded;
    } else if (
      tenant["CheckB_Status"] !== "Completed" &&
      tenant["CheckC_Status"] !== "Completed"
    ) {
      areAllExpanded = idExpanded && documentsExpanded;
    } else if (
      tenant["CheckA_Status"] !== "Completed" &&
      tenant["CheckC_Status"] !== "Completed"
    ) {
      areAllExpanded = incomeExpanded && documentsExpanded;
    } else if (tenant["CheckA_Status"] !== "Completed") {
      areAllExpanded = incomeExpanded && creditExpanded && documentsExpanded;
    } else if (tenant["CheckB_Status"] !== "Completed") {
      areAllExpanded = idExpanded && creditExpanded && documentsExpanded;
    } else if (tenant["CheckC_Status"] !== "Completed") {
      areAllExpanded = idExpanded && incomeExpanded && documentsExpanded;
    }

    function handleExpandAll() {
      // Expand all accordions. If all are already expanded, collapse all.
      setIdExpanded(!areAllExpanded);
      setIncomeExpanded(!areAllExpanded);
      setCreditExpanded(!areAllExpanded);
      setDocumentsExpanded(!areAllExpanded);
    }
    return (
      <Button
        startIcon={areAllExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        color="primary"
        variant="text"
        onClick={handleExpandAll}
      >
        <Typography sx={{ fontSize: 16, padding: 0.5 }}>
          {!areAllExpanded && t("EXPAND ALL")}
          {areAllExpanded && t("COLLAPSE ALL")}
        </Typography>
      </Button>
    );
  };

  const regionNames = new Intl.DisplayNames([userSettings.language], {
    type: "region",
  });

  const DocumentButton = ({ name, urls_string }) => {
    const [disabledButton, setDisabledButton] = useState(false);

    const onDownload = async (urls_string) => {
      setDisabledButton(true);
      const payload = {
        urls_string: urls_string,
        zip_filename: tenant["properize_id"] + "-all",
      };
      const response = await axios.post(
        configData.backendURL + "/download_documents",
        payload,
        { responseType: "blob" }
      );

      setTimeout(() => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", response.headers["x-filename"]); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });

      setDisabledButton(false);
    };

    if (!urls_string) {
      return null;
    }

    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => onDownload(urls_string)}
        disabled={disabledButton}
      >
        <Typography sx={{ fontSize: 16 }}>{t(name)}</Typography>
      </Button>
    );
  };

  const personalDataCard = (
    <Grid
      style={{
        maxWidth: "1200px",
        width: "100%",
        paddingBottom: tenant["30% ruling"] === "Yes" ? "20px" : "40px",
      }}
      sx={accordionNoCompletedStyle}
    >
      <Typography p={1} sx={{ fontSize: 22, fontWeight: "bold" }}>
        {tenant["Full name"]}
      </Typography>
      <Grid container direction="row" spacing={4} px={2}>
        <Grid item xs={12} lg={6}>
          <TableContainer sx={{ padding: 0.5, marginTop: 1, marginBottom: 2 }}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  border: "none",
                  padding: 0.5,
                },
                [`& .${tableRowClasses.root}`]: {
                  height: "40px",
                },
                marginTop: 1,
              }}
            >
              <TableBody>
                <EditableRow
                  properize_id={properize_id}
                  feature="Email"
                  featureLabel={t("Email")}
                  defaultValue={tenant["Email"]}
                  defaultTextValue={
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      wrap="nowrap"
                    >
                      <Grid item>{tenant["Email"]}</Grid>
                      <Grid item>
                        <Link
                          href={"mailto:" + tenant["Email"]}
                          color="inherit"
                          target="_blank"
                        >
                          <EmailIcon color="primary" />
                        </Link>
                      </Grid>

                      {tenant["email_bounced"] && (
                        <Grid item>
                          <TooltipHelp
                            json_flag={t("email_bounce_tooltip")}
                            Icon={ErrorIcon}
                            color="red"
                          />
                        </Grid>
                      )}
                    </Grid>
                  }
                />

                <EditableRow
                  properize_id={properize_id}
                  feature="Mobile phone"
                  featureLabel={t("Mobile Phone")}
                  defaultValue={tenant["Mobile phone"]}
                  defaultTextValue={
                    tenant["Mobile phone"] ? (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        wrap="nowrap"
                      >
                        <Grid item>{tenant["Mobile phone"]}</Grid>
                        <Grid item>
                          <Link
                            href={"tel:" + tenant["Mobile phone"]}
                            color="inherit"
                            target="_blank"
                          >
                            <CallIcon color="primary" />
                          </Link>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )
                  }
                />

                <EditableRow
                  properize_id={properize_id}
                  feature="ID Date of Birth"
                  featureLabel={t("Date of Birth")}
                  defaultValue={tenant["ID Date of Birth"]}
                  defaultTextValue={
                    tenant["ID Date of Birth"]
                      ? new Date(
                        tenant["ID Date of Birth"]
                      ).toLocaleDateString() +
                      " (" +
                      calculateAge(tenant["ID Date of Birth"]) +
                      " " +
                      t("years old") +
                      ")"
                      : ""
                  }
                  type="date"
                />

                <EditableRow
                  properize_id={properize_id}
                  feature="Nationality"
                  featureLabel={t("Nationality")}
                  defaultValue={tenant["Nationality"]}
                  defaultTextValue={
                    countries.find((c) => c.code === tenant["Nationality"]) ? (
                      <Grid
                        container
                        alignItems="center"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      >
                        <>
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${tenant[
                              "Nationality"
                            ].toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${tenant[
                              "Nationality"
                            ].toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {regionNames.of(tenant["Nationality"])}
                        </>
                      </Grid>
                    ) : (
                      ""
                    )
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={4} alignItems="center">
            {tenant["30% ruling"] && tenant["30% ruling"] === "Yes" && (
              <Grid item>
                <Tooltip
                  enterTouchDelay={0}
                  title={t("30 tax ruling")}
                  placement="bottom"
                >
                  <img src={thirtyRegeling} alt="thirtyRegeling" height="50" />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container alignItems="center" spacing={1} my={1.5}>
            <Grid item>
              <Typography sx={{ fontSize: 14 }}>{t("Biography")}</Typography>
            </Grid>
            {false && (
              <Grid item sx={{ display: { sm: "table-cell", xs: "none" } }}>
                <CopyToClipboard message={tenant["Bio"]} />
              </Grid>
            )}
          </Grid>

          <EditableField
            properize_id={properize_id}
            feature="Bio"
            defaultValue={tenant["Bio"]}
            defaultTextValue={
              tenant["Bio"] ? (
                <Typography sx={{ fontSize: 12, fontStyle: "italic" }}>
                  "{tenant["Bio"].length <= 1200 && tenant["Bio"]}
                  {tenant["Bio"].length > 1200 &&
                    tenant["Bio"].slice(0, 1200) + " (...)"}
                  "
                </Typography>
              ) : (
                ""
              )
            }
          />

          {tenant["Miscellaneous text"] && (
            <>
              <Grid container alignItems="center" spacing={1} mt={3} mb={1}>
                <Grid item>
                  <Typography sx={{ fontSize: 14 }}>
                    {t("Miscellaneous information")}
                  </Typography>
                </Grid>
                {false && (
                  <Grid item sx={{ display: { sm: "table-cell", xs: "none" } }}>
                    <CopyToClipboard message={tenant["Miscellaneous text"]} />
                  </Grid>
                )}
              </Grid>

              <EditableField
                properize_id={properize_id}
                feature="Miscellaneous text"
                defaultValue={tenant["Miscellaneous text"]}
                defaultTextValue={
                  <Typography sx={{ fontSize: 12, fontStyle: "italic" }}>
                    "
                    {tenant["Miscellaneous text"].length <= 1200 &&
                      tenant["Miscellaneous text"]}
                    {tenant["Miscellaneous text"].length > 1200 &&
                      tenant["Miscellaneous text"].slice(0, 1200) + " (...)"}
                    "
                  </Typography>
                }
              />
            </>
          )}

          {tenant["Account Type"] === "Lite" && (
            <Grid item mt={4}>
              <DocumentButton
                name={t("DOWNLOAD ALL")}
                urls_string={all_documents}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const personalDataNoCompletedCard = (
    <Grid
      style={{ maxWidth: "1200px", width: "100%" }}
      sx={accordionNoCompletedStyle}
    >
      <Typography p={1} sx={{ fontSize: 22, fontWeight: "bold" }}>
        {tenant["Full name"]}
      </Typography>
      <Grid item xs={12} lg={6}>
        <TableContainer sx={{ padding: 0.5, marginTop: 1, marginBottom: 2 }}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                border: "none",
                padding: 0.5,
              },
              [`& .${tableRowClasses.root}`]: {
                height: "40px",
              },
              marginTop: 1,
            }}
          >
            <TableBody>
              <EditableRow
                properize_id={properize_id}
                feature="Email"
                featureLabel={t("Email")}
                defaultValue={tenant["Email"]}
                defaultTextValue={
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    wrap="nowrap"
                  >
                    <Grid item>{tenant["Email"]}</Grid>
                    <Grid item>
                      <Link
                        href={"mailto:" + tenant["Email"]}
                        color="inherit"
                        target="_blank"
                      >
                        <EmailIcon color="primary" />
                      </Link>
                    </Grid>

                    {tenant["email_bounced"] && (
                      <Grid item>
                        <TooltipHelp
                          json_flag={t("email_bounce_tooltip")}
                          Icon={ErrorIcon}
                          color="red"
                        />
                      </Grid>
                    )}
                  </Grid>
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );

  const SummaryCard = () => {
    const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);

    const VerifyIncomeDialog = () => {
      const [disableVerifyButton, setDisableVerifyButton] = useState(false);

      const [typeOfVerification, setTypeOfVerification] = useState(tenant['type_of_verification']);
      const trueIncomeRef = useRef();
      const employerRef = useRef();

      const verificationTypes = [
        "Documents",
        "Submitted bank statement",
        "No income reported",
      ].map((incomeSource) => (
        <MenuItem key={incomeSource} value={incomeSource}>
          {t(incomeSource)}
        </MenuItem>
      ));

      async function handleVerify(event) {
        event.preventDefault();

        setDisableVerifyButton(true);

        let payload = {
          properize_id: properize_id,
          "type_of_verification": typeOfVerification,
          "True income median": Number(trueIncomeRef.current.value),
          "income_verified_by_agent": true,
        };

        if (["Work",
          "Recently Changed Jobs",
          "Changing Jobs"].includes(tenant['WhatToDo']))
          payload["Employer"] = employerRef.current.value;

        await axios.post(configData.backendURL + "/update_airtable_row", payload);

        const response = await axios.post(
          configData.backendURL + "/calculate_automatic_rating",
          {
            parent_request: parentRequest,
          }
        );

        dispatch(
          tenantsEdited(
            tenantsDetails.map((t) =>
              t["Parent request"] === parentRequest ?
                t["properize_id"] === properize_id
                  ? {
                    ...t,
                    "type_of_verification": typeOfVerification,
                    "True income median": Number(trueIncomeRef.current.value),
                    "income_verified_by_agent": true,
                    Rating: Number(response.data),
                    rating_changed_by_agent: null,
                    "Employer": ["Work",
                      "Recently Changed Jobs",
                      "Changing Jobs"].includes(tenant['WhatToDo']) ? employerRef.current.value : t['Employer']
                  }
                  : {
                    ...t,
                    Rating: Number(response.data),
                    rating_changed_by_agent: null,
                  } : t
            )
          )
        );

        setDisableVerifyButton(false);
        setVerifyDialogOpen(false);
        navigate("/dossier_summary?par_request=" + parentRequest)
      }

      return (
        <Dialog open={verifyDialogOpen} onClose={() => setVerifyDialogOpen(false)}>
          <DialogTitle>
            <Grid p={1} align="center">
              {t("Verify the income manually")}
            </Grid>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleVerify} style={{ maxWidth: "400px" }}>
              <Grid
                container
                direction="column"
                alignItems="center"
                wrap="nowrap"
                spacing={2}
              >
                <Grid item align="center">
                  <Typography sx={{ fontSize: 14 }}>
                    {t("Verify the income manually paragraph")}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={2} my={2}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Type of verification")}
                        size="small"
                        value={typeOfVerification}
                        onChange={(event) => setTypeOfVerification(event.target.value)}
                        variant="outlined"
                        fullWidth
                        select
                      >
                        {verificationTypes}
                      </TextField>

                    </Grid>
                    {["Work",
                      "Recently Changed Jobs",
                      "Changing Jobs"].includes(tenant['WhatToDo']) && <Grid item xs={12}>
                        <TextField
                          label={t("Employer")}
                          size="small"
                          variant="outlined"
                          defaultValue={tenant["Employer"]}
                          inputRef={employerRef}
                          fullWidth
                        />
                      </Grid>}
                    {tenant['WhatToDo'] !== "No income" && <Grid item xs={12}>
                      <TextField
                        label={t("Verified income")}
                        type="number"
                        size="small"
                        defaultValue={tenant["True income median"]}
                        inputRef={trueIncomeRef}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>}

                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="column" wrap="nowrap" spacing={1} mt={2}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      borderRadius: 16,
                      fontSize: "18px",
                    }}
                    fullWidth
                    onClick={() => setVerifyDialogOpen(false)}
                  >
                    {t("BACK")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    style={{
                      borderRadius: 16,
                      fontSize: "18px",
                    }}
                    fullWidth
                    disabled={disableVerifyButton}
                  >
                    {t("RE-CALCULATE AUTOMATIC SCORE")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      );
    };

    const noIncomeItem = (
      <Grid item>
        <Typography variant="h6">{t("No income reported")}</Typography>
      </Grid>
    );

    const incomeFoundItem = (
      <>
        <Grid item>
          <Typography variant="body1">
            {t("Personal gross income of")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            {"€ " +
              decimalNotationInCommas(tenant["True income median"]) +
              " / " +
              t("month")}
          </Typography>
        </Grid>
      </>
    );

    const incomeNotFoundItem = (
      <>
        <Grid item>
          <Typography variant="body1">
            {t("Personal gross income of")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            {"€ " +
              decimalNotationInCommas(tenant["Selfreported gross income"]) +
              " / " +
              t("month")}
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="body2">
            {t("Self-reported income. Not verified by Properize")}
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Button
            variant="contained"
            style={{
              margin: 10,
              borderRadius: 4,
              maxWidth: "250px",
            }}
            onClick={() => setVerifyDialogOpen(true)}
            fullWidth
          >
            {t("VERIFY MANUALLY")}
          </Button>
        </Grid>
        <VerifyIncomeDialog />
      </>
    );

    const incomeItem = tenant["True income median"] || tenant['income_verified_by_agent']
      ? incomeFoundItem
      : tenant["Selfreported gross income"]
        ? incomeNotFoundItem
        : noIncomeItem;

    const fraudWarnings = tenant["fraud_warnings"]
      ? JSON.parse(tenant["fraud_warnings"])
      : [];

    return (
      <Grid
        style={{ maxWidth: "1200px", width: "100%" }}
        sx={accordionNoCompletedStyle}
      >
        <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          {t("Summary")}
        </Typography>
        <Grid
          container
          justifyContent="space-evenly"
          p={isSmUp ? 2 : 1}
          spacing={isSmUp ? 5 : 2}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              sx={minicardStyle}
              style={{
                padding: "25px",
                backgroundColor:
                  tenant["Selfreported gross income"] === 0 && tenant["True income median"] === 0
                    ? "#f5f5f5"
                    : !tenant["True income median"] &&
                      tenant["Selfreported gross income"] && !tenant['income_verified_by_agent']
                      ? "#fff4e5"
                      : "#edf7ed",
              }}
            >
              <Grid
                item
                xs={1}
                sx={{ display: { sm: "table-cell", xs: "none" } }}
              >
                {!tenant["True income median"] &&
                  tenant["Selfreported gross income"] && !tenant['income_verified_by_agent'] ? (
                  <WarningIcon style={{ color: "orange", fontSize: 60 }} />
                ) : tenant["Selfreported gross income"] === 0 ? null : (
                  <CheckCircleIcon style={{ color: "green", fontSize: 60 }} />
                )}
              </Grid>
              <Grid item xs={12} sm={11}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
                >
                  {incomeItem}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              wrap="nowrap"
              sx={minicardStyle}
              style={{
                padding: ["High", "Medium"].includes(tenant["Fraud indicator"])
                  ? "15px"
                  : "25px",
                backgroundColor:
                  tenant["Fraud indicator"] === "High"
                    ? "#FDEDED"
                    : tenant["Fraud indicator"] === "Medium"
                      ? "#fff4e5"
                      : "#edf7ed",
              }}
            >
              <Grid item>
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid
                    item
                    xs={1}
                    sx={{ display: { sm: "table-cell", xs: "none" } }}
                  >
                    {tenant["Fraud indicator"] === "High" ? (
                      <CancelIcon style={{ color: "red", fontSize: 60 }} />
                    ) : tenant["Fraud indicator"] === "Medium" ? (
                      <WarningIcon style={{ color: "orange", fontSize: 60 }} />
                    ) : (
                      <CheckCircleIcon
                        style={{ color: "green", fontSize: 60 }}
                      />
                    )}
                  </Grid>

                  <Grid item align="center" xs={12} sm={11}>
                    <Typography
                      variant="h6"
                      style={{
                        color:
                          tenant["Fraud indicator"] === "High"
                            ? "#5F2120"
                            : null,
                      }}
                    >
                      {tenant["Fraud indicator"] === "High"
                        ? t("High risk of fraud")
                        : tenant["Fraud indicator"] === "Medium"
                          ? fraudWarnings.length + " " + t("fraud warnings")
                          : t("No fraud warnings")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {["High", "Medium"].includes(tenant["Fraud indicator"]) && (
                <Grid item>
                  <ul>
                    {fraudWarnings.map((warning) => (
                      <li
                        style={{
                          color:
                            tenant["Fraud indicator"] === "High"
                              ? "#5F2120"
                              : null,
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            color:
                              tenant["Fraud indicator"] === "High"
                                ? "#5F2120"
                                : null,
                          }}
                        >
                          {warning.includes("Bank statement was created with")
                            ? t("Bank statement was created with") +
                            warning.split("Bank statement was created with")[1]
                            : warning.includes(
                              "Bank statement shows signs of digital manipulation with"
                            )
                              ? t(
                                "Bank statement shows signs of digital manipulation with"
                              ) +
                              warning.split(
                                "Bank statement shows signs of digital manipulation with"
                              )[1]
                              : t(warning)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  function dismissLowFuzzyMatching() {
    axios.post(configData.backendURL + "/update_airtable_row", {
      properize_id: properize_id,
      CheckA_Detail: tenant["CheckA_Detail"].replace(
        "Low fuzzy matching score in name.",
        "Low fuzzy matching (dismissed by agent)"
      ),
    });
    dispatch(
      tenantsEdited(
        tenantsDetails.map((t) =>
          t["properize_id"] === properize_id
            ? {
              ...t,
              CheckA_Detail: tenant["CheckA_Detail"].replace(
                "Low fuzzy matching score in name.",
                "Low fuzzy matching (dismissed by agent)"
              ),
            }
            : t
        )
      )
    );
  }

  async function overWriteFront(event) {
    await uploadPictureToS3Directly(
      properize_id,
      "front-cropped",
      event.target.files[0]
    );

    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-front-cropped.jpg";
    const response = await axios.post(
      configData.backendURL + "/create_presigned_url_api",
      {
        url: photoURL,
      }
    );

    setFrontPhoto(response.data);

    const payload = {
      properize_id: tenant["properize_id"],
      "ID card": photoURL,
    };
    axios.post(configData.backendURL + "/update_airtable_row", payload);
  }

  async function downloadOriginalFront() {
    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-front.jpg";
    const response = await axios.post(
      configData.backendURL + "/create_presigned_url_api",
      {
        url: photoURL,
      }
    );
    window.open(response.data, "_blank");
  }

  async function overWriteBack(event) {
    await uploadPictureToS3Directly(
      properize_id,
      "back-cropped",
      event.target.files[0]
    );

    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-back-cropped.jpg";
    const response = await axios.post(
      configData.backendURL + "/create_presigned_url_api",
      {
        url: photoURL,
      }
    );

    setBackPhoto(response.data);

    const payload = {
      properize_id: tenant["properize_id"],
      "ID card back": photoURL,
    };
    axios.post(configData.backendURL + "/update_airtable_row", payload);
  }

  async function downloadOriginalBack() {
    const photoURL =
      "https://properize-id-cards.s3.eu-central-1.amazonaws.com/" +
      properize_id +
      "-back.jpg";
    const response = await axios.post(
      configData.backendURL + "/create_presigned_url_api",
      {
        url: photoURL,
      }
    );
    window.open(response.data, "_blank");
  }

  const idVerificationCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }} sx={accordionStyle}>
      <AccordionNoBorder
        expanded={idExpanded}
        onChange={() => setIdExpanded(!idExpanded)}
        key={"personalDataCard"}
      >
        <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
          {" "}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                {t("ID verification")}
              </Typography>
            </Grid>
            <Grid item align="center">
              <Grid container direction="column" wrap="nowrap">
                <RenderStatus status={tenant["CheckA_Status"]} />
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    display: { sm: "block", xs: "none" },
                  }}
                >
                  {tenant["CheckA_Completed"]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container p={isSmUp ? 2 : 0} direction="row" spacing={5}>
            <Grid item xs={12} lg={6}>
              {tenant["CheckA_Detail"].includes("Low fuzzy matching score") && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  <Grid
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <AlertTitle>{t("Please Read")}</AlertTitle>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={dismissLowFuzzyMatching}
                      >
                        {t("DISMISS")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography variant="caption">
                    {t("Low fuzzy matching score 1") +
                      ' "' +
                      tenant["First name"] +
                      " " +
                      tenant["Last name"] +
                      '" ' +
                      t("Low fuzzy matching score 2")}
                  </Typography>
                  <Grid mt={1}>
                    <Typography variant="caption">
                      {t("Low fuzzy matching score 3")}
                    </Typography>
                  </Grid>
                </Alert>
              )}

              {monthDiffFromToday(tenant["date_of_expiry"]) > 0 &&
                monthDiffFromToday(tenant["date_of_expiry"]) <= 3 &&
                tenant["document_type"] === "Residence Permit" && (
                  <Alert severity="warning" sx={{ marginBottom: 2 }}>
                    <AlertTitle>{t("Please Read")}</AlertTitle>
                    <Typography variant="caption">
                      {t("Residence permit expiring soon")}
                    </Typography>
                  </Alert>
                )}

              <TableContainer sx={{ padding: 0.5 }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      border: "none",
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    maxWidth: 600,
                  }}
                >
                  <TableBody>
                    <EditableRow
                      properize_id={properize_id}
                      feature="document_type"
                      featureLabel={t("Document type")}
                      defaultValue={tenant["document_type"]}
                      defaultTextValue={t(tenant["document_type"])}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="Document Number"
                      featureLabel={t("Document number")}
                      defaultValue={tenant["Document Number"]}
                      defaultTextValue={tenant["Document Number"]}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="ID first name"
                      featureLabel={t("First Name")}
                      defaultValue={tenant["ID first name"]}
                      defaultTextValue={tenant["ID first name"]}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="ID last name"
                      featureLabel={t("Last Name")}
                      defaultValue={tenant["ID last name"]}
                      defaultTextValue={tenant["ID last name"]}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="ID Place of Birth"
                      featureLabel={t("Place of Birth")}
                      defaultValue={tenant["ID Place of Birth"]}
                      defaultTextValue={tenant["ID Place of Birth"]}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="issuing_authority"
                      featureLabel={t("Issuing authority")}
                      defaultValue={tenant["issuing_authority"]}
                      defaultTextValue={tenant["issuing_authority"]}
                    />

                    <EditableRow
                      properize_id={properize_id}
                      feature="date_of_expiry"
                      featureLabel={t("Expiry date")}
                      defaultValue={tenant["date_of_expiry"]}
                      defaultTextValue={
                        tenant["date_of_expiry"]
                          ? new Date(
                            tenant["date_of_expiry"]
                          ).toLocaleDateString() +
                          String(
                            monthDiffFromToday(tenant["date_of_expiry"]) > 6
                              ? " (> 6 " + t("months from now") + ")"
                              : monthDiffFromToday(tenant["date_of_expiry"]) >
                                0
                                ? " (< " +
                                monthDiffFromToday(tenant["date_of_expiry"]) +
                                " " +
                                t("months from now") +
                                ")"
                                : ""
                          )
                          : ""
                      }
                      type="date"
                    />
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container sx={{ ...minicardStyle, p: 3, mt: 3 }}>

                {!tenant["CheckA_Detail"].includes("The document uploaded is a fake or sample document") &&
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={2}>
                        <IconButton disabled>
                          <CheckCircleIcon style={{ color: "green" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>
                          {t("Information extracted is valid and consistent")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>}

                {tenant["CheckA_Detail"].includes("The document uploaded is a fake or sample document") && (
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={2}>
                        <IconButton disabled>
                          <CloseIcon style={{ color: "red" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{t("ID is fake or a sample")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {!isPastDate(tenant["date_of_expiry"]) && (
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={2}>
                        <IconButton disabled>
                          <CheckCircleIcon style={{ color: "green" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{t("Document is not expired")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {isPastDate(tenant["date_of_expiry"]) && (
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs={2}>
                        <IconButton disabled>
                          <CloseIcon style={{ color: "red" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{t("Document is expired")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs={2}>
                      <IconButton disabled>
                        <CheckCircleIcon style={{ color: "green" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        {t("No signs of digital manipulation")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {!tenant["CheckA_Detail"].includes(
                  "MRZ contains invalid check digit"
                ) && (
                    <Grid item xs={12}>
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid item xs={2}>
                          <IconButton disabled>
                            <CheckCircleIcon style={{ color: "green" }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>
                            {t("Control numbers are checked")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                {tenant["CheckA_Detail"].includes(
                  "MRZ contains invalid check digit"
                ) && (
                    <Grid item xs={12}>
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid item xs={2}>
                          <IconButton disabled>
                            <CloseIcon style={{ color: "red" }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>
                            {t("Control numbers don't match")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              {[
                {
                  photo: frontPhoto,
                  sideLabel: "Front of the document",
                  overWrite: overWriteFront,
                  downloadOriginal: downloadOriginalFront,
                },
                {
                  photo: backPhoto,
                  sideLabel: "Back of the document",
                  overWrite: overWriteBack,
                  downloadOriginal: downloadOriginalBack,
                },
              ].map(
                (photoObject) =>
                  (photoObject.photo ||
                    tenant["document_type"] !== "Passport") && (
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      alignItems="center"
                      mb={photoObject.photo === frontPhoto ? 7 : 0}
                      key={photoObject.sideLabel}
                    >
                      <Grid item>
                        <Typography variant="body2">
                          {t(photoObject.sideLabel)}:
                        </Typography>
                      </Grid>
                      <Grid item>
                        {photoObject.photo && (
                          <img
                            loading="lazy"
                            alt="photo_side"
                            src={photoObject.photo}
                            width={isSmUp ? "400" : "300"}
                          />
                        )}
                        {!photoObject.photo && (
                          <Grid
                            p={6}
                            m={1}
                            style={{
                              backgroundColor: "#ffc9bb",
                              maxWidth: "400px",
                            }}
                          >
                            <Typography variant="h6">
                              {t("Candidate did not provide back side")}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          sx={{
                            minWidth: "250px",
                            maxWidth: "450px",
                            marginTop: "10px",
                          }}
                        >
                          {t("UPLOAD NEW VERSION")}
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={photoObject.overWrite}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </AccordionNoBorder>
    </Grid>
  );

  const idVerificationNoCompletedCard = (
    <Grid
      style={{ maxWidth: "1200px", width: "100%" }}
      sx={accordionNoCompletedStyle}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          {t("ID verification")}
        </Typography>
        <RenderStatus status={tenant["CheckA_Status"]} />
      </Grid>
    </Grid>
  );

  const isThereIncomeWarnings =
    (!tenant["income_verified_by_agent"] &&
      (tenant["bank_account_type"].includes("Business") ||
        (tenant["income_source_where"] === "Abroad" && tenant["Income source"] !== "Open Banking") ||
        tenant["WhatToDo"] === "Savings" ||
        (tenant["multiple_sources_of_income"] &&
          tenant["True income median"] !== 0) ||
        tenant["joint_accounts"])) || (monthDiffFromToday(tenant["Contract end date"]) > 0 && monthDiffFromToday(tenant["Contract end date"]) <= 3);

  const employeeEditableRows = (
    <>
      <EditableRow
        properize_id={properize_id}
        feature="Employer"
        featureLabel={t("Employer")}
        defaultValue={tenant["Employer"]}
        defaultTextValue={tenant["Employer"]}
      />
      <EditableRow
        properize_id={properize_id}
        feature="contract_type"
        featureLabel={t("Type of contract")}
        defaultValue={tenant["contract_type"]}
        defaultTextValue={t(tenant["contract_type"])}
      />
      <EditableRow
        properize_id={properize_id}
        feature="start_date"
        featureLabel={t("Starting Date")}
        defaultValue={tenant["start_date"]}
        defaultTextValue={
          tenant["start_date"]
            ? new Date(tenant["start_date"]).toLocaleDateString()
            : "N/A"
        }
        type="date"
      />
      {tenant["contract_type"] !== "Indefinite" && (
        <EditableRow
          properize_id={properize_id}
          feature="Contract end date"
          featureLabel={t("Ending Date")}
          defaultValue={tenant["Contract end date"]}
          defaultTextValue={
            tenant["Contract end date"]
              ? new Date(tenant["Contract end date"]).toLocaleDateString() +
              String(
                monthDiffFromToday(tenant["Contract end date"]) > 6
                  ? " (> 6 " + t("months from now") + ")"
                  : monthDiffFromToday(tenant["Contract end date"]) > 0
                    ? " (< " +
                    monthDiffFromToday(tenant["Contract end date"]) +
                    " " +
                    t("months from now") +
                    ")"
                    : ""
              )
              : ""
          }
          type="date"
        />
      )}
    </>
  );

  const entrepreneurEditableRows = (
    <>
      <EditableRow
        properize_id={properize_id}
        feature="Employer"
        featureLabel={t("Business name")}
        defaultValue={tenant["Employer"]}
        defaultTextValue={tenant["Employer"]}
      />
      <EditableRow
        properize_id={properize_id}
        feature="kvk_number"
        featureLabel={t("KvK number")}
        defaultValue={tenant["kvk_number"]}
        defaultTextValue={tenant["kvk_number"] ? tenant["kvk_number"] : "N/A"}
      />
      <EditableRow
        properize_id={properize_id}
        feature="start_date"
        featureLabel={
          tenant["WhatToDo"] === "Entrepreneur"
            ? t("Incorporation date")
            : t("Starting Date work")
        }
        defaultValue={tenant["start_date"]}
        defaultTextValue={
          tenant["start_date"]
            ? new Date(tenant["start_date"]).toLocaleDateString()
            : ""
        }
        type="date"
      />

      {tenant["WhatToDo"] === "Self-employed" && (
        <EditableRow
          properize_id={properize_id}
          feature="self_employed_has_fixed_assignment"
          featureLabel={t("Fixed assignment")}
          defaultValue={tenant["self_employed_has_fixed_assignment"]}
          defaultTextValue={
            tenant["self_employed_has_fixed_assignment"] ? t("Yes") : t("No")
          }
        />
      )}
    </>
  );

  const incomeVerificationCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }} sx={accordionStyle}>
      <AccordionNoBorder
        expanded={incomeExpanded}
        onChange={() => setIncomeExpanded(!incomeExpanded)}
        key={"incomeVerificationCard"}
      >
        <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
          {" "}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                {t("Income verification")}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column" wrap="nowrap" align="center">
                <RenderStatus status={tenant["CheckB_Status"]} />
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    display: { sm: "block", xs: "none" },
                  }}
                >
                  {tenant["CheckB_Completed"]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={5}
            px={isSmUp ? 2 : 0}
          >
            <Grid item xs={12} lg={7}>
              <TableContainer sx={{ padding: 0.5 }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      border: "none",
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    marginTop: 1,
                    maxWidth: 600,
                  }}
                >
                  <colgroup>
                    <col style={{ width: "35%" }} />
                  </colgroup>
                  <TableBody>
                    <EditableRow
                      properize_id={properize_id}
                      feature="WhatToDo"
                      featureLabel={t("Type of candidate")}
                      defaultValue={tenant["WhatToDo"]}
                      defaultTextValue={
                        tenant["WhatToDo"]
                          ? t("whatToDo_" + tenant["WhatToDo"])
                          : ""
                      }
                    />
                    <EditableRow
                      properize_id={properize_id}
                      feature="Selfreported gross income"
                      featureLabel={t("Self-reported income")}
                      convertTo={Number}
                      defaultValue={tenant["Selfreported gross income"]}
                      defaultTextValue={
                        tenant["Selfreported gross income"]
                          ? "€ " +
                          decimalNotationInCommas(
                            tenant["Selfreported gross income"]
                          ) +
                          " / " +
                          t("month")
                          : t("No income reported")
                      }
                      type="number"
                    />
                    <EditableRow
                      properize_id={properize_id}
                      feature="True income median"
                      featureLabel={t("Verified income")}
                      convertTo={Number}
                      defaultValue={tenant["True income median"]}
                      defaultTextValue={
                        tenant["True income median"]
                          ? "€ " +
                          decimalNotationInCommas(
                            tenant["True income median"]
                          ) +
                          " / " +
                          t("month")
                          : "N/A"
                      }
                      type="number"
                    />
                    <EditableRow
                      properize_id={properize_id}
                      feature="type_of_verification"
                      featureLabel={t("Type of verification")}
                      defaultValue={tenant["type_of_verification"]}
                      defaultTextValue={t(tenant["type_of_verification"])}
                    />
                    <EditableRow
                      properize_id={properize_id}
                      feature="IBAN list"
                      featureLabel={t("IBAN number")}
                      defaultValue={tenant["IBAN list"]}
                      defaultTextValue={t(tenant["IBAN list"])}
                    />

                    {!isThereIncomeWarnings && (
                      <>
                        {[
                          "Work",
                          "Recently Changed Jobs",
                          "Changing Jobs",
                        ].includes(tenant["WhatToDo"]) && employeeEditableRows}

                        {["Entrepreneur", "Self-employed"].includes(
                          tenant["WhatToDo"]
                        ) && entrepreneurEditableRows}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {(tenant["source_of_wealth_explanation"] ||
              tenant["loan_explanation"]) && (
                <Grid item xs={12} lg={5} mt={2}>
                  <Typography sx={{ fontSize: 14 }}>
                    {t("Explanation provided by candidate")}
                  </Typography>

                  <Typography
                    sx={{ fontSize: 12, fontStyle: "italic", marginTop: 2 }}
                  >
                    "{tenant["source_of_wealth_explanation"]}
                    {tenant["loan_explanation"]}"
                  </Typography>
                </Grid>
              )}
          </Grid>
          {[
            {
              label: "Business bank account",
              severity: "error",
              condition:
                tenant["bank_account_type"].includes("Business") &&
                !tenant["income_verified_by_agent"],
            },
            {
              label: "Foreign income source",
              severity: "error",
              condition:
                tenant["income_source_where"] === "Abroad" && tenant["Income source"] !== "Open Banking" &&
                !tenant["income_verified_by_agent"],
            },
            {
              label: "Living off savings",
              severity: "warning",
              condition:
                tenant["WhatToDo"] === "Savings" &&
                !tenant["income_verified_by_agent"],
            },
            {
              label: "Multiple sources of income",
              severity: "info",
              condition:
                tenant["multiple_sources_of_income"] &&
                !tenant["income_verified_by_agent"] &&
                tenant["True income median"] !== 0,
            },
            {
              label: "Potential use of joint accounts",
              severity: "warning",
              condition:
                tenant["joint_accounts"] && !tenant["income_verified_by_agent"],
            },
            {
              label: "Job contract expires soon",
              severity: "warning",
              condition: monthDiffFromToday(tenant["Contract end date"]) > 0 && monthDiffFromToday(tenant["Contract end date"]) <= 3,
            },
          ].map(
            (warning) =>
              warning.condition && (
                <Grid m={1} key={warning.label}>
                  <Alert severity={warning.severity}>
                    <AlertTitle>{t(warning.label)}</AlertTitle>

                    <Typography variant="caption">
                      {warning.label !== "Living off savings" &&
                        t(warning.label + " paragraph")}
                      {warning.label === "Living off savings" &&
                        t(warning.label + " paragraph 1") +
                        decimalNotationInCommas(
                          tenant["liquid_assets_value"]
                        ) +
                        t(warning.label + " paragraph 2")}
                    </Typography>
                  </Alert>
                </Grid>
              )
          )}

          {isThereIncomeWarnings && (
            <Grid item xs={12} lg={7} mt={2} px={isSmUp ? 2 : 0}>
              <TableContainer sx={{ padding: 0.5, marginTop: 1 }}>
                {["Work", "Recently Changed Jobs", "Changing Jobs"].includes(
                  tenant["WhatToDo"]
                ) && (
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          border: "none",
                          padding: 0.5,
                        },
                        [`& .${tableRowClasses.root}`]: {
                          height: "40px",
                        },
                        maxWidth: 600,
                      }}
                    >
                      <colgroup>
                        <col style={{ width: "35%" }} />
                      </colgroup>
                      <TableBody>{employeeEditableRows}</TableBody>
                    </Table>
                  )}

                {["Entrepreneur", "Self-employed"].includes(
                  tenant["WhatToDo"]
                ) && (
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          border: "none",
                          padding: 0.5,
                        },
                        [`& .${tableRowClasses.root}`]: {
                          height: "40px",
                        },
                        maxWidth: 600,
                      }}
                    >
                      <TableBody>{entrepreneurEditableRows}</TableBody>
                    </Table>
                  )}
              </TableContainer>
            </Grid>
          )}

        </AccordionDetails>
      </AccordionNoBorder>
    </Grid>
  );

  const incomeVerificationNoCompletedCard = (
    <Grid
      style={{ maxWidth: "1200px", width: "100%" }}
      sx={accordionNoCompletedStyle}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          {t("Income verification")}
        </Typography>
        <RenderStatus status={tenant["CheckB_Status"]} />
      </Grid>
    </Grid>
  );

  const FixEDRDialog = () => {
    const [showBack, setShowBack] = useState(false);
    const [disableRetry, setDisableRetry] = useState(false);

    const [isExpat, setIsExpat] = useState(false);

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const birthDateRef = useRef();

    const streetRef = useRef();
    const housenumberRef = useRef();
    const postCodeRef = useRef();
    const cityRef = useRef();

    async function retryEDR(event) {
      event.preventDefault();

      setDisableRetry(true);

      let payload = {
        properize_id: properize_id,
        "ID first name": firstNameRef.current.value,
        "ID last name": lastNameRef.current.value,
        "ID Date of Birth": birthDateRef.current.value,
        "Current address": streetRef.current.value,
        "Current number": housenumberRef.current.value,
        "Current post code": postCodeRef.current.value,
        "Current city": cityRef.current.value,
        "Flow type": isExpat ? "Expat" : "Netherlands",
      };

      await axios.post(configData.backendURL + "/update_airtable_row", payload);
      await axios.post(configData.backendURL + "/launch_edr_manually", payload);

      payload = {
        id: properize_id,
        database: "Tenants",
      };

      const response = await axios.post(
        configData.backendURL + "/get_data_from_airtable_table",
        payload
      );

      dispatch(
        tenantsEdited(
          tenantsDetails.map((t) =>
            t["properize_id"] === properize_id
              ? {
                ...t,
                "ID first name": firstNameRef.current.value,
                "ID last name": lastNameRef.current.value,
                "ID Date of Birth": birthDateRef.current.value,
                "Current address": streetRef.current.value,
                "Current number": housenumberRef.current.value,
                "Current post code": postCodeRef.current.value,
                "Current city": cityRef.current.value,
                "EDR personal score": response.data["EDR personal score"],
                "EDR overall score": response.data["EDR overall score"],
                "EDR conclusion": response.data["EDR conclusion"],
                "EDR issues text": response.data["EDR issues text"],
                "Flow type": response.data["Flow type"],
                CheckC_Status: response.data["CheckC_Status"],
              }
              : t
          )
        )
      );

      setDisableRetry(false);
      setEdrDialogOpen(false);
    }

    return (
      <Dialog open={edrDialogOpen} onClose={() => setEdrDialogOpen(false)}>
        <DialogTitle>
          <Grid p={1} align="center">
            {t("Diagnose the EDR problem")}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={retryEDR}>
            <Grid
              container
              direction="column"
              alignItems="center"
              wrap="nowrap"
              spacing={2}
            >
              <Grid item>
                <Typography sx={{ fontSize: 14 }}>
                  {t("Please make sure that the below information is correct")}
                </Typography>
              </Grid>

              <Grid item>
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid item sx={{ display: backPhoto ? "grid" : "none" }}>
                    <IconButton
                      onClick={() => setShowBack(false)}
                      disabled={!showBack}
                      size={isSmUp ? "medium" : "small"}
                    >
                      <ArrowBackIosIcon
                        color={showBack ? "primary" : "disabled"}
                        fontSize={isSmUp ? "medium" : "small"}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <img
                      loading="lazy"
                      alt="photo_side"
                      src={showBack ? backPhoto : frontPhoto}
                      width={isSmUp ? "400" : "220"}
                    />
                  </Grid>
                  <Grid item sx={{ display: backPhoto ? "grid" : "none" }}>
                    <IconButton
                      onClick={() => setShowBack(true)}
                      disabled={showBack}
                      size={isSmUp ? "medium" : "small"}
                    >
                      <ArrowForwardIosSharpIcon
                        color={showBack ? "disabled" : "primary"}
                        fontSize={isSmUp ? "medium" : "small"}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t("First Name")}
                      size="small"
                      variant="outlined"
                      defaultValue={tenant["ID first name"]}
                      inputRef={firstNameRef}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t("Last Name")}
                      size="small"
                      variant="outlined"
                      defaultValue={tenant["ID last name"]}
                      inputRef={lastNameRef}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t("Date of Birth")}
                      size="small"
                      type="date"
                      variant="outlined"
                      defaultValue={tenant["ID Date of Birth"]}
                      inputRef={birthDateRef}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography sx={{ fontSize: 14 }}>
                  {t("This candidate has provided this address")}
                </Typography>
              </Grid>

              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <TextField
                      label={t("Street")}
                      size="small"
                      defaultValue={tenant["Current address"]}
                      inputRef={streetRef}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={t("Number")}
                      size="small"
                      defaultValue={tenant["Current number"]}
                      inputRef={housenumberRef}
                      fullWidth
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Post code")}
                      size="small"
                      defaultValue={tenant["Current post code"]}
                      inputRef={postCodeRef}
                      fullWidth
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label={t("City")}
                      size="small"
                      defaultValue={tenant["Current city"]}
                      inputRef={cityRef}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid item mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setIsExpat(!isExpat)}
                        value={isExpat}
                      />
                    }
                    label={t("This person is an expat")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="column" wrap="nowrap" spacing={1} mt={2}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  fullWidth
                  onClick={() => setEdrDialogOpen(false)}
                >
                  {t("BACK")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  fullWidth
                  disabled={disableRetry}
                >
                  {t("RETRY")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const creditAssessmentCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }} sx={accordionStyle}>
      <AccordionNoBorder
        expanded={creditExpanded}
        onChange={() => setCreditExpanded(!creditExpanded)}
        key={"creditAssessmentCard"}
      >
        <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
                {t("Credit assessment")}
              </Typography>
            </Grid>
            <Grid item align="center">
              <Grid container direction="column" wrap="nowrap">
                <RenderStatus status={tenant["CheckC_Status"]} />
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    display: { sm: "block", xs: "none" },
                  }}
                >
                  {tenant["CheckC_Completed"]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" wrap="nowrap" px={isSmUp ? 2 : 0}>
            <Grid item>
              <TableContainer sx={{ padding: 0.5, marginTop: 1 }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      border: "none",
                      padding: 0.5,
                    },
                    [`& .${tableRowClasses.root}`]: {
                      height: "40px",
                    },
                    marginTop: 1,
                    maxWidth: 600,
                  }}
                >
                  <TableBody>
                    <TableRow key="type_of_edr_verification">
                      <TableCell align="left" wrap="nowrap">
                        {t("Type of verification")}:
                      </TableCell>
                      <TableCell align="left">
                        {t("EDR Consumententoetsing")}
                      </TableCell>
                    </TableRow>

                    <TableRow key="currentAddress">
                      <TableCell align="left" wrap="nowrap">
                        {t("Current Address")}:
                      </TableCell>
                      <TableCell>{wholeAddress}</TableCell>
                    </TableRow>
                    {(tenant["EDR response"] || tenant["CheckC_Status"] === 'Failed') && (
                      <>
                        <TableRow key="address_risk">
                          <TableCell align="left">
                            {t("Address risk")}:
                          </TableCell>
                          <TableCell align="left">
                            {tenant["EDR overall score"] === 0
                              ? "-"
                              : (Number(
                                JSON.parse(tenant["EDR response"])[
                                "statisticalscore"
                                ]
                              ) +
                                Number(
                                  JSON.parse(tenant["EDR response"])[
                                  "paymentscore"
                                  ]
                                )) /
                                2 >=
                                35
                                ? t("Low risk")
                                : t("High risk")}
                          </TableCell>
                        </TableRow>
                        <TableRow key="personal_score">
                          <TableCell align="left">
                            {t("Personal score")}:
                          </TableCell>
                          <TableCell align="left">
                            {tenant["EDR personal score"] === 0 ? (
                              "-"
                            ) : (
                              <>
                                {tenant["EDR personal score"]}
                                {tenant["EDR personal score"] < 35 ? (
                                  <sup
                                    style={{
                                      fontSize: 9,
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    {t("BAD")}
                                  </sup>
                                ) : (
                                  <sup
                                    style={{
                                      fontSize: 9,
                                      fontWeight: "bold",
                                      color: "green",
                                    }}
                                  >
                                    {" "}
                                    {t(
                                      tenant["EDR personal score"] < 55
                                        ? t("GOOD")
                                        : t("VERY GOOD")
                                    )}
                                  </sup>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow key="overall_score">
                          <TableCell align="left">
                            {t("Overall score")}:
                          </TableCell>
                          <TableCell align="left">
                            {tenant["EDR overall score"] === 0 ? (
                              "-"
                            ) : (
                              <>
                                {tenant["EDR overall score"]}
                                {tenant["EDR overall score"] < 35 ? (
                                  <sup
                                    style={{
                                      fontSize: 9,
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {" "}
                                    {t("BAD")}
                                  </sup>
                                ) : (
                                  <sup
                                    style={{
                                      fontSize: 9,
                                      fontWeight: "bold",
                                      color: "green",
                                    }}
                                  >
                                    {" "}
                                    {t(
                                      tenant["EDR overall score"] < 55
                                        ? t("GOOD")
                                        : t("VERY GOOD")
                                    )}
                                  </sup>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {!tenant["EDR response"] && tenant["CheckC_Status"] === 'Completed' && (
                      <TableRow key="personal_score">
                        <TableCell align="left">
                          {t("Personal score")}:
                        </TableCell>
                        <TableCell align="left">
                          {t("Expat: Check not performed")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {tenant["CheckC_Status"] === "Failed" &&
                (
                  <Grid mt={1}>
                    <Alert severity="error">
                      <AlertTitle>
                        {t("EDR Credit Check not completed")}
                      </AlertTitle>
                      <Grid>
                        <Typography variant="caption">
                          {t("EDR Credit Check not completed paragraph 1")}
                        </Typography>
                      </Grid>
                      <Grid mt={1}>
                        <Typography variant="caption">
                          {t("EDR Credit Check not completed paragraph 2")}
                        </Typography>
                      </Grid>
                    </Alert>
                    <Button
                      variant="contained"
                      style={{
                        margin: 10,
                        borderRadius: 4,
                        maxWidth: "250px",
                      }}
                      onClick={() => setEdrDialogOpen(true)}
                      fullWidth
                    >
                      {t("DIAGNOSE ISSUE")}
                    </Button>
                  </Grid>
                )}

              {!tenant["EDR conclusion"].includes("expat status") &&
                tenant["EDR overall score"] !== 0 && (
                  <Box
                    p={1}
                    my={1}
                    sx={{
                      border: 1,
                      borderRadius: "6px",
                      borderColor: "#FCA510",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      spacing={{ xs: 1, md: 5 }}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <img
                          src={
                            tenant["EDR overall score"] >= 35
                              ? greenTrafficLight
                              : tenant["EDR overall score"] < 30
                                ? redTrafficLight
                                : yellowTrafficLight
                          }
                          alt="trafficLight"
                          height="110"
                        />
                      </Grid>
                      <Grid item>
                        {!tenant["EDR issues text"].includes(
                          "Geen incassodossiers, faillissementen of schuldsaneringen gevonden"
                        ) && (
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  {tenant["EDR issues text"]
                                    .split("\n")
                                    .map((element) => (
                                      <TableRow key={element}>
                                        <TableCell align="left">
                                          {element.split(": ")[0]}
                                        </TableCell>
                                        <TableCell align="left">
                                          {element.split(": ")[1]}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}

                        {tenant["EDR issues text"].includes(
                          "Geen incassodossiers, faillissementen of schuldsaneringen gevonden"
                        ) && (
                            <Typography
                              p={1}
                              variant="body2"
                              sx={{ whiteSpace: "pre-line" }}
                            >
                              {tenant["EDR issues text"]}
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </Grid>
            <Grid item>
              <Typography p={1} variant="body2" mt={2}>
                <strong>{t("Conclusion")}:</strong> {tenant["EDR conclusion"]}
              </Typography>
            </Grid>
          </Grid>
          <FixEDRDialog />
        </AccordionDetails>
      </AccordionNoBorder>
    </Grid>
  );

  const creditAssessmentNoCompletedCard = (
    <Grid
      style={{ maxWidth: "1200px", width: "100%" }}
      sx={accordionNoCompletedStyle}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
          {t("Credit assessment")}
        </Typography>
        <RenderStatus status={tenant["CheckC_Status"]} />
      </Grid>
    </Grid>
  );

  const DocumentsTable = ({ name, urls_string }) => {
    if (!urls_string) {
      if (name === "Extra document") return null;
      return (
        <Grid item xs={12} lg={6} mt={1}>
          <Typography ml={2} sx={{ fontSize: 16, fontWeight: "bold" }}>
            {t(name)}
          </Typography>
          <Typography m={2} p={0.5} sx={{ fontSize: 14 }}>
            {t("No documents provided")}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDocumentType(name);
              setShowUploadDocsDialog(true);
            }}
            startIcon={<NoteAddIcon />}
            sx={{ marginBottom: 5, marginX: 2, marginTop: 1 }}
          >
            {t("UPLOAD DOCUMENTS")}
          </Button>
        </Grid>
      );
    }

    const urlsArray = urls_string.split(", ");

    async function openDocument(url) {
      const response = await axios.post(
        configData.backendURL + "/create_presigned_url_api",
        {
          url: url,
        }
      );
      window.open(response.data);
    }

    function getFileName(fileName) {
      const fileNameMaxLength = isSmUp ? 50 : 20;

      if (fileName.length < fileNameMaxLength) return fileName;

      const re = /(?:\.([^.]+))?$/;
      const name = fileName.replace(/\.[^/.]+$/, "");
      const extension = re.exec(fileName)[1];

      return name.slice(0, fileNameMaxLength) + "(...)." + extension;
    }

    return (
      <Grid item xs={12} lg={6}>
        <Typography ml={2} mt={1} sx={{ fontSize: 16, fontWeight: "bold" }}>
          {t(name)}
        </Typography>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              padding: 0.5,
            },
            [`& .${tableRowClasses.root}`]: {
              height: "40px",
            },
            marginTop: 2,
            marginX: 2,
            maxWidth: "450px",
          }}
        >
          <TableBody>
            {urlsArray.map((url) => (
              <TableRow key={url}>
                <TableCell align="left">
                  {getFileName(url.split("/").slice(-1)[0])}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    style={{ borderRadius: 0 }}
                    onClick={() => openDocument(url)}
                  >
                    <LaunchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {name !== "Extra document" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDocumentType(name);
              setShowUploadDocsDialog(true);
            }}
            startIcon={<NoteAddIcon />}
            sx={{ marginBottom: 5, marginX: 2, marginTop: 1 }}
          >
            {t("UPLOAD DOCUMENTS")}
          </Button>
        )}
      </Grid>
    );
  };

  let name = t("Proof of income");
  if (
    ["Work", "Recently Changed Jobs", "Changing Jobs", "Study"].includes(
      tenant["WhatToDo"]
    )
  )
    name = t("Proof of employment");

  const UploadDocumentsDialog = ({
    showUploadDocsDialog,
    setShowUploadDocsDialog,
  }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();

    async function handleUploadDocuments(event) {
      event.preventDefault();
      setLoading(true);

      let bucket;
      switch (documentType) {
        case "Bank statements":
          bucket = "properize-bank-statements";
          break;
        case "Payslips":
          bucket = "properize-payslips";
          break;
        case "Other document":
          bucket = "properize-miscellaneous";
          break;
        case "Extra document":
          bucket = "properize-miscellaneous";
          break;
        default:
          bucket = "properize-employer-docs";
          break;
      }

      await uploadFilesToS3Directly(
        properizeIdTenantSelected,
        files,
        bucket,
        setProgress
      );

      let payload = {
        properize_id: properizeIdTenantSelected,
        documentType: documentType,
        bucket: bucket,
        fileNames: files.map((file) => file.file.name.replaceAll(" ", "_")),
      };

      await axios.post(
        configData.backendURL + "/upload_documents_from_dashboard",
        payload
      );
      dispatch(fetchTenants(userSettings.group_id));
      dispatch(fetchTenantsForAdvancedList(userSettings.group_id));
      setShowUploadDocsDialog(false);
    }
    return (
      <Dialog open={showUploadDocsDialog}>
        <DialogTitle>
          <Grid p={1} align="center">
            <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
              {t(documentType)}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleUploadDocuments}>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              alignItems="center"
              sx={{ maxWidth: "400px" }}
              spacing={3}
            >
              <Grid item>
                <DropzoneReact files={files} setFiles={setFiles} />
              </Grid>
              <Grid item>
                <Alert severity="info">
                  <AlertTitle>{t("Please Read")}</AlertTitle>
                  <Typography variant="caption">
                    {t("upload documents warning")}
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              mt={10}
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  onClick={() => setShowUploadDocsDialog(false)}
                >
                  {t("CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{
                    borderRadius: 16,
                    fontSize: "18px",
                  }}
                  disabled={loading || files.length === 0 || !documentType}
                >
                  {(!loading || files.length === 0) && t("SUBMIT")}
                  {loading && files.length > 0 && (
                    <CircularProgressWithLabel value={progress} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const documentCategories = [
    { name: "Bank statements", urls_string: tenant["Bank_Statement_Link"] },
    { name: "Payslips", urls_string: tenant["Document_Payslips_link"] },
    { name: name, urls_string: tenant["Document_Employer_links"] },
    { name: "Other document", urls_string: other_documents },
    { name: "Extra document", urls_string: tenant["Extra link"] },
  ];

  function dismissWhyNot() {
    axios.post(configData.backendURL + "/update_airtable_row", {
      properize_id: properize_id,
      why_not_dismissed_by_agent: true,
    });

    dispatch(
      tenantsEdited(
        tenantsDetails.map((t) =>
          t["properize_id"] === properize_id
            ? {
              ...t,
              why_not_dismissed_by_agent: true,
            }
            : t
        )
      )
    );
  }

  const documentsCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }} sx={accordionStyle}>
      <AccordionNoBorder
        expanded={documentsExpanded}
        onChange={() => setDocumentsExpanded(!documentsExpanded)}
        key="documentsCard"
      >
        <AccordionSummary sx={{ bgcolor: "#ffffff" }}>
          <Typography p={1} sx={{ fontSize: 20, fontWeight: "bold" }}>
            {t("Documents")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {tenant["why_not_files"] && !tenant["why_not_dismissed_by_agent"] && (
            <Grid container mb={2} px={isSmUp ? 2 : 0}>
              <Alert severity="error">
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <AlertTitle>{t("Missing documents")}</AlertTitle>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={dismissWhyNot}
                    >
                      {t("DISMISS")}
                    </Button>
                  </Grid>
                </Grid>

                <Grid>
                  <Typography variant="caption">
                    {t("Missing documents paragraph")}:
                  </Typography>
                </Grid>
                <Grid mt={1}>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12, fontStyle: "italic", color: "#6a6a6a" }}
                  >
                    "{tenant["why_not_files"]}"
                  </Typography>
                </Grid>
              </Alert>
            </Grid>
          )}

          <Grid container direction="row" ml={isSmUp ? 1 : 0}>
            {documentCategories.map((category) => (
              <DocumentsTable
                name={category.name}
                key={category.name}
                urls_string={category.urls_string}
              />
            ))}
          </Grid>
          <Grid container mt={1} spacing={3}>
            <Grid item ml={2}>
              <DocumentButton
                name={t("DOWNLOAD ALL")}
                urls_string={all_documents}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </AccordionNoBorder>
      <UploadDocumentsDialog
        showUploadDocsDialog={showUploadDocsDialog}
        setShowUploadDocsDialog={setShowUploadDocsDialog}
      />
    </Grid>
  );

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Candidate tenant summary")}
      </Typography>

      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Candidate tenant summary")}
      </Typography>
      <Box textAlign="center" sx={{ display: { sm: "none", xs: "block" } }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ marginBottom: 1 }}
        >
          {t("GO BACK TO OVERVIEW")}
        </Button>
      </Box>
      <Grid container direction="column">
        <Grid item sx={{ display: { sm: "block", xs: "none" } }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: 1180 }}
          >
            <Grid item>{breadcrumbsCard}</Grid>
            <Grid item>
              <ExpandAllButton />
            </Grid>
          </Grid>
        </Grid>

        {["Completed", "Failed"].includes(tenant["CheckC_Status"]) && personalDataCard}
        {!["Completed", "Failed"].includes(tenant["CheckC_Status"]) && personalDataNoCompletedCard}
        {tenant["CheckB_Status"] === "Completed" && <SummaryCard />}
        {["Completed", "Failed"].includes(tenant["CheckA_Status"]) &&
          idVerificationCard}
        {!["Completed", "Failed"].includes(tenant["CheckA_Status"]) &&
          idVerificationNoCompletedCard}
        {tenant["CheckB_Status"] === "Completed" && incomeVerificationCard}
        {tenant["CheckB_Status"] !== "Completed" &&
          tenant["Account Type"] !== "Lite" &&
          incomeVerificationNoCompletedCard}
        {["Completed", "Failed"].includes(tenant["CheckC_Status"]) && creditAssessmentCard}
        {!["Completed", "Failed"].includes(tenant["CheckC_Status"]) &&
          creditAssessmentNoCompletedCard}
        {tenant["Account Type"] !== "Lite" && documentsCard}
      </Grid>
    </motion.div>
  );
};

export default TenantDetail;
