import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectGroupSettings } from "../slicers/userDataSlice";

import LoadingDataComponent from "../components/LoadingDataComponent";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LanguageIcon from "@mui/icons-material/Language";
import getBrightness from "getbrightness";
import cookies from "js-cookie";

import { Player } from "@lottiefiles/react-lottie-player";
import LanguageMenuSelection from "../components/LanguageMenuSelection";
import MessagePopup from "../components/MessagePopup";

import { ReactComponent as ProperizeWhiteLogo } from "../logos/properize-monochrome-white.svg";
import properizeLogo from "../logos/properize.png";

import configData from "../utils/config.json";
import navigatorLanguage from "../utils/navigatorLanguage";
import AutoCompleteAddress from "../components/AutoCompleteAddress";

const CssTextField = styled(TextField)({
  marginTop: 15,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
    },
  },
});

const paperStyle = {
  padding: 3,
  mx: 2,
  minWidth: 350,
  maxWidth: 450,
  bgcolor: "#f5f5f5",
  borderColor: "#257cff",
  borderRadius: 4,
  boxShadow: 3,
};

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const translationJSON = {
  English: {
    interested_in_renting_a_property: "Interested in renting a property?",
    let_us_know_here: "Let us know here",
    if_you_are_visiting_this_website1:
      "If you are visiting this website, it must be because you are interested in renting a property offered by ",
    if_you_are_visiting_this_website2:
      ". Fill in this form to formally apply for the property and initiate tenant screening",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    continue: "Continue",
    addressLabel: "Rental property address",
    placeHolderLabel: "Start typing...",
    street: "Street",
    number: "Number",
    post_code: "Post Code",
    city: "City",
    rental_price: "Monthly Rental Price (EUR)",
    back: "Back",
    review_your_application: "Please review your tenant application",
    your_email_address: "Your e-mail address:",
    your_rental_property: "The rental property you are interested in:",
    monthly_rental_price: "Monthly Rental price:",
    renting_with_somebody_disclaimer:
      "If you are renting with somebody else, you will have the chance to add them later to your application",
    "By ticking, you agree to our": "By ticking, you agree to our ",
    "terms and conditions": "terms and conditions",
    and: " and ",
    "privacy policy": "privacy policy",
    tenant_application_sent: "Tenant application sent",
    tenant_application_sent_paragraph1:
      "In a few minutes you will receive an e-mail from our partner Properize to conduct tenant screening. Once completed, your real estate agent at ",
    tenant_application_sent_paragraph2:
      " will approach you regarding next steps.",
    you_can_close_this_window: "You can now close this window",
    this_tenant_solution_is_powered_by:
      "This tenant onboarding solution is powered by",
    BACK: "BACK",
    SUBMIT: "SUBMIT",
    "You need to agree with terms and conditions":
      "You need to agree with our Terms and Conditions and Privacy Policy",
    "No valid address": "The address is invalid/incomplete. Please go back, enter the address and select one of the suggested locations"
  },
  Français: {
    interested_in_renting_a_property: "Intéressé par la location d'un bien?",
    let_us_know_here: "Faites-le nous savoir ici",
    if_you_are_visiting_this_website1:
      "Si vous visitez ce site Web, cela doit être parce que vous êtes intéressé par la location d'une propriété proposée par ",
    if_you_are_visiting_this_website2:
      ". Remplissez ce formulaire pour postuler officiellement à la propriété et lancer la sélection des locataires",
    first_name: "Prénom",
    last_name: "Nom",
    email: "Email",
    continue: "Continuer",
    addressLabel: "Adresse du bien locatif",
    placeHolderLabel: "Commencer à écrire...",
    street: "Rue",
    number: "Numéro",
    post_code: "Code postal",
    city: "Ville",
    rental_price: "Prix de location mensuel (EUR)",
    back: "Retour",
    review_your_application: "Veuillez revoir votre demande de locataire",
    your_email_address: "Votre adresse e-mail:",
    your_rental_property: "Le bien locatif qui vous intéresse:",
    monthly_rental_price: "Prix de location mensuel:",
    renting_with_somebody_disclaimer:
      "Si vous louez avec quelqu'un d'autre, vous aurez la possibilité de l'ajouter plus tard à votre demande",
    "By ticking, you agree to our": "En cochant, vous acceptez nos ",
    "terms and conditions": "termes et conditions",
    and: " et ",
    "privacy policy": "politique de confidentialité",
    tenant_application_sent: "Candidature locataire envoyée",
    tenant_application_sent_paragraph1:
      "Dans quelques minutes, vous recevrez un e-mail de notre partenaire Properize pour effectuer une sélection des locataires. Une fois terminé, votre agent immobilier au ",
    tenant_application_sent_paragraph2:
      " vous contactera concernant les prochaines étapes.",
    you_can_close_this_window: "Vous pouvez maintenant fermer cette fenêtre",
    this_tenant_solution_is_powered_by:
      "Cette solution d'intégration des locataires est optimisée par",
    BACK: "RETOUR",
    SUBMIT: "SOUMETTRE",
    "You need to agree with terms and conditions":
      "Vous devez accepter nos conditions générales et notre politique de confidentialité",
      "No valid address": "L'adresse est invalide/incomplète. Veuillez revenir en arrière, entrer l'adresse et sélectionner l'un des emplacements suggérés"
  },
  Nederlands: {
    interested_in_renting_a_property: "Geïnteresseerd in een huurwoning?",
    let_us_know_here: "Laat het ons weten",
    if_you_are_visiting_this_website1:
      "Fijn dat u geïnteresseerd bent in het huren van een woning aangeboden door ",
    if_you_are_visiting_this_website2:
      ". Vul dit formulier in om de woning formeel aan te vragen en het screeningproces te starten",
    first_name: "Voornaam",
    last_name: "Achternaam",
    email: "Email",
    continue: "Verder",
    addressLabel: "Adres huurwoning",
    placeHolderLabel: "Begin met typen...",
    street: "Straatnaam",
    number: "Nummer",
    post_code: "Postcode",
    city: "Plaats",
    rental_price: "Maandelijkse huurprijs (EUR)",
    back: "Terug",
    review_your_application: "Controleer uw huurdersaanvraag",
    your_email_address: "Uw gegevens:",
    your_rental_property: "Adres van huurwoning:",
    monthly_rental_price: "Maandelijkse huurprijs:",
    renting_with_somebody_disclaimer:
      "Als u samen met iemand anders huurt, krijgt u de kans om deze later in dit proces aan uw aanvraag toe te voegen",
    "By ticking, you agree to our":
      "Door aan te vinken gaat u akkoord met onze ",
    "terms and conditions": "algemene voorwaarden",
    and: " en ",
    "privacy policy": "privacy policy",
    tenant_application_sent: "Huurdersaanvraag verzonden",
    tenant_application_sent_paragraph1:
      "Binnen enkele minuten ontvangt u een e-mail van onze partner Properize om het screeningproces te voltooien. Na afronding zal uw makelaar bij ",
    tenant_application_sent_paragraph2: " contact met u opnemen.",
    you_can_close_this_window: "U kunt dit venster sluiten.",
    this_tenant_solution_is_powered_by:
      "Dit instapproces wordt mogelijk gemaakt door",
    BACK: "TERUG",
    SUBMIT: "INDIENEN",
    "You need to agree with terms and conditions":
      "U moet akkoord gaan met onze Algemene voorwaarden en Privacybeleid",
      "No valid address": "Het adres is ongeldig/onvolledig. Ga terug, voer het adres in en selecteer een van de voorgestelde locaties"
  },
};

const FirstScreen = ({
  languageLabel,
  getBrightness,
  loadedFromDashboard,
  companyName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  primaryColor,
  secondaryColor,
  goToFirstScreen,
  goToSecondScreen,
}) => {
  return (
    <Paper sx={paperStyle}>
      <Typography
        style={{
          color: primaryColor,
          fontWeight: "bold",
        }}
      >
        {translationJSON[languageLabel].interested_in_renting_a_property}
      </Typography>
      <Typography
        style={{
          color: primaryColor,
          fontWeight: "bold",
        }}
      >
        {translationJSON[languageLabel].let_us_know_here}
      </Typography>
      <form onSubmit={loadedFromDashboard ? goToFirstScreen : goToSecondScreen}>
        <Typography style={{ margin: 10 }}>
          {translationJSON[languageLabel].if_you_are_visiting_this_website1}
          {companyName}
          {translationJSON[languageLabel].if_you_are_visiting_this_website2}
        </Typography>
        <CssTextField
          label={translationJSON[languageLabel].first_name}
          variant="outlined"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          InputLabelProps={{ required: false }}
          fullWidth
          required={!loadedFromDashboard}
        />

        <CssTextField
          label={translationJSON[languageLabel].last_name}
          variant="outlined"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          InputLabelProps={{ required: false }}
          fullWidth
          required={!loadedFromDashboard}
        />
        <CssTextField
          label={translationJSON[languageLabel].email}
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          InputLabelProps={{ required: false }}
          type="email"
          fullWidth
          required={!loadedFromDashboard}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{
            borderRadius: 8,
            marginTop: 30,
            fontSize: "18px",
            backgroundColor: secondaryColor,
            color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
          }}
        >
          {translationJSON[languageLabel].continue}
        </Button>
      </form>
    </Paper>
  );
};

const SecondScreen = ({
  languageLabel,
  getBrightness,
  street,
  setStreet,
  houseNumber,
  setHouseNumber,
  postCode,
  setPostCode,
  city,
  setCity,
  rentalPrice,
  setRentalPrice,
  secondaryColor,
  goToFirstScreen,
  goToReviewScreen,
}) => {
  return (
    <Paper sx={paperStyle}>
      <form onSubmit={goToReviewScreen}>
        <AutoCompleteAddress
          setStreet={setStreet}
          setHouseNumber={setHouseNumber}
          setPostCode={setPostCode}
          setCity={setCity}
          addressLabel={translationJSON[languageLabel].addressLabel}
          placeHolderLabel={translationJSON[languageLabel].placeHolderLabel}
        />

        <div style={{ marginTop: "10px" }}>
          <CssTextField
            label={translationJSON[languageLabel].rental_price}
            type="number"
            variant="outlined"
            value={rentalPrice}
            onChange={(event) => setRentalPrice(event.target.value)}
            InputLabelProps={{ required: false }}
            fullWidth
            required
          />
        </div>

        <Button
          variant="outlined"
          fullWidth
          onClick={goToFirstScreen}
          style={{
            borderRadius: 8,
            marginTop: 50,
            fontSize: "18px",
            color:
              getBrightness(secondaryColor) > 0.5 ? "black" : secondaryColor,
            borderColor: secondaryColor,
          }}
        >
          {translationJSON[languageLabel].back}
        </Button>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{
            borderRadius: 8,
            marginTop: 10,
            fontSize: "18px",
            backgroundColor: secondaryColor,
            color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
          }}
        >
          {translationJSON[languageLabel].continue}
        </Button>
      </form>
    </Paper>
  );
};

function TenantPage({
  loadedFromDashboard = false,
  primaryColorPreview,
  secondaryColorPreview,
  backgroundColorPreview,
  imagePreview,
}) {
  const id = useParams().id;

  // Company variables
  const [companyName, setCompanyName] = useState();
  const [logoURL, setLogoURL] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);
  const [daysDeadline, setDaysDeadline] = useState();
  const [costScreening, setCostScreening] = useState();
  const [suspended, setSuspended] = useState(false);

  // Main agent variables
  const [groupId, setGroupId] = useState();
  const [emailAgent, setEmailAgent] = useState();

  // Other variables
  const [error, setError] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);

  const groupSettings = useSelector(selectGroupSettings);

  useEffect(() => {
    if (loadedFromDashboard) {
      setPrimaryColor(primaryColorPreview);
      setSecondaryColor(secondaryColorPreview);
      setBackgroundColor(backgroundColorPreview);
      setLogoURL(imagePreview || groupSettings.logo_url);
    }
  }, [
    primaryColorPreview,
    secondaryColorPreview,
    backgroundColorPreview,
    imagePreview,
    groupSettings,
    loadedFromDashboard,
  ]);
  useEffect(() => {
    if (loadedFromDashboard) {
      setGroupId(groupSettings.group_id);
      setDaysDeadline(groupSettings.days_deadline);
      setCompanyName(groupSettings.company_name);
      setCostScreening(groupSettings.cost_screen);
      setEmailAgent(groupSettings.email_master);
      setSuspended(groupSettings.suspended);
      setRenderComponent(true);
      setError(false);
    } else {
      let payload = {
        key: "tenant_link",
        value: id,
      };

      axios
        .post(configData.backendURL + "/get_properize_groups_data", payload, {
          timeout: 5000,
        })
        .then((response) => {
          setLogoURL(response.data.logo_url);
          setPrimaryColor(response.data.primary_color);
          setSecondaryColor(response.data.secondary_color);
          setBackgroundColor(response.data.background_color);
          setGroupId(response.data.group_id);
          setDaysDeadline(response.data.days_deadline);
          setCompanyName(response.data.company_name);
          setCostScreening(response.data.cost_screen);
          setEmailAgent(response.data.email_master);
          setSuspended(response.data.suspended);
          setRenderComponent(true);
          setError(false);
        })
        .catch((err) => {
          setError(true);
          setRenderComponent(true);
        });
    }
  }, [id, groupSettings, loadedFromDashboard]);

  const ComponentRender = () => {
    const [showFirstScreen, setShowFirstScreen] = useState(true);
    const [showSecondScreen, setShowSecondScreen] = useState(false);
    const [showReviewScreen, setShowReviewScreen] = useState(false);
    const [tenantAddedSuccesfully, setTenantAddedSuccesfully] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);

    function goToFirstScreen(e) {
      e.preventDefault();
      setShowFirstScreen(true);
      setShowSecondScreen(false);
      setShowReviewScreen(false);
    }
    function goToSecondScreen(e) {
      setShowFirstScreen(false);
      setShowSecondScreen(true);
      setShowReviewScreen(false);
    }

    function goToReviewScreen(e) {
      setShowFirstScreen(false);
      setShowSecondScreen(false);
      setShowReviewScreen(true);
    }

    function goToAddedSuccesfullyScreen() {
      setShowReviewScreen(false);
      setTenantAddedSuccesfully(true);
      setDataSubmitted(false);
    }

    function handleSubmit() {
      setDataSubmitted(true);
      let number;
      if (houseNumber){
        number = houseNumber
      } else {
        number = "X"
      }
      const payload = {
        fields: {
          User_id: Number(groupId),
          "Parent request": String(
            Math.floor(100000000000 + Math.random() * 90000000000000)
          ),
          "First name": firstName,
          "Last name": lastName,
          Email: email,
          "Property address": street,
          "Property number": number,
          "Property city": city,
          "Post code": postCode,
          "Notes (private)": "Added from " + companyName + " landing page",
          "Name agent": companyName,
          "Email agent": emailAgent,
          "Cost of the screen (EUR)": Number(costScreening),
          "Property price (EUR)": Number(rentalPrice),
          "Days deadline": Number(daysDeadline),
          ArchiveRemove: 0,
          Status: "In progress",
          CheckA_Status: "In progress",
          CheckB_Status: "In progress",
          CheckC_Status: "In progress",
          "Initiated by": "Tenant via landing page",
        },
      };

      axios
        .post(configData.backendURL + "/create_airtable_record_api", payload)
        .then((response) => {
          goToAddedSuccesfullyScreen();
        });
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postCode, setPostCode] = useState("");
    const [city, setCity] = useState("");
    const [rentalPrice, setRentalPrice] = useState("");

    const [languageAnchor, setLanguageAnchor] = useState(null);

    const navigatorLanguageCode = navigatorLanguage();
    const language = cookies.get("i18next") || navigatorLanguageCode;
    const [languageLabel, setLanguageLabel] = useState(
      languagesData.find((lan) => lan.code === language).name
    );

    const handleLanguageClick = (event) => {
      setLanguageAnchor(event.currentTarget);
    };

    const ReviewScreen = () => {
      const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] =
        useState(false);
      const [message, setMessage] = useState(false);

      function handleCheckConditionsAccepted(e) {
        e.preventDefault();
        if (!termsAndConditionsCheckbox) {
          setMessage(
            translationJSON[languageLabel][
              "You need to agree with terms and conditions"
            ]
          );
        } else if (!street | !postCode | !city) {
          setMessage(translationJSON[languageLabel]["No valid address"]);
        } else {
          handleSubmit();
        }
      }
      return (
        <Paper sx={paperStyle}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].review_your_application}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].your_email_address}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {firstName} {lastName}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {email}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].your_rental_property}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {street} {houseNumber}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {postCode} {city}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {translationJSON[languageLabel].monthly_rental_price} €{rentalPrice}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].renting_with_somebody_disclaimer}
          </Typography>

          <MessagePopup
            state={message}
            setState={setMessage}
            message={message}
          />

          <FormControlLabel
            sx={{
              marginTop: "40px",
              marginBottom: "20px",
              marginLeft: "5px",
              alignItems: "flex-end",
            }}
            control={
              <Checkbox
                onChange={() =>
                  setTermsAndConditionsCheckbox(!termsAndConditionsCheckbox)
                }
                value={termsAndConditionsCheckbox}
              />
            }
            label={
              <Typography
                mt={3}
                align="left"
                style={{
                  fontSize: "12px",
                }}
              >
                {translationJSON[languageLabel]["By ticking, you agree to our"]}
                <Link
                  href="https://properize.com/terms-and-conditions/"
                  color="inherit"
                  target="_blank"
                >
                  {translationJSON[languageLabel]["terms and conditions"]}
                </Link>
                {translationJSON[languageLabel]["and"]}
                <Link
                  href="https://properize.com/privacy-policy/"
                  color="inherit"
                  target="_blank"
                >
                  {translationJSON[languageLabel]["privacy policy"]}
                </Link>
              </Typography>
            }
          />

          <Button
            variant="outlined"
            fullWidth
            onClick={goToSecondScreen}
            style={{
              borderRadius: 8,
              marginTop: 10,
              fontSize: "18px",
              color:
                getBrightness(secondaryColor) > 0.5 ? "black" : secondaryColor,
              borderColor: secondaryColor,
            }}
          >
            {translationJSON[languageLabel]["BACK"]}
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{
              borderRadius: 8,
              marginTop: 10,
              fontSize: "18px",
              backgroundColor: secondaryColor,
              color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
            }}
            onClick={handleCheckConditionsAccepted}
          >
            {translationJSON[languageLabel]["SUBMIT"]}
          </Button>
        </Paper>
      );
    };

    const CompletedScreen = () => {
      return (
        <Paper sx={paperStyle}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].tenant_application_sent}
          </Typography>
          <Player
            autoplay
            loop
            src="https://assets9.lottiefiles.com/packages/lf20_01bh9ld2.json"
          />
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].tenant_application_sent_paragraph1}
            {companyName}
            {translationJSON[languageLabel].tenant_application_sent_paragraph2}
          </Typography>

          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
              marginTop: 40,
            }}
          >
            {translationJSON[languageLabel].you_can_close_this_window}
          </Typography>
        </Paper>
      );
    };

    return (
      <div>
        <Grid
          container
          wrap="nowrap"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          minHeight={loadedFromDashboard ? "65vh" : "100vh"}
        >
          <Grid
            container
            wrap="nowrap"
            spacing={3}
            direction="column"
            align="center"
            justifyContent="center"
            style={{ minHeight: "85%" }}
            sx={{ marginBottom: loadedFromDashboard ? 2 : 10 }}
          >
            <Grid item>
              {logoURL && (
                <img
                  src={logoURL}
                  alt="logo"
                  height="50"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              )}
              {showFirstScreen && (
                <FirstScreen
                  getBrightness={getBrightness}
                  languageLabel={languageLabel}
                  loadedFromDashboard={loadedFromDashboard}
                  companyName={companyName}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  goToFirstScreen={goToFirstScreen}
                  goToSecondScreen={goToSecondScreen}
                />
              )}
              {showSecondScreen && (
                <SecondScreen
                  getBrightness={getBrightness}
                  languageLabel={languageLabel}
                  street={street}
                  setStreet={setStreet}
                  houseNumber={houseNumber}
                  setHouseNumber={setHouseNumber}
                  postCode={postCode}
                  setPostCode={setPostCode}
                  city={city}
                  setCity={setCity}
                  rentalPrice={rentalPrice}
                  setRentalPrice={setRentalPrice}
                  secondaryColor={secondaryColor}
                  goToFirstScreen={goToFirstScreen}
                  goToReviewScreen={goToReviewScreen}
                />
              )}
              {showReviewScreen && <ReviewScreen />}
              <LoadingDataComponent alreadyRendered={!dataSubmitted} />
              {tenantAddedSuccesfully && <CompletedScreen />}
            </Grid>
            <Grid item>
              <Typography
                style={{
                  color:
                    getBrightness(backgroundColor) > 0.5 ? "#444444" : "white",
                  fontSize: "12px",
                }}
              >
                {
                  translationJSON[languageLabel]
                    .this_tenant_solution_is_powered_by
                }
              </Typography>
              {getBrightness(backgroundColor) <= 0.5 && (
                <ProperizeWhiteLogo width="90" height="25" />
              )}

              {getBrightness(backgroundColor) > 0.5 && (
                <img
                  src={properizeLogo}
                  alt="properizelogo"
                  width="90"
                  height="25"
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            width: "100%",
            position: loadedFromDashboard ? "relative" : "fixed",
            bottom: loadedFromDashboard ? "auto" : 0,
            left: 0,
            right: 0,
          }}
        >
          <BottomNavigation showLabels sx={{ bgcolor: primaryColor }}>
            <BottomNavigationAction
              sx={{
                color: getBrightness(primaryColor) > 0.5 ? "black" : "white",
              }}
              label="Helpdesk"
              icon={<ContactSupportIcon />}
              onClick={() =>
                loadedFromDashboard
                  ? 0
                  : window.open("https://properize.com/contact", "_blank")
              }
            />
            <BottomNavigationAction
              label={languageLabel}
              icon={<LanguageIcon />}
              sx={{
                color: getBrightness(primaryColor) > 0.5 ? "black" : "white",
              }}
              onClick={handleLanguageClick}
            ></BottomNavigationAction>

            <LanguageMenuSelection
              anchorEl={languageAnchor}
              setAnchorEl={setLanguageAnchor}
              setLanguageLabel={setLanguageLabel}
            />
          </BottomNavigation>
        </Grid>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {renderComponent && !error && !suspended && <ComponentRender />}
      <LoadingDataComponent
        alreadyRendered={renderComponent || loadedFromDashboard}
      />
      {error && !loadedFromDashboard && <div>Invalid address</div>}
      {suspended && <div>Account suspended. Please contact Properize</div>}
    </div>
  );
}

export default TenantPage;
