import React, { useEffect, useRef, useState } from "react";
import { Box, Fab, Grid, Switch, Typography } from "@mui/material";
import Draggable from "react-draggable";

import AddIcon from "@mui/icons-material/Add";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  alpha,
  createTheme,
  styled,
  ThemeProvider,
} from "@mui/material/styles";

import { useSelector } from "react-redux";
import {
  selectTenants,
  selectTenantsForAdvancedList,
} from "../../../slicers/userDataSlice";

import DossierMobile from "./DossierMobile";
import SendInvitationToCandidate from "./SendInvitationToCandidate";
import { Point } from "../../../utils/Point";

import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#f45a2a",
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.5,
          backgroundColor: "#f45a2a",
        },
      },
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginBottom: 10,
  width: "100%",
}));

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
  },
  backgroundColor: "#FFFFFF",
  width: "100%",
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const tenantLine = " and " + String(row["n_tenants"] - 1) + " more";

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <IconButton size="small" style={{ width: "10px" }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {renderValues(row)}
          </Grid>
        </TableCell>

        <TableCell>
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container direction="column">
              <strong>{row["Property location"]}</strong>
              <Grid item mt={1}>
                {row["tenant1_Full name"]}
                {row["n_tenants"] > 1 && tenantLine}
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#f5f5f5",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: -1 }}>
              <DossierMobile parentRequest={row["id"]} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const renderValues = (row) => {
  let color, Icon;

  if (row["Status"] === "Completed") {
    color = "green";
    Icon = DoneIcon;
  } else if (row["Status"] === "Processing") {
    color = "#e47200";
    Icon = RestartAltIcon;
  } else if (row["Status"] === "In progress") {
    color = "blue";
    Icon = AccessTimeIcon;
  } else if (row["Status"] === "Failed") {
    color = "red";
    Icon = ClearIcon;
  }

  return (
    <Grid container direction="row" ml={2}>
      <IconButton
        style={{
          color: color,
          border: "solid 2px",
        }}
      >
        <Icon />
      </IconButton>
    </Grid>
  );
};

export default function CollapsibleTable() {
  const [firstLevelRows, setFirstLevelRows] = useState();
  const [secondLevelRows, setSecondLevelRows] = useState([]);

  const [alreadyRendered, setAlreadyRendered] = useState(false);
  const [addNewRequestWindow, setAddNewRequestWindow] = useState(false);

  const tenantsFirstLevel = useSelector(selectTenantsForAdvancedList);
  const tenantsSecondLevel = useSelector(selectTenants);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const nodeRef = useRef(null);

  const [archiveView, setArchiveView] = useState(false);
  const view = archiveView ? 1 : 0;

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function useClickObserver(callback) {
    const [dragStartPos, setDragStartPos] = useState(new Point());
    const onStart = (_, data) => {
      setDragStartPos(new Point(data.x, data.y));
    };
    const onStop = (_, data) => {
      const dragStopPoint = new Point(data.x, data.y);
      if (Point.dist(dragStartPos, dragStopPoint) < 5) {
        callback();
      }
    };
    return { onStart, onStop };
  }

  const requestSearch = (event) => {
    const filteredRows = tenantsFirstLevel.filter((row) => {
      const rating = row["Rating"];
      const status = t(row["Status"]);
      const address = row["Property location"];
      const nameTenant1 = row["tenant1_Full name"];
      const nameTenant2 = row["tenant2_Full name"];
      const created =
        row["Created"].slice(8, 10) +
        "-" +
        row["Created"].slice(5, 7) +
        "-" +
        row["Created"].slice(0, 4) +
        " " +
        row["Created"].slice(11);

      const rowConcatenated =
        rating +
        " " +
        status +
        " " +
        address +
        " " +
        nameTenant1 +
        " " +
        nameTenant2 +
        " " +
        created;

      return rowConcatenated
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setFirstLevelRows(filteredRows);
  };

  useEffect(() => {
    setFirstLevelRows(tenantsFirstLevel.filter(tenant => tenant.ArchiveRemove === view));
    setSecondLevelRows(tenantsSecondLevel);
    setAlreadyRendered(true);
  }, [tenantsFirstLevel, view]);

  function labelDisplayedRows({ from, to, count }) {
    return (
      from +
      "–" +
      to +
      " " +
      t("of") +
      " " +
      (count !== -1 ? count : t("more than") + " " + to)
    );
  }

  return (
    <div>
      <Grid container direction="column" alignItems="center">
        <Typography
          color="secondary"
          sx={{
            marginBottom: 2,
            fontSize: 20,
          }}
        >
          {t("Tenant Overview")}
        </Typography>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color="primary"
              sx={{ fontWeight: "bold", fontSize: 14 }}
            >
              {t("ONGOING")}
            </Typography>
            <Switch
              checked={archiveView}
              onChange={() => setArchiveView(!archiveView)}
            />
            <Typography
              color="primary"
              sx={{ fontWeight: "bold", fontSize: 14 }}
            >
              {t("ARCHIVED")}
            </Typography>
          </Grid>
        </ThemeProvider>

        <Search>
          <StyledInputBase
            placeholder={t("Search") + "…"}
            onChange={requestSearch}
          />
        </Search>

        {tenantsFirstLevel.length === 0 && view === 0 && (
          <Paper sx={{ height: "150px", padding: 3 }}>
            <Typography>{t("No Rows")}</Typography>
          </Paper>
        )}
        {tenantsFirstLevel.length !== 0 && alreadyRendered && (
          <Grid item sx={{ width: "100%" }}>
            <Paper>
              <Table>
                <TableBody>
                  {(rowsPerPage > 0
                    ? firstLevelRows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : firstLevelRows
                  ).map((row) => (
                    <Row
                      key={row["id"]}
                      row={row}
                      secondLevelRows={secondLevelRows}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <TablePagination
              labelRowsPerPage={t("Items per page")}
              labelDisplayedRows={labelDisplayedRows}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={firstLevelRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
      <Draggable
        nodeRef={nodeRef}
        {...useClickObserver(() => setAddNewRequestWindow(true))}
      >
        <div
          ref={nodeRef}
          style={{
            position: "fixed",
            bottom: "5%",
            right: "5%",
            zIndex: "999",
          }}
        >
          <Fab color="secondary">
            <AddIcon />
          </Fab>
        </div>
      </Draggable>
      {addNewRequestWindow && (
        <SendInvitationToCandidate
          addNewRequestWindow={addNewRequestWindow}
          setAddNewRequestWindow={setAddNewRequestWindow}
        />
      )}
    </div>
  );
}
