import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useAuth } from "../../../contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  userSettingsChanged,
} from "../../../slicers/userDataSlice";

import {
  snackbarMessageChanged,
  selectSnackbarMessage,
} from "../../../slicers/globalStateVariablesSlice";

import configData from "../../../utils/config.json";

import axios from "axios";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 3,
  maxWidth: "550px",
};

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

export default function UpdateProfile() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("en");

  const [changePasswordTickbox, setChangePasswordTickbox] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);

  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const userValues = useSelector(selectUserSettings);
  const snackbarMessage = useSelector(selectSnackbarMessage);

  const languages = languagesData.map((language) => (
    <MenuItem key={language.country_code} value={language.code}>
      <Grid
        mr={2}
        className={`flag-icon flag-icon-${language.country_code} mx-2`}
      ></Grid>
      <strong>{language.name}</strong>
    </MenuItem>
  ));

  useEffect(() => {
    setName(userValues.name_agent);
    setEmail(currentUser.email);
    setLanguage(userValues.language);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const promises = [];
    const oldEmail = currentUser.email
    setLoading(true);

    if (changePasswordTickbox) {
      if (newPassword !== passwordConfirm) {
        setNewPassword("");
        setPasswordConfirm("");
        setLoading(false);
        return dispatch(snackbarMessageChanged(t("Passwords do not match")));
      }
      promises.push(updatePassword(newPassword));
    }

    if (email !== currentUser.email) {
      promises.push(updateEmail(email));
    }

    Promise.all(promises)
      .then(() => {
        let payload;
        if (email !== oldEmail) {
          payload = {
            email: userValues.email,
            newEmail: email,
          };

          axios
            .post(configData.backendURL + "/change_email_user", payload)
            .then((response) => {
              dispatch(userSettingsChanged({ email: email }));
            })
            .catch((signUpError) => {
              dispatch(
                snackbarMessageChanged(
                  t("Critical error email change") + "hello@properize.com"
                )
              );
            });
        }

        payload = {
          email: currentUser.email,
          name_agent: name,
          language: language,
        };

        axios
          .post(configData.backendURL + "/update_properize_users_data", payload)
          .then((response) => {
            dispatch(userSettingsChanged(payload));
            i18next.changeLanguage(language);
          });
        if (!snackbarMessage) {
          dispatch(snackbarMessageChanged(t("Data Saved Successfully")));
        }
      })
      .catch((signUpError) => {
        if (
          signUpError.toString().includes("The email address is already in use")
        ) {
          setLoading(false);
          dispatch(
            snackbarMessageChanged(t("The email address is already in use"))
          );
        } else if (
          signUpError
            .toString()
            .includes("Password should be at least 6 characters")
        ) {
          setLoading(false);
          dispatch(
            snackbarMessageChanged(
              t("Password should be at least 6 characters long")
            )
          );
        } else if (
          signUpError.toString().includes("requires recent authentication")
        ) {
          setLoading(false);
          dispatch(snackbarMessageChanged(t("Sensitive Operation")));
        } else {
          setLoading(false);
          dispatch(snackbarMessageChanged(signUpError.toString()));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{
          marginLeft: 2,
          marginBottom: 1,
          fontSize: 24,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("Profile")}
      </Typography>

      <Typography
        color="secondary"
        align="center"
        sx={{
          marginBottom: 2,
          fontSize: 20,
          display: { sm: "none", xs: "block" },
        }}
      >
        {t("Profile")}
      </Typography>
      <Card sx={cardStyle}>
        <Typography
          sx={{
            margin: 1,
            marginBottom: 2,
            fontSize: 20,
            fontWeight: "bold",
            flexGrow: 1,
          }}
        >
          {t("User details")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={t("Your name")}
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              required: false,
              shrink: true,
            }}
            style={{ marginTop: 15 }}
          />
          <Grid container direction="column">
            <TextField
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label={t("Email")}
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{
                required: false,
                shrink: true,
              }}
              style={{ marginTop: 15 }}
            />

            <TextField
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
              label={t("Language")}
              select
              fullWidth
              required
              InputLabelProps={{
                required: false,
                shrink: true,
              }}
              style={{ marginTop: 15 }}
            >
              {languages}
            </TextField>

            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    setChangePasswordTickbox(!changePasswordTickbox)
                  }
                  value={changePasswordTickbox}
                />
              }
              style={{ marginTop: 15 }}
              label={t("Change password")}
            />
            {changePasswordTickbox && (
              <TextField
                label={t("Password")}
                type="password"
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                required
                style={{ marginTop: 15 }}
              />
            )}

            {changePasswordTickbox && (
              <TextField
                label={t("Repeat password")}
                type="password"
                variant="outlined"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                fullWidth
                required
                style={{ marginTop: 15 }}
              />
            )}

            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{
                borderRadius: 16,
                fontSize: "18px",
                marginTop: 40,
              }}
              disabled={
                loading ||
                (userValues.name_agent === name &&
                  currentUser.email === email &&
                  userValues.language === language &&
                  !changePasswordTickbox)
              }
              fullWidth
            >
              {t("APPLY CHANGES")}
            </Button>
          </Grid>
        </form>
      </Card>
    </motion.div>
  );
}
