import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import cookies from "js-cookie";

import KeyIcon from "@mui/icons-material/Key";

import { useNavigate } from "react-router-dom";

import MessagePopup from "../components/MessagePopup";
import navigatorLanguage from "../utils/navigatorLanguage";

import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import properizeLogo from'../logos/properize.png';

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

const paperStyle = {
  padding: 4,
  minHeight: "20vw",
  width: 350,
  bgcolor: "primary.main",
  color: "#FFFFFF",
  borderColor: "#257cff",
  borderRadius: 8,
  border: 2,
  boxShadow: 8,
};

const CssTextField = styled(TextField)({
  input: {
    color: "white",
    "&::placeholder": {
      color: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "30px",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export default function ForgotPassword() {
  const { t } = useTranslation();

  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigatorLanguageCode = navigatorLanguage();
  const languageCode = cookies.get("i18next") || navigatorLanguageCode;

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage(false);
      setLoading(true);
      await resetPassword(emailRef.current.value, languageCode);
      setMessage(t("Check your inbox for further instructions"));
    } catch (signUpError) {
      if (signUpError.toString().includes("There is no user record")) {
        setMessage(t("User does not exist. Please try again"));
      } else {
        setMessage(signUpError.toString());
      }
    }
    setLoading(false);
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Grid
        container
        spacing={5}
        direction="column"
        align="center"
        justifyContent="center"
        minHeight="80vh"
      >
        <Grid item maxWidth="350px" mt={2}>
          <img
            src={properizeLogo}
            alt="properizelogo"
            height="50"
          />
        </Grid>
        <Grid item maxWidth="350px">
          <Paper sx={paperStyle}>
            <Avatar style={{ backgroundColor: "white" }}>
              <KeyIcon color={"secondary"} />
            </Avatar>
            <Typography sx={{ margin: 2, fontSize: 24, fontWeight: "bold" }}>
              {t("Password Reset")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <CssTextField
                label={t("Email")}
                variant="outlined"
                type="email"
                inputRef={emailRef}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                required
              />

              <br />
              <br />
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                style={{
                  borderRadius: 16,
                  fontSize: "18px",
                }}
                disabled={loading}
              >
                {t("RESET PASSWORD")}
              </Button>
            </form>

            <Button
              variant="text"
              style={{
                color: "#FFF",
                //padding: "18px 36px",
                fontSize: "14px",
                fontWeight: "bold",
                marginTop: 30,
              }}
              fullWidth
              onClick={() => navigate("/login")}
            >
              {t("LOG IN")}
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <MessagePopup state={message} setState={setMessage} message={message} />
    </motion.div>
  );
}
