import React, { useState } from "react";

import { Grid, IconButton, TextField } from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import { useSelector, useDispatch } from "react-redux";
import {
  selectTenants,
  selectTenantsForAdvancedList,
  tenantsEdited,
  tenantsForAdvancedListEdited,
} from "../slicers/userDataSlice";

import axios from "axios";
import configData from "../utils/config.json";

const EditableField = ({
  properize_id,
  parentRequest,
  feature,
  featureLabel,
  convertTo,
  defaultValue,
  defaultTextValue,
  type = "string",
}) => {
  const [showEditFields, setShowEditFields] = useState(false);
  const [newValue, setNewValue] = useState(defaultValue);

  const dispatch = useDispatch();
  const tenantsSelector = useSelector(selectTenants);
  const tenantsForAdvancedList = useSelector(selectTenantsForAdvancedList);

  function editHouseholdFields() {
    let payload = {};

    if (properize_id) {
      payload["properize_id"] = properize_id;
    }

    if (parentRequest) {
      payload["parentRequest"] = parentRequest;
    }

    payload[feature] = convertTo === undefined ? newValue : convertTo(newValue);
    axios.post(configData.backendURL + "/update_airtable_row", payload);

    dispatch(
      tenantsEdited(
        tenantsSelector.map((t) =>
          t["Parent request"] === parentRequest ||
          t["properize_id"] === properize_id
            ? {
                ...t,
                [feature]:
                  convertTo === undefined ? newValue : convertTo(newValue),
              }
            : t
        )
      )
    );

    dispatch(
      tenantsForAdvancedListEdited(
        tenantsForAdvancedList.map((t) =>
          t["id"] === parentRequest
            ? {
                ...t,
                [feature]:
                  convertTo === undefined ? newValue : convertTo(newValue),
              }
            : t
        )
      )
    );

    setShowEditFields(false);
  }

  function cancelEdition() {
    setNewValue(defaultValue);
    setShowEditFields(false);
  }

  return (
    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
      {!showEditFields && (
        <>
          <Grid item>{defaultTextValue}</Grid>

          <Grid item>
            <IconButton size="small" onClick={() => setShowEditFields(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Grid>
        </>
      )}

      {showEditFields && (
        <>
          <TextField
            value={newValue}
            label={featureLabel}
            type={type}
            size={"small"}
            multiline={feature === "Bio"}
            rows={feature === "Bio" ? 6 : 1}
            onChange={(event) => {
              setNewValue(event.target.value);
            }}
            variant="outlined"
            fullWidth
          />

          <Grid item>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <IconButton size="small" onClick={editHouseholdFields}>
                  <SaveIcon fontSize="small" style={{ color: "green" }} />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton onClick={cancelEdition} style={{ borderRadius: 0 }}>
                  <CloseIcon fontSize="small" style={{ color: "red" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EditableField;
