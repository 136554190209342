import React, { useEffect, useState } from "react";
import unidecode from "unidecode";

import {
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import CallIcon from "@mui/icons-material/Call";
import DownloadIcon from "@mui/icons-material/Download";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserSettings,
  selectTenantsForAdvancedList,
  getActiveLandlord,
  selectLandlords,
  fetchLandlords,
  landlordAccessed,
} from "../../../slicers/userDataSlice";

import { householdClicked } from "../../../slicers/globalStateVariablesSlice";

import { useNavigate } from "react-router-dom";
import CopyToClipboard from "../../../components/CopyToClipboard";

import axios from "axios";
import configData from "../../../utils/config.json";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import LoadingDataComponent from "../../../components/LoadingDataComponent";
import EditLandlord from "./AddEditLandlord";
import MessagePopup from "../../../components/MessagePopup";
import { uploadFileToS3Bucket } from "../../../utils/uploadToS3";

const cardStyle = {
  padding: 3,
  bgcolor: "#ffffff",
  borderRadius: 2,
  boxShadow: 5,
  marginTop: 1,
  marginBottom: 2,
};

const breadcumbcardStyle = {
  padding: 1,
  px: 3,
  bgcolor: "#ffffff",
  borderRadius: 10,
  boxShadow: 3,
  marginBottom: 1,
};

function makeid(length) {
  var result = "";
  var characters =
    "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function reformatTimestamp(timestamp) {
  return (
    timestamp.slice(8, 10) +
    "-" +
    timestamp.slice(5, 7) +
    "-" +
    timestamp.slice(0, 4) +
    ", " +
    timestamp.slice(11, 16)
  );
}

const renderCreated = (createdTimestamp) => {
  return (
    <>
      {createdTimestamp.slice(8, 10)}-{createdTimestamp.slice(5, 7)}-
      {createdTimestamp.slice(0, 4)}
      {", "}
      {createdTimestamp.slice(11, 16)}
    </>
  );
};

const DeleteDialog = ({ deleteClicked, setDeleteClicked, handleClick }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={deleteClicked}>
      <DialogContent>
        <DialogContentText>
          {t("Are you sure you want to remove it")}
          <br />
          <br />
          <Grid container justifyContent="space-around">
            <Button
              variant="outlined"
              style={{ width: "47%" }}
              onClick={() => setDeleteClicked(false)}
            >
              {t("NO")}
            </Button>
            <Button
              variant="contained"
              style={{ width: "47%" }}
              color="primary"
              onClick={handleClick}
            >
              {t("YES")}
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const AttachmentsCard = ({ attachments, landlordId }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const userSettings = useSelector(selectUserSettings);
  const landlord = useSelector(selectLandlords).find(
    (landlord) => landlord.id === landlordId
  );

  const dispatch = useDispatch();

  function changeLabel(event) {
    setFile({
      ...file,
      title: event.target.value,
    });
  }

  async function addFile(event) {
    // If the file is too big, a pop up show up
    if (event.target.files[0].size > 1048576 * 20)
      return setError(t("This file is too big!"));

    // Upload the file to S3
    await uploadFileToS3Bucket(
      "properize-landlord-docs",
      landlordId + "/attachments",
      event.target.files[0]
    );

    // Upload values to DynamoDB
    const payload = {
      ...file,
      landlord_id: landlordId,
      title: file.title ? file.title : event.target.files[0].name.split(".")[0],
      file_name: unidecode(event.target.files[0].name.replaceAll(" ", "_")),
    };

    await axios.post(
      configData.backendURL + "/upload_landlord_attachment",
      payload
    );

    setFile(null);
    dispatch(fetchLandlords(userSettings.group_id));
  }

  function labelDisplayedRows({ from, to, count }) {
    return (
      from +
      "–" +
      to +
      " " +
      t("of") +
      " " +
      (count !== -1 ? count : t("more than") + " " + to)
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function Attachment(props) {
    const { attachment } = props;
    const [disabledButton, setDisabledButton] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);

    const onDownload = async () => {
      setDisabledButton(true);
      const payload = {
        url:
          "https://properize-landlord-docs.s3.eu-central-1.amazonaws.com/" +
          landlordId +
          "/attachments/" +
          attachment["file_name"],
      };
      const response = await axios.post(
        configData.backendURL + "/create_presigned_url_api",
        payload
      );

      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = response.data;
      link.click();
      setDisabledButton(false);
    };

    async function removeFile() {
      const newAttachmentsArray = attachments.filter(
        (att) => att.id !== attachment["id"]
      );

      let landlordNew = {
        ...landlord,
        landlord_id: landlord["id"],
        attachments: newAttachmentsArray,
      };
      delete landlordNew["id"];

      const payload = {
        table: "properize-landlords",
        item: landlordNew,
      };

      await axios.post(
        configData.backendURL + "/edit_dynamodb_record",
        payload
      );

      dispatch(fetchLandlords(userSettings.group_id));
      setDeleteClicked(false);
    }

    return (
      <TableRow key={attachment["id"]}>
        <TableCell align="left">
          {renderCreated(attachment["created"])}
        </TableCell>
        <TableCell align="left">{attachment["title"]}</TableCell>
        <TableCell align="right">
          <Grid container justifyContent="flex-end" wrap="nowrap" spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={onDownload}
                disabled={disabledButton}
              >
                {t("DOWNLOAD")}
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setDeleteClicked(true)}
                style={{ borderRadius: 0 }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
        <DeleteDialog
          deleteClicked={deleteClicked}
          setDeleteClicked={setDeleteClicked}
          handleClick={removeFile}
        />
      </TableRow>
    );
  }

  return (
    <Grid style={{ maxWidth: "1200px", width: "100%" }}>
      <Card sx={cardStyle}>
        <Typography
          sx={{ fontSize: 18, fontWeight: "bold", margin: 1, marginBottom: 2 }}
        >
          {t("Attachments")}
        </Typography>

        {attachments.length === 0 && (
          <Grid item align="center">
            <Typography fontSize={14}>
              {t("There are no attachments yet, upload your first attachment")}
            </Typography>
          </Grid>
        )}

        {attachments.length > 0 && (
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "60%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <TableBody>
                {(rowsPerPage > 0
                  ? attachments.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : attachments
                ).map((attachment) => (
                  <Attachment key={attachment.id} attachment={attachment} />
                ))}
              </TableBody>
            </Table>
            {rowsPerPage !== 0 && attachments.length > 3 && (
              <TablePagination
                labelRowsPerPage={t("Items per page")}
                labelDisplayedRows={labelDisplayedRows}
                rowsPerPageOptions={[3, 5, 10]}
                component="div"
                count={attachments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        )}

        {file && (
          <Grid container mt={1} ml={-1} spacing={2} wrap="nowrap">
            <Grid item xs={6}>
              <TextField
                label={t("Name of document")}
                variant="outlined"
                size="small"
                value={file["title"]}
                onChange={(event) => changeLabel(event)}
                required
                fullWidth
                InputLabelProps={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
              >
                {t("ATTACH FILE")}
                <input
                  type="file"
                  //accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  hidden
                  onChange={(event) => addFile(event)}
                />
              </Button>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => setFile(null)}
                style={{ borderRadius: 0 }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}

        {!file && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<AttachFileIcon />}
            style={{
              borderRadius: 8,
              fontSize: "14px",
              minWidth: "250px",
              maxWidth: "400px",
              marginTop: "20px",
              marginLeft: "10px",
            }}
            onClick={() =>
              setFile({
                id: makeid(25),
                title: "",
              })
            }
          >
            {t("ADD ATTACHMENT")}
          </Button>
        )}

        <MessagePopup state={error} setState={setError} message={error} />
      </Card>
    </Grid>
  );
};


const LandlordProfile = () => {
  const [deleteLandlordClicked, setLandlordDeleteClicked] = useState(false);
  const [landlordRemoved, setLandlordRemoved] = useState(true);

  const [editLandlordWindow, setEditLandlordWindow] = useState(false);
  const { t } = useTranslation();

  const activeLandlordId = useSelector(getActiveLandlord);
  const landlord = useSelector(selectLandlords).find(
    (landlord) => landlord.id === activeLandlordId
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const tenantsAdvancedList = useSelector(selectTenantsForAdvancedList);
  const userSettings = useSelector(selectUserSettings);

  const [dossiers, setDossiers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeLandlordId) {
      setDossiers(
        tenantsAdvancedList.filter(
          (dossier) => dossier.landlord_id === activeLandlordId
        )
      );
    }
  }, []);

  if (!activeLandlordId) {
    return navigate("/landlords");
  }

  const createdTimestamp = reformatTimestamp(landlord["created"]);

  const breadcrumbsCard = (
    <Grid justifyContent="flex-start" style={{ maxWidth: "1000px" }}>
      <Card sx={breadcumbcardStyle}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            startIcon={<PeopleAltIcon sx={{ fontWeight: "bold" }} />}
            variant="text"
            onClick={() => navigate("/landlords")}
          >
            <Typography sx={{ fontSize: 16 }}>{t("Landlords")}</Typography>
          </Button>
          <Typography sx={{ fontSize: 16 }}>
            {landlord["initials"] + ". " + landlord["last_name"]}
          </Typography>
        </Breadcrumbs>
      </Card>
    </Grid>
  );

  const personalDataCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }} sx={cardStyle}>
      <Typography p={1} sx={{ fontSize: 22, fontWeight: "bold" }}>
        {landlord["initials"] + ". " + landlord["last_name"]}
      </Typography>

      <TableContainer sx={{ padding: 0.5, marginTop: 1, marginBottom: 2 }}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              border: "none",
              padding: 0.5,
            },
            [`& .${tableRowClasses.root}`]: {
              height: "40px",
            },
            marginTop: 1,
          }}
        >
          <TableBody>
            <TableRow key="email">
              <TableCell
                align="left"
                sx={{ display: { sm: "block", xs: "none" } }}
              >
                {t("Email")}:
              </TableCell>
              <TableCell align="left">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>{landlord["email"]}</Grid>
                  <Grid item>
                    <Link
                      href={"mailto:" + landlord["email"]}
                      color="inherit"
                      target="_blank"
                    >
                      <EmailIcon color="primary" />
                    </Link>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell
                align="right"
                sx={{ display: { sm: "block", xs: "none" } }}
              >
                <Typography variant="body2">
                  <CopyToClipboard message={landlord["email"]} />
                </Typography>
              </TableCell>
            </TableRow>
            {landlord["phone_number"] && (
              <TableRow key="phone_number">
                <TableCell
                  align="left"
                  sx={{ display: { sm: "block", xs: "none" } }}
                >
                  {t("Mobile Phone")}:
                </TableCell>
                <TableCell align="left">
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>{landlord["phone_number"]}</Grid>
                    <Grid item>
                      <Link
                        href={"tel:" + landlord["phone_number"]}
                        color="inherit"
                        target="_blank"
                      >
                        <CallIcon color="primary" />
                      </Link>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ display: { sm: "block", xs: "none" } }}
                >
                  <Typography variant="body2">
                    <CopyToClipboard message={landlord["phone_number"]} />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow key="created">
              <TableCell
                align="left"
                sx={{ display: { sm: "block", xs: "none" }, marginTop: 0.8 }}
              >
                {t("Created")}:
              </TableCell>
              <TableCell align="left">{createdTimestamp}</TableCell>
            </TableRow>
            <TableRow key="iban_number">
              <TableCell
                align="left"
                sx={{ display: { sm: "block", xs: "none" } }}
              >
                {t("IBAN number")}:
              </TableCell>
              <TableCell align="left">{landlord["iban_number"]}</TableCell>
              <TableCell
                align="right"
                sx={{ display: { sm: "block", xs: "none" } }}
              >
                <Typography variant="body2">
                  <CopyToClipboard message={landlord["iban_number"]} />
                </Typography>
              </TableCell>
            </TableRow>
            {landlord["property_manager"] && (
              <TableRow key="property_manager">
                <TableCell
                  align="left"
                  sx={{ display: { sm: "block", xs: "none" } }}
                >
                  {t("Associated manager")}:
                </TableCell>
                <TableCell align="left">
                  {landlord["property_manager"] +
                    " (" +
                    t("emergency contact") +
                    ": " +
                    landlord["emergency_contact_details"] +
                    ")"}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ display: { sm: "block", xs: "none" } }}
                >
                  <Typography variant="body2">
                    <CopyToClipboard
                      message={
                        landlord["property_manager"] +
                        " (" +
                        " " +
                        t("emergency contact") +
                        ": " +
                        landlord["emergency_contact_details"] +
                        ")"
                      }
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  const renderAddress = (row) => {
    const address = row["Property location"];
    const n_tenants = row["n_tenants"] - 1;
    const nameTenant = row["tenant1_Full name"];

    return (
      <>
        <strong>{address}</strong>
        <br />
        {nameTenant} {n_tenants > 0 && t("and") + n_tenants + t("more")}
      </>
    );
  };

  const renderStatus = (row) => {
    let status = row["Status"];
    let variant = "outlined";
    let color, Icon;

    if (row["tenant1_Contract"]) {
      const contract = JSON.parse(row["tenant1_Contract"]);
      const envelopeStatus = contract["envelope_status"];

      if (envelopeStatus === "In progress") {
        variant = "filled";
        status = "Signing";
        color = "#0f52ba";
        Icon = MailOutlineIcon;
      } else if (envelopeStatus === "Completed") {
        variant = "filled";
        status = "Signed";
        color = "green";
        Icon = DoneIcon;
      }
    }

    if (status === "Completed") {
      color = "green";
      Icon = DoneIcon;
    } else if (status === "Processing") {
      color = "#e47200";
      Icon = RestartAltIcon;
    } else if (status === "In progress") {
      color = "blue";
      Icon = AccessTimeIcon;
    } else if (status === "Failed") {
      color = "red";
      Icon = ClearIcon;
    }

    return (
      <Button
        variant="outlined"
        startIcon={<Icon />}
        disabled
        style={{
          color: variant === "outlined" ? color : "white",
          borderRadius: 50,
          height: "2em",
          borderColor: color,
          backgroundColor: variant === "filled" ? color : "white",
        }}
      >
        {t(status.toUpperCase())}
      </Button>
    );
  };

  const RenderViewButton = (parentRequest) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onClick = () => {
      dispatch(
        householdClicked({
          parentRequest: parentRequest.parentRequest,
          tenantDetail: false,
        })
      );
      navigate("/dossier_summary?par_request=" + parentRequest.parentRequest);
    };
    return (
      <Button variant="contained" onClick={onClick} sx={{ width: "90%" }}>
        {t("VIEW")}
      </Button>
    );
  };

  const RenderPDFButton = (pdfReportUrl) => {
    const [disabledButton, setDisabledButton] = useState(false);

    const onDownload = async () => {
      setDisabledButton(true);
      const payload = {
        url: pdfReportUrl.pdfReportUrl,
      };
      const response = await axios.post(
        configData.backendURL + "/create_presigned_url_api",
        payload
      );

      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = response.data;
      link.click();
      setDisabledButton(false);
    };
    return (
      <strong>
        {pdfReportUrl.length > 1 && (
          <IconButton onClick={onDownload} disabled={disabledButton}>
            <PictureAsPdfIcon />
          </IconButton>
        )}
      </strong>
    );
  };

  function Dossier(props) {
    const { dossier } = props;

    return (
      <React.Fragment>
        <TableRow key={dossier["id"]}>
          <TableCell align="left">
            {renderCreated(dossier["Created"])}
          </TableCell>

          <TableCell align="left">{renderAddress(dossier)}</TableCell>

          <TableCell align="right">
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>{renderStatus(dossier)}</Grid>

              <Grid item>
                <RenderPDFButton pdfReportUrl={dossier["pdf_report"]} />
              </Grid>

              <Grid item>
                <RenderViewButton parentRequest={dossier["id"]} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function labelDisplayedRows({ from, to, count }) {
    return (
      from +
      "–" +
      to +
      " " +
      t("of") +
      " " +
      (count !== -1 ? count : t("more than") + " " + to)
    );
  }

  async function removeLandlord() {
    setLandlordDeleteClicked(false);
    setLandlordRemoved(false);

    let landlordNew = {
      ...landlord,
      landlord_id: landlord["id"],
      group_id: "DELETED_" + landlord["group_id"],
    };
    delete landlordNew["id"];

    const payload = {
      table: "properize-landlords",
      item: landlordNew,
    };

    await axios.post(configData.backendURL + "/edit_dynamodb_record", payload);
    dispatch(fetchLandlords(userSettings.group_id));
    dispatch(landlordAccessed(null));
    setLandlordRemoved(true);
  }

  const dossiersCard = (
    <Grid style={{ maxWidth: "1200px", width: "100%" }}>
      <Card sx={cardStyle}>
        <Typography
          sx={{ fontSize: 18, fontWeight: "bold", margin: 1, marginBottom: 2 }}
        >
          {t("Rental dossiers")}
        </Typography>
        {dossiers.length === 0 && (
          <Grid item align="center">
            <Typography fontSize={14}>
              {t("There are no dossiers associated to this landlord")}
            </Typography>
          </Grid>
        )}
        {dossiers.length > 0 && (
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "60%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <TableBody>
                {(rowsPerPage > 0
                  ? dossiers.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : dossiers
                ).map((dossier) => (
                  <Dossier key={dossier.id} dossier={dossier} />
                ))}
              </TableBody>
            </Table>
            {rowsPerPage !== 0 && dossiers.length > 3 && (
              <TablePagination
                labelRowsPerPage={t("Items per page")}
                labelDisplayedRows={labelDisplayedRows}
                rowsPerPageOptions={[3, 5, 10]}
                component="div"
                count={dossiers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        )}
      </Card>
    </Grid>
  );

  return (
    <motion.div
      initial="initial"
      animate="in"
      variants={{
        initial: {
          x: "-100%",
        },
        in: {
          x: 0,
        },
        out: {
          x: "100%",
        },
      }}
      transition={{
        type: "spring",
        mass: 0.2,
        duration: 100,
      }}
      exit="out"
    >
      <Typography
        color="secondary"
        sx={{ marginLeft: 2, marginBottom: 1, fontSize: 24 }}
      >
        {t("Landlord profile")}
      </Typography>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ marginLeft: 10 }}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          sx={{ maxWidth: "1200px" }}
        >
          <Grid item>{breadcrumbsCard}</Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => setEditLandlordWindow(true)}
              size="large"
            >
              {t("EDIT PROFILE")}
            </Button>
          </Grid>
        </Grid>

        {personalDataCard}
        {dossiersCard}
        <AttachmentsCard
          attachments={landlord.attachments}
          landlordId={activeLandlordId}
        />
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        mt={-1}
        style={{ maxWidth: 1210 }}
      >
        <Button
          variant="text"
          color="error"
          size="large"
          startIcon={<DeleteIcon />}
          onClick={() => setLandlordDeleteClicked(true)}
          disabled={deleteLandlordClicked}
        >
          {t("REMOVE PROFILE")}
        </Button>
      </Grid>

      <DeleteDialog
        deleteClicked={deleteLandlordClicked}
        setDeleteClicked={setLandlordDeleteClicked}
        handleClick={removeLandlord}
      />
      {editLandlordWindow && (
        <EditLandlord
          addLandlordWindow={editLandlordWindow}
          setAddLandlordWindow={setEditLandlordWindow}
          landlord={landlord}
        />
      )}
      <LoadingDataComponent alreadyRendered={landlordRemoved} />
    </motion.div>
  );
};

export default LandlordProfile;
